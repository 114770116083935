import React, { useState, useEffect, useRef} from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Achievement } from '../../Atoms'
import renderAchievementText from '../../Atoms/Achievement/renderAchievementText'

import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../env'
import moment from 'moment'

const Events = ({ teamId, teamName, offsetLeft }) => {
    const [data, setData] = useState(null)

    const evsRef = useRef(null)

    useEffect(() => {
        if(teamId) {
            axios.get(`${ENDPOINT}client/achievements?sampleType=team&sampleId=${teamId}`)
                .then(resp => {
                    setData(resp.data.filter(a => !['ycs', 'rcs'].includes(a.scope)))
                })
        }
    }, [teamId])

    return  <div className="team-events">
                <div className='title alterfont'>События</div>

                {!data ? (
                    <div className='events-grid'>
                        <div className='team-events_item'></div>
                    </div>
                ) : (
                    <div className='events-grid' style={{marginLeft: offsetLeft}}>
                        <Splide
                            ref={evsRef}
                            options={{
                                pagination: true,
                                fixedWidth: 260,
                                focus: 0,
                                type: 'loop',
                                arrows: true,
                                gap: '10px',
                                interval: 4500,
                                perMove: 1,
                                updateOnMove: false,
                                waitForTransition: true,
                                clones: 1
                            }}
                        >
                            {data.filter(e => e.player).map((evt, i) => (
                                <SplideSlide key={i}>
                                    <div className='team-events_item'>
                                        <div className='player alterfont'>{evt.player.name} {evt.player.surname}</div>

                                        <div className='graph'>
                                            <div className='avatar'>
                                                <img
                                                    className={!evt.player.avatarUrl ? 'empty' : ''}
                                                    src={evt.player.avatarUrl || ''}
                                                    onError={e => {
                                                        e.target.src = require('./holder.png')
                                                }} />
                                            </div>

                                            <Achievement sample={evt.sample} scope={evt.scope} size={80} />
                                        </div>

                                        <div className='content'>
                                            <span>{renderAchievementText(evt, 'team', '')}</span>
                                        </div>

                                        <span className='date'>{moment(evt.date, 'YY-MM-DD').format('DD MMMM YYYY')}</span>
                                    </div>
                                </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                )}
            </div>
}

export default Events
