import React from 'react'

import { Skeleton } from 'primereact/skeleton'

import './style.scss'

const renderWidths = obj => {
    const total = parseInt(obj.home)+parseInt(obj.away)+parseInt(obj.draws)
    const home = Math.ceil((parseInt(obj.home)/total)*100)
    const away = Math.floor((parseInt(obj.away)/total)*100)

    return {home: home, away: away}
}

const MatchesBalance = ({ data, width, theme }) => {
    const widths = data ? renderWidths(data) : null

    return  widths ? <div
                className='matches-balance'
                // style={{
                //     width: width
                // }}
            >
                <div className='mean'>{`история встреч: общий счет ${data.totalScore}`}</div>
                <div
                    className='matches-balance_body'
                    style={{
                        background: `var(--${theme.bar}-100)`
                    }}
                >
                    <div
                        className='matches-balance_segment __home'
                        style={{
                            width: widths.home+'%',
                            background: `var(--${theme.bar}-200)`
                        }}
                    ></div>
                    <div
                        className='matches-balance_segment __away'
                        style={{
                            width: widths.away+'%',
                            background: `var(--${theme.bar}-300)`
                        }}
                    ></div>
                </div>
                <div className='matches-balance_values'>
                    <div
                        style={{
                            width: widths.home+'%'
                        }}
                    >{data.home ? data.home+' x П1' : null}</div>
                    <div
                        style={{
                            width: (100-(widths.home+widths.away))+'%',
                            textAlign: 'center'
                        }}
                    >{data.draws ? data.draws+' ничьи' : null}</div>
                    <div
                        style={{
                            width: widths.away+'%',
                            textAlign: 'right'
                        }}
                    >{data.away ? data.away+' x П2' : null}</div>
                </div>
            </div> : (
                <div className='balance-skeleton'>
                    <Skeleton height={10} />
                </div>
            )
}

export default MatchesBalance
