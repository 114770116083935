import React, { useState, useEffect } from 'react'

import { Skeleton } from 'primereact/skeleton'

import { FeedCard } from '../../Atoms'
import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const Feed = ({ playerId }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        if(playerId) {
            setData(null)
            axios.get(`${ENDPOINT}client/playermatches/${playerId}?fullFeed=true`)
                .then(resp => {
                    setData(resp.data)
                })
        }
    }, [playerId])

    return  <div className="feed">
                {data ? data.map((card, i) => (
                    <FeedCard
                        key={i}
                        data={card}
                        scope='player'
                    />
                )) : [
                    <Skeleton height={140} />,
                    <Skeleton height={140} />,
                    <Skeleton height={140} />
                ]}
            </div>
}

export default Feed
