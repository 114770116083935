import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Tag } from 'primereact/tag'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import './mobile-legs.scss'

const renderOleEmblem = club => {
    if(club.origin && club.origin.source === 'ole') {
        return `${process.env.FILES_BUCKET}clubs/ole/${club.origin.id}.png`
    } else {
        return null
    }
}

const handleEmblemErr = e => {
    e.target.src = require('./football-club.png')
}

const MobileLegs = ({ data }) => {
    const [active, setActive] = useState(null)
    const history = useHistory()
    const splRef = useRef()

    useEffect(() => {
        if(splRef && splRef.current && active === null) {
            setActive(data.length - 1)
            splRef.current.go(data.length - 1)
        }
    }, [splRef])

    return  <div className='mobile-legs'>
                <div className='mobile-legs_nav stages-nav'>
                    {data.filter(md => !md.leg.find(m => (!m.homeClub || !m.awayClub))).map((md, i) => (
                        <Tag
                            key={i}
                            className={active === i ? 'selected' : ''}
                            onClick={() => splRef.current.go(i)}
                        >{md.matchday}</Tag>
                    ))}
                </div>

                <div className='mobile-legs_cards'>
                    <Splide
                        ref={splRef}
                        options={{
                            pagination: false,
                            fixedWidth: '100%',
                            focus: 0,
                            arrows: false,
                            gap: '10px',
                            interval: 4500,
                            perMove: 1,
                            updateOnMove: false,
                            waitForTransition: true,
                        }}
                        onMove={(e, i) => setActive(i)}
                    >
                        {data.filter(md => !md.leg.find(m => (!m.homeClub || !m.awayClub))).map((md, i) => (
                            <SplideSlide key={i}>
                                <div className='mobile-legs_card'>
                                    {md.leg.map((node, i) => (
                                        <div className='playoff-node' onClick={() => node.homeClub && node.awayClub ? history.push(`/match/${node._id}`) : null}>
                                            <div className='row'>
                                                <span className='emb'>
                                                    {node.homeClub ? <img src={node.homeClub.club ? node.homeClub.club.emblem || renderOleEmblem(node.homeClub.club) || '' : ''} onError={handleEmblemErr} /> : null}
                                                </span>
                                                <div className='name'>{node.homeClub ? node.homeClub.name : null}</div>
                                                <div className='score alterfont'>{node.scores && node.scores.full ? node.scores.full.home : '-'}</div>
                                            </div>

                                            {node.scores && node.scores.shootout && (node.scores.shootout.home || node.scores.shootout.away) ? (
                                                <div className='shootout'>{`пен. ${node.scores.shootout.home}:${node.scores.shootout.away}`}</div>
                                            ) : null}

                                            <div className='row'>
                                                <span className='emb'>
                                                    {node.awayClub ? <img src={node.awayClub.club ? node.awayClub.club.emblem || renderOleEmblem(node.awayClub.club) || '' : ''} onError={handleEmblemErr} /> : null}
                                                </span>
                                                <div className='name'>{node.awayClub ? node.awayClub.name : null}</div>
                                                <div className='score alterfont'>{node.scores && node.scores.full ? node.scores.full.away : '-'}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </div>
}

export default MobileLegs
