import './style.scss'
import React from "react";
import {Sidebar} from "primereact/sidebar";
import {BETS_BASE} from "../../../env";

const ModalNotification = ({ state, user, onClose }) => {

    return  <div>
        <Sidebar
            position='top'
            visible={state && user && user._id}
            maskClassName='fantasy-noti-panel'
            onHide={onClose}
        >
            {user && state ? <iframe src={`${BETS_BASE}${user?._id}/achievements/${state?._id}`} frameBorder="0" /> : null}
        </Sidebar>
    </div>
}

export default ModalNotification