import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Lineup, BtnSet } from '../Atoms'
import { Tag } from 'primereact/tag'

const formatLineup = roster => {
    const { list, lineup } = roster

    if(lineup && lineup.schema) {
        return {
            formation: lineup.schema,
            players: lineup.lines.map(id => {
                const matched = list.find(p => p._id === id)
                return matched || {}
            })
        }
    } else {
        return null
    }
}

const extractRosters = (obj, proxy) => {
    return [
        !proxy || proxy.finished ? obj.rosters.find(r => r.teamId === obj.home._id) : (proxy && proxy.rosters && proxy.rosters.home ? {players: proxy.rosters.home.list, lineup: formatLineup(proxy.rosters.home)} : {players: [], lineup: null}),
        !proxy || proxy.finished ? obj.rosters.find(r => r.teamId === obj.away._id) : (proxy && proxy.rosters && proxy.rosters.away ? {players: proxy.rosters.away.list, lineup: formatLineup(proxy.rosters.away)} : {players: [], lineup: null})
    ]
}

const Rosters = ({ data, layout, proxyData }) => {
    const [side, setSide] = useState('home')
    const output = extractRosters(data, proxyData)

    const history = useHistory()

    const linkHandle = (event) => {
        event.preventDefault();
        history.push(event.currentTarget.getAttribute('href'))
    }

    return  [
        layout === 'mobile' ? (
            <div className='roster-pos-switcher'>
                <BtnSet
                    btns={[{label: data.home.name, id: 'home'}, {label: data.away.name, id: 'away'}]}
                    size='sm'
                    theme='global-highlight-color'
                    active={side}
                    onChange={e => setSide(e)}
                />
            </div>
        ) : null,
        <div className={'rosters-content'+(side === 'away' ? ' translated' : '')}>
                {output.filter(r => r).map((roster, idx) => (
                    <div key={idx} className={`roster-cell ${idx===0 ? '__home' : '__away'}`}>
                        <Lineup
                            width={layout === 'mobile' ? 280 : 320}
                            data={roster.lineup || null}
                        />

                        <div className={`roster-list`} key={idx} style={{width: 320}}>
                            {roster.players.map((plr, i) => (
                                <a href={`/player/${plr._id}`} className='roster-list_item' key={i} onClick={linkHandle}>
                                    {plr.mvp ? <Tag icon='pi pi-star' className='alterfont'>MVP</Tag> : null}
                                    <span className='alterfont'>{plr.num}</span>
                                    <div>{plr.name+' '+plr.surname}</div>
                                </a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        ]
}

export default Rosters
