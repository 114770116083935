import React from 'react'

import Icon from '../Icon'
import './style.scss'

const NonIdealState = ({ height, icon, text, color, iconColor }) => {
    return  <div
                className='non-ideal-state'
                style={{height: height}}
            >
                <Icon icon={icon} size={44} color={iconColor || 'bluegray-200'} />
                <div className='message' style={color ? {color: color} : {}}>{text}</div>
            </div>
}

export default NonIdealState
