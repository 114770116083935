export default {
    appTitle: 'ЛФЛ Череповец',
    federation: {
        _id: '62a1ef502f89e853b1826578'
    },
    bannerNode: null,
    brandEmblem: `${process.env.FILES_BUCKET}leagues/cher_branding.png`,
    topBanners: [],
    footerCreds: {
        copyright: '© ИП Ершов М.А., 2024',
        phone: '+7 (921) 839 97 34',
        address: '162602, Вологодская обл., г. Череповец, ул. Максима Горького, д. 34, кв. 316'
    },
    footerStores: null,
    footerBanners: [],
    footerSocials: {
        vk: 'https://vk.com/lflcherepovets',
    },
    fantasyBrand: {
        type: 'label',
        text: 'Amateum',
        link: null
    },
    metrikaCounter: 89158481,
    firebase: {
        config: {
          apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
          authDomain: "amateum-bb5a1.firebaseapp.com",
          projectId: "amateum-bb5a1",
          storageBucket: "amateum-bb5a1.appspot.com",
          messagingSenderId: "456338694560",
          appId: "1:456338694560:web:17f8ee8575d621314784c5",
          measurementId: "G-VKZ0MMH0XY"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
