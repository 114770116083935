import React from 'react'

import './style.scss'

const Emblem = ({ source, backdroped, size, player }) => {
    const handleError = e => {
        e.target.src = require(player ? './football.png' : './football-club.png')
    }

    return  <div
                className={`emblem ${size || 'sm'}`+(backdroped ? ' backdroped': '')+(player ? ' player' : '')}
            >
                <img src={source || ''} onError={handleError} />
            </div>
}

export default Emblem
