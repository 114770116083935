import React, { useState, useEffect } from 'react'

import { AutoComplete } from 'primereact/autocomplete'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'

import Lottie from 'react-lottie'

import { Emblem } from '../../Atoms'

import * as animationData from './welcome.json'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const WelcomeFlow = ({ data, federation, passedFlow }) => {
    const [step, setStep] = useState(0)
    const [suggestions, setSuggestions] = useState([])
    const [favorites, setFavorites] = useState([])
    const [profileName, setProfileName] = useState('Безымянный Профиль')
    const [profileAge, setProfileAge] = useState(null)

    const searchFavorites = evt => {
        if(evt.query && evt.query.length > 3) {
            axios.get(`${ENDPOINT}client/suggestData/${federation}?query=${evt.query}`)
                .then(resp => {
                    if(resp.data) {
                        const formatted = ['teams', 'players'].reduce((acc, key) => {
                            const pull = resp.data[key].slice(0, 10)
                            acc = acc.concat(pull.map(item => key === 'teams' ? ({
                                info: item.name,
                                img: item.club ? item.club.emblem || '' : '',
                                type: 'team',
                                _id: item._id,
                                url: `/club/${item.club._id}?team=${item._id}`
                            }) : ({
                                info: item.name+' '+item.surname,
                                img: item.avatarUrl || '',
                                type: 'player',
                                _id: item._id,
                                url: `/player/${item._id}`
                            })))

                            return acc
                        }, [])

                        setSuggestions(formatted)
                    } else {
                        setSuggestions([])
                    }
                })
        } else {
            setSuggestions([])
        }
    }

    const proceedFavorites = () => {
        setStep(1)
        axios.put(`${ENDPOINT}user/${data.firebaseTkn}`, {favorites: favorites})
            .then(resp => {
                console.log('Added favorites')
            })
    }

    const saveProfile = () => {
        axios.put(`${ENDPOINT}user/${data.firebaseTkn}`, {profile: {name: profileName, age: profileAge}})
            .then(resp => {
                console.log('Saved profile')
                passedFlow()
            })
    }

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData
    }

    return  <div className='welcome-flow'>
                <Lottie
                    options={defaultOptions}
                    width='100%'
                    speed={.8}
                />
                <div className='title alterfont'>{step === 0 ? 'Добро пожаловать!' : 'Ваш профиль'}</div>
                <div className='cta'>{step === 0 ? <span>Хотите прямо сейчас добавить интересные Вам команды и игроков в <i>Избранное</i> для быстрого доступа?</span> : null}</div>
                <div className='field'>
                    {step === 0 ? (
                        <AutoComplete
                            inputClassName='p-inputtext-sm'
                            placeholder='фамилия или название команды'
                            multiple
                            value={favorites}
                            suggestions={suggestions}
                            completeMethod={searchFavorites}
                            field='info'
                            onChange={evt => setFavorites(evt.value)}
                            itemTemplate={item => (
                                <div className='suggest-list_item'>
                                    <div className='img'>
                                        <Emblem size='xs' source={item.img} backdroped={true} player={item.type === 'player'} />
                                    </div>

                                    <div className='info'>
                                        <div>{item.info}</div>
                                        <span>{item.type === 'team' ? 'команда' : 'игрок'}</span>
                                    </div>
                                </div>
                            )}
                        />) : [
                            <span className='p-float-label profile-info'>
                                <InputText value={profileName} className='p-inputtext-sm' onChange={e => setProfileName(e.target.value)} id='profile-name' />
                                <label htmlFor='profile-name'>Отображаемое имя:</label>
                            </span>,
                            <span className='p-float-label profile-info age'>
                                <InputNumber inputClassName='p-inputtext-sm' value={profileAge} onChange={e => setProfileAge(e.value)} id='profile-age' />
                                <label htmlFor='profile-age'>Возраст:</label>
                            </span>
                        ]}
                    </div>
                <div className='btns'>
                    <Button className='p-button-sm' label={step === 1 ? 'Сохранить' : favorites.length ? 'Продолжить' : 'Добавить потом'} onClick={() => step === 0 ? proceedFavorites() : saveProfile()} />
                </div>
            </div>
}

export default WelcomeFlow
