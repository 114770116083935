import React from 'react'
import MediaBlock from '../../MediaBlock'
import './style.scss'

const { mediablock } = JSON.parse(localStorage.getItem('_am_appconfig'))

const HeroBlock = ({ teamId, theme }) => {
    return  <div>
                <MediaBlock
                    theme={theme}
                    layout='desktop'
                    scope='tag'
                    inPage={true}
                    subjectId={teamId}
                    width={988}
                />
            </div>
}

export default HeroBlock
