const options = {
    lfl39: require('./lfl39').default,
    ffgt: require('./ffgt').default,
    lfl36: require('./lfl36').default,
    olesports: require('./olesports').default,
    kids: require('./kids').default,
    football63: require('./football63').default,
    'lfl-cherepovets': require('./lfl-cherepovets').default,
    nmlrussia: require('./nmlrussia').default,
    sfl: require('./sfl.js').default,
    rfu: require('./rfu.js').default,
    dev: require('./dev.js').default
}

const DEV = 'rfu'
export const CONF = options[window.location.host.split('.')[0]] || options[DEV]
