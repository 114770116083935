import React, { useState, useEffect } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { Top, MediaBlock, MatchesCarousel, Tournament, Feed, Standings, StatsBlock, Highlights, BottomStories, Footer, Match, Publication } from '../../Components'
import { Breadcrumbs, BannerNode, ScrollToTop } from '../../Components/Atoms'
import { Button } from '@ui/button'
import { Sidebar } from '@ui/sidebar'

import InstallCta from './InstallCta'
import Fantasy from './Fantasy'
import withStorage from './Storage'

import Stack from './Stacks'

import axios from 'axios'
import './style.scss'

import qs from 'qs'

import { CONF } from '../../configs'

import LayoutContext from './ctx'

import { injectQueryPath } from '../../utils'
import ym from 'react-yandex-metrika'
import ModalNotification from "./ModalNotification";

const excludePublicationLoc = query => {
    return `${window.location.pathname}${Object.keys(query).length > 1 ? '?'+qs.stringify(query) : ''}`
}

const Mobile = (props) => {
    const location = useLocation()
    const history = useHistory()

    const [config, setConfig] = useState(null)
    const [query, setQuery] = useState(null)
    const [activeSubject, setActiveSubject] = useState(null)
    const [user, setUser] = useState(null)
    const [accountOpen, setAccountOpen] = useState(true)
    const [installCta, setInstallCta] = useState(false)
    const [isInstallable, setIsInstallable] = useState(null)
    const [userAchiev, setUserAchiev] = useState([])
    const [newAchiev, setNewAchiev] = useState('')

    const [bannerNode, setBannerNode] = useState(CONF.bannerNode ? (
        <BannerNode
            media={CONF.bannerNode.media}
            action={() => {
                if(CONF.bannerNode.publicationId) {
                    history.push(`${window.location.pathname}${injectQueryPath('publication', CONF.bannerNode.publicationId)}`);
                } else {
                    window.open(CONF.bannerNode.externalLink)
                }
            }}
        />) : null
    )

    useEffect(() => {
        if(!localStorage.getItem('NO_INSTALL_CTA')){
            if (isInstallable) setInstallCta(true)
        } else setInstallCta(false)
    }, [localStorage.getItem('NO_INSTALL_CTA')])

    useEffect(() => {
        if (user && !userAchiev?.length) {
            axios.get(`https://fantasy.amateum.com/book/getBetPlayerAchievements/${user._id}`)
                .then(resp => {
                    if (!resp.data || (!resp.data.success && !resp.data._id)) {
                        console.log('resp error')
                    } else {
                        setUserAchiev(resp.data.betachievements)
                    }
                })
        }
    }, [user])
    
    useEffect(() => {
        if(userAchiev?.length) {
            setTimeout(() => {
                const arrayAchiev = localStorage.getItem('_am_achievements')?.split(',')
                localStorage.setItem('_am_achievements', userAchiev.map(ua => ua._id))
                if (arrayAchiev) {
                    const achiev = userAchiev.find(ua => !arrayAchiev.find(aa => aa === ua._id))
                    setNewAchiev(achiev)
                } else setNewAchiev(userAchiev[0])
            }, 4000)
        }
    }, [userAchiev])

    useEffect(() => {
        setQuery(qs.parse(window.location.search.slice(1)))
    }, [location])

    useEffect(() => {
        setConfig(config)

        const isInIframe = () => {
            if(window.parent) {
                return window.location !== window.parent.location
            } else {
                return false
            }
        }

        let displayMode = 'browser'
        const mqStandAlone = '(display-mode: standalone)'
        if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
            displayMode = 'standalone'
        }

        if(displayMode !== 'standalone' && !isInIframe()) {
            setIsInstallable(true)
            setTimeout(() => {
                if(!localStorage.getItem('NO_INSTALL_CTA')){
                    setInstallCta(true)
                }
            }, 5000)
        } else {
            ym('reachGoal', 'PWA_VISIT', {platform: props.device && props.device.os && props.device.os.name ? props.device.os.name : 'none'})
        }
    }, [props.config])

    return <LayoutContext.Provider value={{config: props.config, federation: props.federationObj, query, activeSubject, setActiveSubject, appUser: user, setUser, accountOpen, setAccountOpen, bannerNode, width: props.width}}>
                <div className={'layout-mobile'+(query && query.publication ? ' locked' : '')}>
                    <Fantasy
                        state={query ? query.fantasy : false}
                        user={user}
                        onClose={() => history.push(`${window.location.pathname}${injectQueryPath('fantasy', null)}`)}
                    />

                    <ModalNotification
                        state={newAchiev}
                        user={user}
                        onClose={() => setNewAchiev('')}
                    />

                    <Top layout='mobile' device={props.device} />
                    <ScrollToTop />

                    <Switch>
                        <Route path='/club/:id'>
                            <Stack page='club' />
                        </Route>

                        <Route path='/match/:id'>
                            <Stack page='match' />
                        </Route>

                        <Route path='/player/:id'>
                            <Stack page='player' />
                        </Route>

                        <Route path='/feed/:id'>
                            <Stack page='feed' />
                        </Route>

                        <Route path='/tournament/:id'>
                            <Stack page='tournament' />
                        </Route>

                        <Route path='/' exact={true}>
                            <Stack page='root' />
                        </Route>
                    </Switch>

                    <Sidebar
                        visible={query && query.publication}
                        position='bottom'
                        onHide={() => history.push(window.location.pathname+''+injectQueryPath('publication', null))}
                        style={{
                            height: 'calc(100vh - 100px)',
                            backgroundColor: '#fff',
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20
                        }}
                        className='pub-panel'
                    >
                        {query && query.publication ? <Publication id={query.publication} /> : null}
                    </Sidebar>

                    <Footer
                        {...props.config.footer}
                        device={props.device}
                        layout='mobile'
                        onForcedInstall={() => {
                            setInstallCta(true)
                            ym('reachGoal', 'FORCE_INSTALL')
                        }}
                        isInstallable={isInstallable}
                    />

                    {!window.location.host.includes('localhost:') ? <InstallCta state={installCta} resetState={() => setInstallCta(false)} device={props.device} /> : null}
                </div>
            </LayoutContext.Provider>
}

export default withStorage(Mobile)
