import React, { useState, useEffect } from 'react'
import { Sidebar } from 'primereact/sidebar'
import CustomScrollbars from 'react-custom-scrollbars-2'

import { Loader } from '../Atoms'
import Rankings from '../Rankings'

import { ENDPOINT } from '../../env'
import axios from 'axios'

import './mobile-extra-view.scss'

const models = {
    frequency: {
        head: ['И', 'Г+П', 'Мин.'],
        valuesModel: ['pld', 'count', 'value'],
        hint: 'Среднее количество игровых минут, которые игрок тратит на забитый мяч или голевую передачу (учитываются матчи, в которых игрок попал в заявку)',
        title: 'TOP-50: частота результативных действий'
    },
    mvp: {
        head: ['И', 'MVP'],
        valuesModel: ['pld', 'value'],
        hint: null,
        title: 'TOP: MVP'
    },
    pointsRate: {
        head: ['И', 'Points Rate'],
        valuesModel: ['pld', 'value'],
        hint: 'Отношение турнирных очков, заработанных командой в матчах с игроком в заявке к очкам в матчах, когда игрок отсутствовал + базовый коэффициент по модели FIFA',
        title: 'TOP-50: показатель Points Rate'
    }
}

const ExtraView = ({ type, subjectId, setExtraView, layout }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        axios.get(`${ENDPOINT}client/statsblock/${subjectId}?sampleType=${type}`)
            .then(resp => {
                if(resp.data) {
                    const sample = resp.data.find(s => s && s.data && s.data.id === type)
                    if(sample) {
                        setData(sample.data.list)
                    }
                }
            })
    }, [type])

    return  <Sidebar
                visible={type}
                position='bottom'
                maskClassName={layout === 'mobile' ? 'mobile-rating' : 'publication-desktop'}
                dismissable
                blockScroll
                onHide={() => {
                    setExtraView(null)
                    setData(null)
                }}
                style={layout === 'mobile' ? {
                    height: 'calc(100vh - 100px)',
                    backgroundColor: '#fff',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20
                } : {}}
            >
                {!data ? (
                    <div style={{marginTop: 200}}>
                        <Loader width={200} />
                    </div>
                ) : [
                    <h3 className='alterfont'>{models[type].title}</h3>,
                    models[type].hint ? (
                        <div className='publication-body_hint'>{models[type].hint}</div>
                    ) : null,
                    <div className='publication-body'>
                        <CustomScrollbars autoHeight autoHeightMin={`calc(100vh - ${models[type].hint ? 170 : 100}px)`} className='pub-scroll' autoHide>
                            <Rankings
                                data={data}
                                head={models[type].head}
                                valuesModel={models[type].valuesModel}
                                layout={layout}
                                isExtraView={true}
                                theme={{
                                    even: '#FBFDFF',
                                    evenBorder: '#f1f6fb',
                                    text: 'bluegray'
                                }}
                                width='100%'
                                wrapped={layout === 'mobile'}
                            />
                        </CustomScrollbars>
                    </div>
                ]}
            </Sidebar>
}

export default ExtraView
