import React, { useState, useContext, useRef, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { Emblem, Icon, VideoBox, Lineup } from '../'
import { MatchesBalance } from '../'
import { InMatchWidget } from '../../Fantasy'
import { Tag } from 'primereact/tag'

import './style.scss'

import moment from 'moment'
import DesktopContext from '../../../Layouts/Desktop/ctx'

const labels = {
    broadcast: 'Трансляции',
    news: 'Новости',
    interview: 'Интервью',
    highlights: 'Обзоры',
    previews: 'Анонсы',
    photos: 'Фото'
}

const scoreClass = score => {
    let str = 'draw'
    switch (true) {
        case parseInt(score.team) > parseInt(score.opposer):
            str = 'won'
            break
        case parseInt(score.team) < parseInt(score.opposer):
            str = 'lost'
            break
    }

    return str
}

const getFormat = dateStr => {
    return moment(dateStr, 'YY-MM-DD').format('YY') === moment().format('YY') ? 'D MMMM' : 'DD.MM.YYYY'
}

const Media = ({ data, item }) => {
    const isEmpty = !data || !data.length
    const history = useHistory()

    const getPhotoSrc = obj => {
        if(obj.sizes && obj.sizes.length) {
            return obj.sizes.sort((a, b) => b.height - a.height)[0].url
        } else {
            return ''
        }
    }

    const getVideoSrc = obj => {
        if(obj.image && obj.image.length) {
            return obj.image.sort((a, b) => b.height - a.height)[0].url
        } else {
            return ''
        }
    }

    return  <div
                onClick={() => history.push(`${window.location.pathname}?publication=${item}`)}
                //className={'mb-item_media'+(isEmpty ? ' empty' : '')}
                style={!isEmpty ? {
                    backgroundImage: `url(${data[0].type === 'video' ? getVideoSrc(data[0].video) : getPhotoSrc(data[0].photo)}`
                } : {}}
            >
                {!isEmpty ? (
                    <img src={data[0].type === 'video' ? getVideoSrc(data[0].video) : getPhotoSrc(data[0].photo)} />
                ) : null}

                {!isEmpty && data[0].type === 'video' ? (
                    <div className='play_btn'></div>
                ) : null}
            </div>
}

const formatText = (count, type) => {
    if (count % 10 === 1) {
        switch (type) {
            case 'goals':
                return `${count} забитый мяч`
            case 'assists':
                return `${count} голевая передача`
            case 'yc':
                return `${count} желтая карточка`
        }

    } else if ([2, 3, 4].includes(count % 10)){
        switch (type) {
            case 'goals':
                return `${count} забитых мяча`
            case 'assists':
                return `${count} голевых передачи`
            case 'yc':
                return `${count} желтых карточки`
        }
    } else {
        switch (type) {
            case 'goals':
                return `${count} забитых мячей`
            case 'assists':
                return `${count} голевых передач`
        }
    }
}

const FeedCard = ({ scope, data }) => {
    const [boxItem, setBoxItem] = useState(null)
    const [selbodyWidth, setSelbodyWidth] = useState(150)

    const history = useHistory()
    const { id } = useParams()

    const dctx = useContext(DesktopContext)
    const selBodyRef = useRef()

    useEffect(() => {
        if(selBodyRef && selBodyRef.current) {
            setSelbodyWidth(selBodyRef.current.clientWidth)
        }
    }, [selBodyRef, id])

    return  data.type === 'match' ? (
                <div className='feed-card'>
                    <div className='title alterfont'>{data.type === 'match' ? 'Участвовал в матче' : 'Следующий матч'}</div>

                    <div className='card-content'>
                        <div className='body'>
                            <div
                                className={'match'+(data.score ? ' played' : '')}
                                style={data.type === 'match' ? {flexDirection: 'column-reverse'} : {}}
                                onClick={() => history.push(`/match/${data._id}`)}
                            >
                                <div className='meta'>
                                    <div>
                                        <div className='date alterfont'>{moment(data.date, 'YY-MM-DD').format(getFormat(data.date))}</div>
                                        <div className='alterfont time'>{data.time}</div>
                                        <div className='location'>{data.location}</div>
                                    </div>
                                    {data.score ? (
                                        <div className={`score ${scoreClass(data.score)}`}>
                                            <div>
                                                <span className='alterfont'>{data.score.team}</span>
                                                <span className='pi pi-times'></span>
                                                <span className='alterfont'>{data.score.opposer}</span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                <div className='opposer'>
                                    <div className='opposer-body team'>
                                        <Emblem source={data.team.emblem} backdroped={true} size='xs' />
                                        <div className='name alterfont'>{data.team.name}</div>
                                    </div>
                                    <div className='muted'>против</div>
                                    <div className='opposer-body'>
                                        <Emblem source={data.opposer.emblem} backdroped={true} size='xs' />
                                        <div className='name alterfont'>{data.opposer.name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col'>
                            {data.fantasy ? (
                                <InMatchWidget
                                    layout='widget'
                                    data={{
                                        brand: `${process.env.FILES_BUCKET}assets/brand.png`,
                                        w1: 3.45,
                                        d: 4.11,
                                        w2: 2.11
                                    }}
                                />
                            ) : null}
                            {data.history ? (
                                <MatchesBalance
                                    data={{
                                        totalScore: '37:22',
                                        home: 6,
                                        draws: 4,
                                        away: 5
                                    }}
                                    theme={{
                                        bar: 'green'
                                    }}
                                />
                            ) : null}

                            {data.meta ? (
                                <div className='meta-block'>
                                    {data.meta.mvp ? (
                                        <div className='meta-block_badge'>
                                            <img src={require('./mvp.png')} />
                                            <div className='label alterfont'>mvp матча</div>
                                        </div>
                                    ) : null}

                                    {data.meta.lineup ? (
                                        <div className='meta-block_item'>
                                            <Icon size={22} icon='shirt' color='blue-400' />
                                            <span>выход в основе</span>
                                        </div>
                                    ) : null}
                                    {data.meta.goals ? (
                                        <div className='meta-block_item'>
                                            <Icon size={22} icon='ball' color='blue-400' />
                                            <span>{formatText(data.meta.goals, 'goals')}</span>
                                        </div>
                                    ) : null}
                                    {data.meta.assists ? (
                                        <div className='meta-block_item'>
                                            <Icon size={22} icon='boots' color='blue-400' />
                                            <span>{formatText(data.meta.assists, 'assists')}</span>
                                        </div>
                                    ) : null}
                                    {data.meta.yc ? (
                                        <div className='meta-block_item'>
                                            <Icon size={22} icon='red-card' color='yellow-400' />
                                            <span>{formatText(data.meta.yc, 'yc')}</span>
                                        </div>
                                    ) : null}
                                    {data.meta.rc ? (
                                        <div className='meta-block_item'>
                                            <Icon size={22} icon='red-card' color='red-400' />
                                            <span>красная карточка</span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : data.formation ? (
                <div className='feed-card'>
                    <div className='card-content'>
                        <div className='selection-item'>
                            <div className='info'>
                                <Tag>попал в символическую сборную</Tag>
                                <div className='title alterfont'>{data.tournament.name}</div>
                                <div className='secondary-title alterfont'>{data.name}</div>
                            </div>
                            <div className='selection-body' ref={selBodyRef}>
                                <Lineup
                                    width={selbodyWidth*.8}
                                    data={data}
                                    highlightPerson={id}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='feed-card'>
                    <div className='title alterfont'>
                        {data.category_ && labels[data.category] ? (
                            <div className='cat-tag'>
                                <Tag>{labels[data.category]}</Tag>
                            </div>
                        ) : null}
                        {data.title}
                        <span className='title-date'>{moment(data.date, 'YY-MM-DD').format(getFormat(data.date))}</span>
                    </div>

                    <div className='card-content'>
                        <div className='pub-preview'>
                            <div className='media' onClick={() => {
                                if(data.type === 'video' || (data.attachments && data.attachments.length === 1 && data.attachments[0].type === 'video')) {
                                    if(dctx && dctx.setPublicationId) {
                                        setBoxItem(data)
                                    } else {
                                        window.history.pushState({}, null, `${window.location.pathname}?publication=${data._id}`)
                                    }
                                } else {
                                    if(dctx && dctx.setPublicationId) {
                                        dctx.setPublicationId(data._id)
                                    } else {
                                        window.history.pushState({}, null, `${window.location.pathname}?publication=${data._id}`)
                                    }
                                }
                            }}>
                                <Media data={data.attachments} item={data._id} />
                            </div>
                            <div className='tags'>
                                {data.category && labels[data.category] ? (
                                    <div className='cat-tag'>
                                        <Tag>{labels[data.category]}</Tag>
                                    </div>
                                ) : null}

                                {data.tags.filter(t => t.subjectId !== id).map((tag, i) => {
                                    const target = tag.type === 'matches' ? `/match/${tag.subjectId}` : tag.type === 'teams' ? `/club/${tag.teams.club._id}` : `/${tag.type.slice(0, -1)}/${tag.subjectId}`
                                    return  <Tag
                                                onClick={() => history.push(target)}
                                            >{`#${tag.text}`}</Tag>
                                })}
                            </div>
                        </div>
                    </div>

                    <VideoBox
                        data={boxItem}
                        key={boxItem ? boxItem._id : null}
                        onClose={() => setBoxItem(null)}
                    />
                </div>
            )
}

export default FeedCard
