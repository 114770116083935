import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'

import * as animationData from './animation.json'

import { Button } from 'primereact/button'

import './magic.css'
import './style.scss'

import ym from 'react-yandex-metrika'

import Share from '../../../assets/img/share_ios.png'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
}

const InstallCta = ({ state, resetState, device }) => {
    const [animation, setAnimation] = useState(null)
    const [loading, setLoading] = useState(false)
    const isIos = device && device.os && device.os.name === 'iOS'

    useEffect(() => {
        if(state) {
            setTimeout(() => {
                setAnimation('shown')
                ym('reachGoal', 'SHOWN_INSTALL_CTA', {platform: device && device.os && device.os.name ? device.os.name : 'none'})
            }, 1000)

            const definePrompt = evt => {
                window.deferredPrompt = evt
                console.log('BEFORE IP Fired. Need to show dialog...')
            }

            return () => window.removeEventListener('beforeinstallprompt', definePrompt)
        }
    }, [state])

    const hideCta = () => {
        setTimeout(() => {
            setAnimation('gone')
            setTimeout(() => {
                setLoading(false)
                resetState()
            }, 500)
        }, 1000)
    }

    const handleAction = async () => {
        setLoading(true)
        ym('reachGoal', 'OK_INSTALL_CTA', {platform: device && device.os && device.os.name ? device.os.name : 'none'})
        if(isIos) {
            hideCta()
        } else {
            const promptEvent = window.deferredPrompt
            if(!promptEvent) {
                return
            }

            promptEvent.prompt()
            const result = await promptEvent.userChoice
            console.log('👍', 'userChoice', result)
            window.deferredPrompt = null
            hideCta()
        }
    }

    return  <div className={`install-cta ${state ? 'visible' : ''} ${animation || ''}`}>
                <div className='install-cta_card'>
                    <span className='pi pi-times' onClick={() => {
                        ym('reachGoal', 'DISMISS_INSTALL_CTA', {platform: device && device.os && device.os.name ? device.os.name : 'none'})
                        hideCta()
                    }}></span>
                    <Lottie
                        options={defaultOptions}
                        width={200}
                    />
                    <div className='title'>С приложением {window.location.host} удобнее!</div>

                    <ul>
                        <li>быстрый доступ к контенту</li>
                        <li>подписка на игроков и команды</li>
                        <li>уведомления о результатах и событиях</li>
                    </ul>

                    <div className='desc'>{isIos ? "перейди в " : "нажми"} <span>{isIos ? <>«‎<img src={Share} alt="«Поделиться»" height="15" width="10"/>»</> : '«‎Установить»'}</span> а затем {isIos ? (<span>«‎Добавить на домашний экран»</span>) : 'подтверди действие ;)'}</div>‎‎

                    <div className='actions'>
                        <Button
                            className='cancel'
                            label='Не показывать больше'
                            onClick={() => {
                                localStorage.setItem('NO_INSTALL_CTA', 'true')
                                ym('reachGoal', 'DISMISS_INSTALL_CTA', {platform: device && device.os && device.os.name ? device.os.name : 'none'})
                                hideCta()
                            }}
                        />

                        <Button
                            label={isIos ? 'Ок, понятно' : 'Установить'}
                            icon={isIos ? null : 'pi pi-download'}
                            onClick={() => handleAction()}
                            disabled={loading}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
}

export default InstallCta
