import React from 'react'

import { Button } from 'primereact/button'

import './style.scss'

const BtnSet = ({ size, btns, active, theme, onChange, darken }) => {
    return  <span className='p-buttonset'>
                {btns.map((b, i) => (
                    <Button
                        className={`p-button${size ? '-'+size : ''} ${b.id !== active ? 'p-button-outlined' : 'active'}`}
                        style={{
                            backgroundColor: active === b.id ? `var(--${theme})` : '',
                            borderColor: active !== b.id ? `var(--${theme})` : 'transparent',
                            color: darken ? `var(--${darken})` : active !== b.id ? `var(--${theme})` : ''
                        }}
                        key={i}
                        label={b.label}
                        icon={b.icon ? `pi pi-${b.icon}` : null}
                        onClick={() => {
                            if(b.id !== active) {
                                onChange(b.id)
                            }
                        }}
                    />
                ))}
            </span>
}

export default BtnSet
