import React, { useState, useEffect } from 'react'

import './style.scss'

import { Loader } from '../Atoms'
import Card from '../Club/Feed/Card'

import { ENDPOINT } from '../../env'
import axios from 'axios'

const TagFeed = ({ _id }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        if(_id) {
            axios.get(`${ENDPOINT}client/tagfeed/${_id}`)
                .then(resp => {
                    setData(resp.data)
                })
        }
    }, [_id])

    return  <div className='tag-feed'>
                {!data ? (
                    <Loader width={200} />
                ) : data.feed.map((item, i) => (
                        <Card data={item} key={i} />
                    ))
                }
            </div>
}

export default TagFeed
