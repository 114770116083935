import React from 'react'
import { TabMenu } from 'primereact/tabmenu'
import './style.scss'

const ContentTabs = ({ layout, width, model, theme, activeIdx, onChange }) => {
    return  <div
                className={`content-tabs ${layout}`}
                style={{
                    width: width
                }}
            >
                <div
                    className='content-tabs_wrap'
                    style={{
                        background: `var(--${theme}-50)`
                    }}
                >
                    <TabMenu model={model} className={`__${theme}`} activeIndex={activeIdx} onTabChange={onChange} />
                </div>
            </div>
}

export default ContentTabs
