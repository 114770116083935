import React, { useState, useEffect, useContext } from 'react'

import StorageContext from 'storage'

import {useHistory, useLocation, useParams} from 'react-router-dom'

import { BtnLink, Link, Emblem } from '../Atoms'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Sidebar } from 'primereact/sidebar'
import { Tag } from 'primereact/tag'
import { randomizeList } from '../../utils'

import Account from './Account'
import Search from './Search'

import axios from 'axios'
import { ENDPOINT } from '../../env'

import { CONF } from '../../configs'

import LayoutContext from '../../Layouts/Mobile/ctx'
import DesktopContext from '../../Layouts/Desktop/ctx'

import './style.scss'

const Navi = ({ data, theme }) => {
    const MAX_QTY = 4
    const rows = data.reduce((acc, tour, idx) => {
        const target = idx === 8 ? 1 : acc.findIndex(arr => arr.length < MAX_QTY)
        if(acc[target]) {
            acc[target].push(tour)
        }
        return acc
    }, [[], []])

    return rows.map((row, i) => (
        <div className="navi-group" key={i}>
            {row.map((link, i) => (
                <Link
                    key={`top_link_${i}`}
                    color={theme.accentText}
                    path={link.type === 'tournament' && link._id ? `/tournament/${link._id}` : null}
                    nested={link.children || null}
                    text={link.name.replace(/— \d{2,4}/, '')}
                />
            ))}
        </div>
    ))
}

const Nav = ({ data, theme }) => {
    const [activeTab, setActiveTab] = useState(-1)
    const MAX_QTY = 4
    const rows = data.reduce((acc, tour, idx) => {
        const target = idx === 8 ? 1 : acc.findIndex(arr => arr.length < MAX_QTY)
        if(acc[target]) {
            acc[target].push(tour)
        }
        return acc
    }, [[], []])

    const location = useLocation()

    useEffect(() => {
        if (!location.pathname.includes('/tournament/')) {
            setActiveTab(-1)
        } else if (activeTab === -1 && data) {
            setActiveTab(data.findIndex(d =>
                (d._id === location.pathname.replace('/tournament/', '')
                ||
                (d.children && d.children.length !== 0 ? d.children.findIndex(dc =>
                    dc._id === location.pathname.replace('/tournament/', '')
                ) !== -1 : false))
            ))
        }
    }, [location.pathname])

    return rows.map((row, _i) => (
        <div className="navi-group" key={_i}>
            {row.map((link, i) => (
                <Link
                    key={`top_link_${i}`}
                    idx={(_i*4)+i}
                    color={theme.accentText}
                    path={link.href || link.type === 'tournament' && link._id ? `/tournament/${link._id}` : null}
                    nested={link.children || null}
                    text={link.text || link.name.replace(/— \d{2,4}/, '')}
                    action={link.type === 'static' ? () => window.open(link.href) : null}
                    active={activeTab === (_i*4)+i}
                    setActiveTab={setActiveTab}
                />
            ))}
        </div>
    ))
}

const Top = (props) => {
    const { layout, width } = props
    const lctx = useContext(LayoutContext)
    const dctx = useContext(DesktopContext)
    const sctx = useContext(StorageContext)
    const { activeSubject, setActiveSubject, setUser, appUser, accountOpen, setAccountOpen, config } = layout === 'mobile' ? lctx : dctx
    const { theme, emblem, promo, groupByLeagues } = config ? config.top : props

    const [bannersNode, setBannersNode] = useState(null)
    const [tournaments, setTournaments] = useState(null)
    const [nav, setNav] = useState(null)
    const [visibleSidebar, setVisibleSidebar] = useState(false)
    const [accountHook, setAccountHook] = useState(true)
    const [suggestions, setSuggestions] = useState([])
    const [federation, setFederation] = useState(null)

    const history = useHistory()

    useEffect(() => {
        if(!visibleSidebar) {
            setSuggestions([])
        }
    }, [visibleSidebar])

    useEffect(() => {
        setTimeout(() => {
            setAccountOpen(false)
        }, 2000)

        setTimeout(() => {
            setAccountHook(false)
        }, 2500)

        const { federation } = CONF
        setFederation(federation)

        sctx.fillEmpty('first_seen')

        axios.get(`${ENDPOINT}client/nav/${federation._id}`)
            .then(nav => {
                setNav(nav.data)
            })
    }, [])

    useEffect(() => {
        renderBanners(CONF.topBanners, CONF.topLinks, layout)
            .then(node => {
                setBannersNode(node)
            })

        if(federation) {
            axios.get(`${ENDPOINT}client/tournaments_navi/${federation._id}${groupByLeagues && (layout !== 'desktop') ? '?groupByLeagues=true' : ''}`)
                .then(navi => {
                    setTournaments(navi.data)
                    if(window.location.pathname.includes('tournament/')) {
                        const id = window.location.pathname.split('/')[2]
                        const l = navi.data.findIndex(l => l.list && l.list.find(t => t._id === id))
                        if(l > -1) {
                            const active = navi.data[l].list.find(t => t._id === id)
                            if(active) {
                                setActiveSubject({name: active.name, _id: active._id, type: 'tournament', naviIndex: l})
                            }
                        }
                    }
                })
        }
    }, [promo, layout, federation])

    const rightAligned = CONF.topBanners && CONF.topBanners.length > 2

    return  layout === 'desktop' ? (
                <div className={`top ${layout}`} style={{width: width}}>
                    <div
                        className='surface'
                        style={{backgroundColor: 'var(--global-surface)'}}
                    >
                        <div className='container'>
                            <div className={`banners-node ${CONF.configName}`}>{bannersNode}{!rightAligned ? <Search setSuggestions={setSuggestions} isDesktop /> : null}</div>

                            <div className='emblem'>
                                <img src={CONF.brandEmblem}  onClick={() => history.push('/')}/>
                            </div>

                            <div className='account'>
                                {rightAligned ? <Search setSuggestions={setSuggestions} isDesktop rightAligned /> : null}

                                <Button
                                    style={{
                                        color: `var(--top-account-btn-color)`,
                                        backgroundColor: `var(--top-account-btn-bgd)`
                                    }}
                                    className='p-button-sm p-button-outlined'
                                    icon={`pi pi-${appUser && appUser.profile ? 'star' : 'unlock'}`}
                                    label={appUser && appUser.profile ? appUser.profile.name : 'Войти'}
                                    onClick={() => setAccountOpen(true)}
                                />
                                {!appUser ? <Link color={'global-tag-color'} text='Регистрация' action={() => setAccountOpen(true)} /> : null}
                            </div>
                        </div>
                    </div>

                    <div
                        className='accent'
                        style={{backgroundColor: 'var(--top-search-accent)'}}
                    >
                        <div className='container'>
                            {!tournaments ? [
                                <div className='navi-group' key='g1'>
                                    <Skeleton width='120px' />
                                    <Skeleton width='120px' />
                                    <Skeleton width='120px' />
                                </div>,
                                <div className='navi-group' key='g2'>
                                    <Skeleton width='120px' />
                                    <Skeleton width='120px' />
                                    <Skeleton width='120px' />
                                </div>
                            ] : (
                                nav ? <Nav data={nav} theme={theme} /> : null
                                /*<Navi
                                    data={tournaments}
                                    theme={theme}
                                    layout={'desktop'}
                                />*/
                            )}
                        </div>
                    </div>

                    <Sidebar
                        visible={accountOpen}
                        onHide={() => setAccountOpen(false)}
                        position='right'
                        blockScroll={true}
                        style={{width: 360}}
                        maskClassName={accountHook ? 'hooked' : ''}
                    >
                        <Account
                            hideAccount={() => setAccountOpen(false)}
                            setAppUser={setUser}
                            accountHook={accountHook}
                        />
                    </Sidebar>
                </div>
            ) : (
                <div className={`top ${layout}`} style={{width: width}}>
                    <Sidebar
                        visible={visibleSidebar}
                        onHide={() => setVisibleSidebar(false)}
                        blockScroll={true}
                        maskClassName='mobile-menu'
                    >
                        <Search setSuggestions={setSuggestions} />

                        {suggestions && suggestions.length ? (
                            <div className='search-suggestions'>
                                <div className='top'>
                                    <Tag icon='pi pi-star-fill' value='Результаты поиска' />
                                </div>

                                <div className='items'>
                                    {suggestions.map((f, i) => (
                                        <div className='item' onClick={() => {
                                            history.push(f.url)
                                            setVisibleSidebar(false)
                                        }}>
                                            <Emblem size='sm' source={f.img} backdroped={true} player={f.type === 'player'}/>
                                            <div className='name alterfont'>{f.info}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}

                        {groupByLeagues ? (
                            <div className='sidebar-menu'>
                                <Accordion
                                    activeIndex={activeSubject ? activeSubject.naviIndex || null : null}
                                >
                                    {nav ? [activeSubject ? {name: 'На главную', fallback: true} : null].filter(t => t).concat(nav.filter(n => n.type !== 'static')).map((group, li) => (
                                        <AccordionTab
                                            key={li}
                                            className={group.type === 'tournament' || group.fallback ? 'directLink' : ''}
                                            header={group.name}
                                            onClick={() => {
                                                if(group.fallback) {
                                                    history.push('/')
                                                    setActiveSubject(null)
                                                    setVisibleSidebar(false)
                                                } else {
                                                    if(group.type === 'tournament') {
                                                        history.push(`/tournament/${group._id}`)
                                                        setActiveSubject({name: group.name, _id: group._id, type: 'tournament', naviIndex: li})
                                                        setVisibleSidebar(false)
                                                    } else {
                                                        return null
                                                    }
                                                }
                                            }}
                                        >
                                            {group.children ? group.children.map((t, i) => (
                                                <div
                                                    className={'sub-accordion'+(activeSubject && activeSubject._id === t._id ? ' current' : '')}
                                                    key={i}
                                                    onClick={() => {
                                                        setActiveSubject({name: t.name, _id: t._id, type: 'tournament', naviIndex: li})
                                                        history.push(`/tournament/${t._id}`)
                                                        setVisibleSidebar(false)
                                                    }}
                                                >{t.name}</div>
                                            )) : null}
                                        </AccordionTab>
                                    )) : null}

                                    {nav ? nav.filter(n => n.type === 'static').map(link => (
                                        <AccordionTab
                                            className='directLink'
                                            header={link.text}
                                            onClick={() => window.open(link.href)}
                                        />
                                    )) : null}
                                </Accordion>
                            </div>
                        ) : null}
                    </Sidebar>

                    <Sidebar
                        visible={accountOpen}
                        onHide={() => setAccountOpen(false)}
                        position={layout === 'mobile' ? 'top' : 'right'}
                        blockScroll={true}
                        maskClassName={accountHook ? 'hooked account-sidebar'+(layout === 'mobile' ? ' _top' : '') : 'account-sidebar'+(layout === 'mobile' ? ' _top' : '')}
                    >
                        <Account
                            hideAccount={() => setAccountOpen(false)}
                            setAppUser={setUser}
                            accountHook={accountHook}
                            isIos={props.device && props.device.os && props.device.os.name === 'iOS'}
                            layout={layout}
                        />
                    </Sidebar>

                    <div
                       className={`surface ${CONF.configName}`}
                       style={{backgroundColor: 'var(--global-surface)'}}
                    >{bannersNode}</div>
                    <div className='emblem' onClick={() => {
                        history.push('/')
                        setActiveSubject(null)
                    }}>
                        <img src={CONF.brandEmblem} />
                    </div>
                    <div
                        className='accent'
                        style={{backgroundColor: `var(--top-search-accent)`}}
                    >
                        <BtnLink
                            className='mobile-menu-trigger'
                            label={activeSubject ? activeSubject.name : 'Все турниры'}
                            icon='list'
                            handler={() => setVisibleSidebar(true)}
                        />

                        <BtnLink label='Ваш аккаунт' icon='star' iconPos='right' handler={() => setAccountOpen(true)} />
                    </div>
                </div>
            )
}

export default React.memo(Top)

const renderBanners = async (array, links, layout) => {
    const newArray = links ? array.map((i, index) => ({href: i, link: links[index]})) : array.map(i => ({href: i}))
    let source

    if(layout === 'desktop') {
        source = newArray.map(i => [i])
    } else {
        const cells = 2 //TODO: bind to layout
        const maxInCell = 3 //TODO: bind to layout

        const shuffled = await randomizeList(newArray)
        source = shuffled.reduce((acc, el) => {
            if((!acc[0] || acc[acc.length - 1].length === maxInCell) && (acc.length < cells)) {
                acc.push([])
            }

            if(acc[acc.length - 1].length < maxInCell) {
                acc[acc.length - 1].push(el)
            }

            return acc
        }, [])
    }

    return source.map((cell, i) => (
        <div key={i}>
            {cell.map((src, i) => (
                <a href={src.link}>
                    <img src={src.href} key={i}/>
                </a>
            ))}
        </div>
    ))
}
