import React from 'react'

import './style.scss'

const IconsNavi = ({ layout, width, iconsStyle, items, theme, active, onChange }) => {
    return  <div
                className={`icons-navi ${layout}`}
                style={{
                    width: width
                }}
            >
                {items.map((item, idx) => (
                    <div
                        key={idx}
                        onClick={()=> onChange(idx)}
                        className={`icons-navi_item${active === idx ? ' active' : ''}`}
                        style={layout === 'desktop' ? {} : {
                            backgroundColor: `var(--${active === idx ? theme.activeSurface : theme.surface})`,
                            color: `var(--${active === idx ? theme.activeAccent : theme.accent})`,
                            boxShadow: `0px 4px 10px ${active === idx ? theme.activeShadow : theme.shadow}`,
                        }}
                    >
                        <div
                            className='icons-navi_item_icon'
                            style={{
                                backgroundColor: layout === 'desktop' ? 'unset' : `var(--${active === idx ? theme.activeAccent : theme.accent})`,
                                maskImage: `url(${require('./'+iconsStyle+'/'+item.icon+'.png')})`,
                                webkitMaskImage: `url(${require('./'+iconsStyle+'/'+item.icon+'.png')})`
                            }}
                        ></div>
                        <span className='alterfont'>{item.label}</span>
                    </div>
                ))}
            </div>
}

export default IconsNavi
