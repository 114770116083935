import React, { useState, useEffect, useContext } from 'react'

import StorageContext from 'storage'

import { ProgressSpinner } from 'primereact/progressspinner'

import { auth, authObserver } from './firebase'

import Form from './Form'
import WelcomeFlow from './WelcomeFlow'
import UserWidget from './UserWidget'

import './style.scss'
import './mobile-account.scss'

import { ENDPOINT } from '../../../env'
import { CONF } from '../../../configs'
import axios from 'axios'

const Account = ({ hideAccount, setAppUser, accountHook, isIos, layout }) => {
    const [firebaseUser, setFirebaseUser] = useState(null)
    const [binding, setBinding] = useState(false)
    const [user, setUser] = useState(null)
    const [federation, setFederation] = useState(null)
    const [initedFlag, setInitedFlag] = useState(false)
    const [nativeToken, setNativeToken] = useState(null)

    const isMobile = layout && layout === 'mobile'

    const sctx = useContext(StorageContext)

    const bindUser = () => {
        if(firebaseUser.uid) {
            setBinding(true)
            const { federation } = CONF
            setFederation(federation._id)
            axios.get(`${ENDPOINT}client/binduser/${firebaseUser.uid}?scope=${federation._id}&tkn=${firebaseUser.token}&phone=${firebaseUser.phone}`)
                .then(resp => {
                    console.log('GOT USER', resp.data)
                    sctx.fillEmpty('first_authorized')
                    setUser(resp.data)
                    setAppUser(resp.data)
                    setBinding(false)
                    if(window && window.top && resp.data.creds) {
                        window.top.postMessage({
                            type: 'wrapCredentials',
                            credentials: resp.data.creds,
                            firebaseId: firebaseUser.uid
                        }, '*')
                    }
                })
        }
    }

    useEffect(() => {
        const urAuthObserver = authObserver(auth, (user) => {
            setFirebaseUser(user ? {token: user.accessToken, uid: user.uid, phone: user.phoneNumber} : 'unauthorized')
        })

        return () => urAuthObserver()
    }, [])

    useEffect(() => {
        if(!nativeToken && user) {
            const handleNativeToken = msg => {
                if(msg.data && msg.data.action === 'registerNativeToken') {
                    if(msg.data.content) {
                        axios.put(`${ENDPOINT}user/${user.firebaseTkn}`, {fcmToken: msg.data.content, clientId: user.clientId})
                            .then(resp => {
                                if(resp.data) {
                                    console.log('Setting saved native token', msg.data.content)
                                    setNativeToken(msg.data.content)
                                }
                            })
                    }
                }
            }

            window.addEventListener('message', handleNativeToken)
            return () => window.addEventListener('message', handleNativeToken)
        }
    }, [nativeToken, user])

    useEffect(() => {
        if(firebaseUser) {
            bindUser()
        }
    }, [firebaseUser])

    return  <div className='account'>
                {!firebaseUser || binding ? (
                    <ProgressSpinner className='sidebar-spinner' />
                ) : firebaseUser === 'unauthorized' ? (
                    <Form
                        setUser={setFirebaseUser}
                        setInitedFlag={setInitedFlag}
                    />
                ) : user && initedFlag ? (
                    <WelcomeFlow
                        federation={federation}
                        data={user}
                        passedFlow={() => {
                            setInitedFlag(false)
                            bindUser()
                        }}
                    />
                ) : user ? (
                    <UserWidget
                        data={user}
                        hideAccount={hideAccount}
                        nativeToken={nativeToken}
                        setNativeToken={setNativeToken}
                        isIos={isIos}
                    />
                ) : null}
            </div>
}

export default Account
