import React, { useRef, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { OrganizationChart } from 'primereact/organizationchart'
import CustomScrollbars from 'react-custom-scrollbars-2'

import MobileLegs from './MobileLegs'

import './style.scss'
import './legs.scss'

const handleEmblemErr = e => {
    e.target.src = require('./football-club.png')
}

const renderOleEmblem = club => {
    if(club.origin && club.origin.source === 'ole') {
        return `${process.env.FILES_BUCKET}clubs/ole/${club.origin.id}.png`
    } else {
        return null
    }
}

const Standings = ({ chess, layout, theme, width, config, data, wrapped, highlight, title }) => {
    const history = useHistory()

    const linkHandle = (event) => {
        event.preventDefault();
        history.push(event.currentTarget.getAttribute('href'))
    }

    const nodeTemplate = node => {
        return  <div className='playoff-node' onClick={() => node.homeClub && node.awayClub ? history.push(`/match/${node._id}`) : null}>
                    <div className='row'>
                        <span className='emb'>
                            {node.homeClub ? <img src={node.homeClub.club ? node.homeClub.club.emblem || renderOleEmblem(node.homeClub.club) || '' : ''} onError={handleEmblemErr} /> : null}
                        </span>
                        <div className='name'>{node.homeClub ? node.homeClub.name : null}</div>
                        <div className='score alterfont'>{node.scores && node.scores.full ? node.scores.full.home : '-'}</div>
                    </div>

                    {node.scores && node.scores.shootout && (node.scores.shootout.home || node.scores.shootout.away) ? (
                        <div className='shootout'>{`пен. ${node.scores.shootout.home}:${node.scores.shootout.away}`}</div>
                    ) : null}

                    <div className='row'>
                        <span className='emb'>
                            {node.awayClub ? <img src={node.awayClub.club ? node.awayClub.club.emblem || renderOleEmblem(node.awayClub.club) || '' : ''} onError={handleEmblemErr} /> : null}
                        </span>
                        <div className='name'>{node.awayClub ? node.awayClub.name : null}</div>
                        <div className='score alterfont'>{node.scores && node.scores.full ? node.scores.full.away : '-'}</div>
                    </div>
                </div>
    }

    return  data ? data.nodes ? layout === 'mobile' && data.splitted ? (
                <MobileLegs data={data.splitted} />
            ) : data.nodes.length ? (
                <div className='legs-nodes'>
                    {data.isStartPoint ? (
                        <div className='nodes-row'>
                            {data.nodes.map((node, i) => (
                                <div key={i} className='p-organizationchart-node-content'>
                                    {nodeTemplate(node)}
                                </div>
                            ))}
                        </div>
                    ) : data.nodes[0]?.children ? (
                        <OrganizationChart value={data.nodes} selectionMode='multiple' nodeTemplate={nodeTemplate} />
                    ) : <div className='nodes-row one'>
                            <div className='p-organizationchart-node-content'>
                                {nodeTemplate(data.nodes[0])}
                            </div>
                    </div>}
                </div>
            ) : null : (!chess || layout === 'desktop') ? <div
                className={`standings ${layout} ${chess ? ' chess-within' : ''}`}
                style={{
                    width: width,
                    background: `var(--${theme.surface})`,
                    color: `var(--${theme.text}-700)`
                }}
            >
                <div
                    className={'standings-row standings-head'+(config.compact ? ' compact' : '')}
                    style={{
                        background: wrapped ? 'transparent' : `var(--${theme.divider})`
                    }}
                >
                    <div className='standings-row_team'>
                        <div className='standings-cell __pos alterfont'></div>
                        <div className='standings-cell __team'>{title || ''}</div>
                    </div>
                    {chess ? (
                        <div
                            className='standings-row_chess-inject head'
                        >
                            {data.map((r, i) => (
                                <div
                                    className='standings-row_chess-inject_cell'
                                    style={{width: `calc(100% / ${data.length})`}}
                                >{i+1}</div>
                            ))}
                        </div>
                    ) : null}
                    <div className='standings-row_values'>
                        <div className='standings-cell __val'>И</div>
                        {!config.compact ? <div className='standings-cell __val'>В</div> : null}
                        {!config.compact ? <div className='standings-cell __val'>Н</div> : null}
                        {!config.compact ? <div className='standings-cell __val'>П</div> : null}
                        {!config.compact ? <div className='standings-cell __val __diff'>Голы</div> : null}
                        <div className='standings-cell __val __pts'>О</div>
                    </div>
                </div>
                <div className={wrapped ? 'content-body' : ''}>
                    {data?.length && data.map((row, i) => (
                        <div
                            key={i}
                            className={'standings-row'+(config.compact ? ' compact' : '')+(highlight && highlight === row.team._id ? ' highlight' : '')}
                            style={{
                                background: i%2!==0 ? theme.even.includes('#') ? theme.even : `var(--${theme.even})` : '',
                                borderTop: i%2!==0 ? `1px solid ${ theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`}` : 'none'
                            }}
                        >
                            <div className='standings-row_team'>
                                <div className='standings-cell __pos alterfont'>
                                    <span>{i+1}</span>
                                    <span>
                                        {row.trend ? <i className={`standings-trend __${row.trend}`}></i> : null}
                                    </span>
                                </div>
                                <a href={`/club/${row.team.club._id}`} className='standings-cell __team' onClick={linkHandle}>
                                    <span className='emb' data-club={row.team && row.team.club && row.team.club.origin ? row.team.club.origin.id || '' : ''}>
                                        <img src={row.team && row.team.emblem ? row.team.emblem : row.team.club ? row.team.club.emblem || renderOleEmblem(row.team.club) || '' : ''} onError={handleEmblemErr} />
                                    </span>
                                    <span>{row.team ? row.team.name : ''}</span>
                                </a>
                            </div>
                            {chess ? (
                                <div
                                    className='standings-row_chess-inject'
                                >
                                    {row.chess ? row.chess.map((r, _i) => (
                                        <div
                                            className={`standings-row_chess-inject_cell ${_i === i ? ' _self' : ''}`}
                                            style={{
                                                width: `calc(100% / ${data.length})`,
                                                borderColor: theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`
                                            }}
                                        >{r.map(m => (
                                            <a href={`/match/${m._id}`} onClick={linkHandle}>{m.score.replace(' ', '')}</a>
                                        ))}</div>
                                    )) : null}
                                </div>
                            ) : null}
                            <div className='standings-row_values'>
                                <div className='standings-cell __val'>{row.pld}</div>
                                {!config.compact ? <div className='standings-cell __val alterfont'>{row.w}</div> : null}
                                {!config.compact ? <div className='standings-cell __val alterfont'>{row.d}</div> : null}
                                {!config.compact ? <div className='standings-cell __val alterfont'>{row.l}</div> : null}
                                {!config.compact ? <div className='standings-cell __val __diff alterfont'>{`${row.gs}-${row.gc}`}<i>{row.diff}</i></div> : null}
                                <div className='standings-cell __val __pts alterfont'>{row.pts}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> : (
                <div
                    className={`standings chess ${layout} ${wrapped ? ' content-body' : ''}`}
                    style={{
                        width: layout === 'mobile' ? width : '100%',
                        color: `var(--${theme.text}-700)`
                    }}
                >
                    {layout === 'mobile' ? <div className='chess-teams-list'>
                        <div
                            className='standings-row standings-head'
                            style={{
                                //background: `var(--blue-50)`,
                                background: '#fff',
                                borderBottom: `1px solid ${theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`}`,
                                paddingLeft: 38
                            }}
                        >Команда</div>
                        {data.map((row, i) => (
                            <div
                                key={i}
                                className={'standings-row'}
                                style={{
                                    //background: i%2!==0 ? theme.even.includes('#') ? theme.even : `var(--${theme.even})` : '',
                                    background: '#fafcff',
                                    borderBottom: `1px solid ${ theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`}`
                                }}
                            >
                                <div className='standings-row_team' onClick={() => history.push(`/club/${row.team.club._id}`)}>
                                    <div className='standings-cell __pos'>
                                        <span>{i+1}</span>
                                    </div>
                                    <div className='standings-cell __team'>
                                        <span>{row.team.name}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> : null}
                    {layout === 'mobile' ? (<div className='chess-body'>
                        <CustomScrollbars autoHeight autoHeightMin={(44*data.length)-12}>
                            <div
                                className='standings-row standings-head'
                                style={{
                                    background: wrapped ? 'transparent' : `var(--blue-50)`,
                                    borderBottom: `1px solid ${ theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`}`
                                }}
                            >
                                <div className='standings-row chess-cells' style={{width: data.length*40}}>
                                    {data.map((cell, i) => (
                                        <div className={`chess-cells_cell`} key={i}>{i+1}</div>
                                    ))}
                                </div>
                                <div className='standings-row_values'>
                                    <div className='standings-cell __val'>И</div>
                                    <div className='standings-cell __val'>В</div>
                                    <div className='standings-cell __val'>Н</div>
                                    <div className='standings-cell __val'>П</div>
                                    <div className='standings-cell __val __diff'>Мячи<i></i></div>
                                    <div className='standings-cell __val __pts'>О</div>
                                </div>
                            </div>
                            {data.map((row, i) => (
                                <div
                                    key={i}
                                    className={'standings-row'}
                                    style={{
                                        // width: (data.length*44)+260,
                                        //background: i%2!==0 ? theme.even.includes('#') ? theme.even : `var(--${theme.even})` : '',
                                        borderBottom: `1px solid ${ theme.evenBorder.includes('#') ? theme.evenBorder : `var(--${theme.evenBorder})`}`
                                    }}
                                >
                                    <div className='chess-cells'>
                                    {row.chess ? row.chess.map((cell, _i) => {
                                        return  <div className={`chess-cells_cell ${_i === i ? 'self' : ''}`} key={_i}>
                                                    {cell && _i !== i ? cell.map((m, _m) => (
                                                        <span onClick={() => history.push(`/match/${m._id}`)} key={_m}>{m.score}</span>
                                                    )) : (_i === i) ? <span className='alterfont'>{_i+1}</span> : null}
                                                </div>
                                    }) : null}
                                    </div>
                                    <div className='standings-row_values'>
                                        <div className='standings-cell __val'>{row.pld}</div>
                                        <div className='standings-cell __val'>{row.w}</div>
                                        <div className='standings-cell __val'>{row.d}</div>
                                        <div className='standings-cell __val'>{row.l}</div>
                                        <div className='standings-cell __val __diff'>{`${row.gs}-${row.gc}`}<i>{row.diff}</i></div>
                                        <div className='standings-cell __val __pts'>{row.pts}</div>
                                    </div>
                                </div>
                            ))}
                        </CustomScrollbars>
                    </div>) : null}
                </div>
            ) : null
}

export default Standings
