import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'

import { CONF } from '../../../configs'
const VAPID = CONF.firebase.vapid
const app = initializeApp(CONF.firebase.config)

const initMessaging = async () => {
    const available = await isSupported()
    if(available) {
        const messaging = getMessaging(app)

        return {
            VAPID,
            messaging,
            getMessagingToken: getToken
        }
    } else {
        console.log('Unsupported messaging SDK')
        return {}
    }
}

export default initMessaging
