import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import { MediaBlock, MediaBlockV2, Highlights, BottomStories, MatchesCarousel, StatsBlock, Tournament, TagFeed } from '../../../Components'
import { TopLineup, AchievementsCarousel, SeriesStack, Loader } from '../../../Components/Atoms'
import { Feed, PositionMap, Profile, Summary, Career } from '../../../Components/Player/blocks'
import { Info, Rosters, Events, ScoreNode, MatchPubs, Stats } from '../../../Components/Match/blocks'
import { ClubTop, ClubFeed, TeamStandings, TeamPlayers } from '../../../Components/Club/blocks'
import MobileMatches from '../../../Components/MatchesCarousel/Mobile'
import MultiWrap from '../../../Components/Standings/MultiWrap'

import { ENDPOINT } from '../../../env'
import { CONF } from '../../../configs'
import axios from 'axios'
import moment from 'moment'

import qs from 'qs'

import LayoutContext from '../ctx'
import StorageContext from 'storage'

const periods = [
    {
        label: '-',
        items: [
            {label: 'В этом году', _id: 'currentYear'},
            {label: 'За всё время', _id: 'overall'}
        ]
    }
]

const toursMapper = tours => {
    try {
        const mapped = Object.keys(tours).map(k => ({
            label: k === 'active' ? 'Текущие' : 'Завершенные',
            items: tours && tours[k] ? tours[k].map(t => ({
                label: `${t.name} (${t.league}, ${t.season})`,
                _id: t._id
            })) : []
        }))

        return mapped
    } catch(e) {
        console.log('Tours map err', tours)
        return null
    }
}

const Root = (props) => {
    const { stackType } = props
    const [toursArr, setToursArr] = useState(null)

    const { config, width } = useContext(LayoutContext)

    const { federation } = CONF

    const types = {
        stories: [
            <MediaBlockV2 {...config.mediablock} layout='mobile' width={width} scope='federation' subjectId={federation._id} />,
            <Highlights {...config.highlights} layout='mobile' width={width} contentType='album' scope='federation' subjectId={federation._id} />,
            // <BottomStories {...props.config.stories} layout='mobile' width={props.width} />
        ],
        matches: [
            <MobileMatches {...config.matchesCarousel} layout='mobile' extendedMode={true} scope='federation' subject={federation._id} />
        ],
        stats: [
            <MultiWrap {...config.standings} width={width} layout='mobile' />,
            <StatsBlock {...config.stats} blured={true} width={width} layout='mobile' />
        ],
        top: [
            <TopLineup scope='federation' subjectId={federation._id} theme={{surface: '#fff', nameColor: 'var(--blue-800)'}} setToursArr={setToursArr} />,
            <AchievementsCarousel scope='federation' toursArr={toursArr} />
        ]
    }

    return types[stackType] || null
}

const ClubStack = (props) => {
    const { stackType, appUser, showAccount } = props

    const [root, setRoot] = useState(null)
    const [team, setTeam] = useState(null)
    const [tours, setTours] = useState(null)
    const { id } = useParams()

    const { config, width } = useContext(LayoutContext)

    useEffect(() => {
        if(id) {
            const { team } = qs.parse(window.location.search.slice(1))

            axios.get(`${ENDPOINT}client/club/${id}`)
                .then(resp => {
                    setRoot(resp.data)
                    if(resp.data.teams && resp.data.teams.length) {
                        setTeam(team && resp.data.teams.find(t => t._id === team) ? team : resp.data.teams.find(t => t.canonical)._id)
                    }
                })
        }
    }, [id])

    useEffect(() => {
        axios.get(`${ENDPOINT}client/teamtours/${team}`)
            .then(resp => {
                setTours(resp.data)
            })
    }, [team])

    const types = {
        stories: [
            <ClubTop
                data={root}
                team={team}
                setTeam={id => {
                    window.history.replaceState({}, null, `?team=${id}`)
                    setTeam(id)
                }}
                favorites={appUser ? appUser.favorites || [] : []}
                appUserId={appUser ? appUser.firebaseTkn : null}
                showAccount={showAccount}
            />,
            <ClubFeed
                team={team}
            />
        ],
        matches: [
            <ClubTop
                data={root}
                team={team}
                setTeam={id => {
                    window.history.replaceState({}, null, `?team=${id}`)
                    setTeam(id)
                }}
            />,
            <MobileMatches {...config.matchesCarousel} layout='mobile' width={width} extendedMode={true} scope='team' subject={team} />
        ],
        stats: [
            <ClubTop
                data={root}
                team={team}
                setTeam={id => {
                    window.history.replaceState({}, null, `?team=${id}`)
                    setTeam(id)
                }}
            />,
            <TeamStandings
                teamId={team}
                theme={config.standings.theme}
                scopedTheme={config.standings.scopedTheme}
                tours={toursMapper(tours)}
            />,
            <SeriesStack
                scope='team'
                subjectId={team}
                title='Клубные рекорды'
            />
        ],
        players: [
            <ClubTop
                data={root}
                team={team}
                setTeam={id => {
                    window.history.replaceState({}, null, `?team=${id}`)
                    setTeam(id)
                }}
            />,
            <TeamPlayers
                tours={tours ? periods.concat(toursMapper(tours)) : null}
                teamId={team}
            />
        ]
    }

    return root ? types[stackType] || null : null
}

const PlayerStack = (props) => {
    const { stackType, appUser, showAccount } = props
    const [player, setPlayer] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT}client/playerCareer/${id}`)
                .then(resp => {
                    setPlayer(resp.data)
                })
        }
    }, [id])

    const types = {
        info: [
            <Profile
                {...player}
                favorites={appUser ? appUser.favorites || [] : []}
                appUserId={appUser ? appUser.firebaseTkn : null}
                showAccount={showAccount}
            />,
            <Summary data={player ? player.career?.career : null} />,
            <PositionMap id={id} />
        ],
        matches: [
            <Feed playerId={id} />
        ],
        stats: [
            <Career teams={player ? player.career?.teams : null} />
        ]
    }

    return types[stackType] || null
}

const MatchStack = (props) => {
    const { stackType, setActiveArea, appUser } = props
    const [data, setData] = useState(null)
    const [useProxy, setUseProxy] = useState(false)
    const [proxyData, setProxyData] = useState(null)
    const [statsData, setStatsData] = useState(null)

    const { id } = useParams()

    const { config, width, bannerNode } = useContext(LayoutContext)
    const sctx = useContext(StorageContext)

    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT}client/matchinfo/${id}?full=true`)
                .then(resp => {
                    setData(resp.data)
                    if(resp.data) {
                        sctx.putContentMarkers([['team', resp.data.home._id], ['team', resp.data.away._id]])
                        if(resp.data.scores && resp.data.scores.full && resp.data.scores.full.home) {
                            setActiveArea(1)
                            if(resp.data.statsData && resp.data.statsData.length) {
                                setStatsData(resp.data.statsData)
                            }
                        } else {
                            if(resp.data.matchday && resp.data.matchday.originDate === moment().format('YY-MM-DD') && !useProxy) {
                                setUseProxy(true)
                                setActiveArea(2)
                            }
                        }
                    }
                })
        }
    }, [id])

    useEffect(() => {
        if(useProxy) {
            const proxyInterval = setInterval(() => {
                axios.get(`${ENDPOINT}client/stateproxy/${id}`)
                    .then(resp => {
                        if(resp.data) {
                            setProxyData(resp.data)
                            if(resp.data.statsData && resp.data.statsData.length) {
                                setStatsData(resp.data.statsData)
                            }
                        }
                    })
            }, 10000)

            return () => clearInterval(proxyInterval)
        }
    }, [useProxy])

    const types = {
        info: data ? [
            <ScoreNode {...config.match} data={data} proxyData={proxyData} />,
            statsData ? (<Stats statsData={statsData} layout='mobile' />) : null,
            bannerNode,
            <Info data={data} matchId={id} appUser={appUser} fromStacks={true} />
        ] : <Loader width={200} />,
        events: data ? [
            <ScoreNode {...config.match} data={data} proxyData={proxyData} />,
            bannerNode,
            <Events data={data} proxyData={proxyData} />
        ] : <Loader width={200} />,
        rosters: data ? [
            <ScoreNode {...config.match} data={data} proxyData={proxyData} />,
            <Rosters data={data} layout='mobile' proxyData={proxyData} />
        ] : <Loader width={200} />,
        media: data ? <MatchPubs data={data.publications} /> : <Loader width={200} />
    }

    return data ? types[stackType] || <Loader width={200} /> : <Loader width={200} />
}

const TournamentStack = (props) => {
    const { stackType } = props
    const { config, width } = useContext(LayoutContext)
    const { federation } = CONF
    const { id } = useParams()

    const types = {
        stories: [
            <MediaBlockV2 {...config.mediablock} layout='mobile' width={width} scope='tag' subjectId={id} />,
            <Highlights {...config.highlights} layout='mobile' width={width} contentType='album' scope='tag' subjectId={id} />
            // <BottomStories {...props.config.stories} layout='mobile' width={props.width} />
        ],
        matches: [
            <MobileMatches {...config.matchesCarousel} layout='mobile' width={width} extendedMode={true} />
        ],
        stats: [
            <Tournament {...config.tournament} width={width} layout='mobile' />
        ],
        top: [
            <TopLineup scope='tournament' theme={{surface: '#fff', nameColor: 'var(--blue-800)'}} />,
            <StatsBlock {...config.stats} scope='tournament' blured={false} width={width} layout='mobile' />,
            <AchievementsCarousel scope='tournament' subjectId={id} />
        ]
    }

    return types[stackType] || null
}

const TagStack = (props) => {
    const { stackType } = props

    const { id } = useParams()

    return <TagFeed _id={id} />
}


export { Root, TournamentStack, PlayerStack, MatchStack, ClubStack, TagStack }
