import React, { useEffect, useState } from 'react'

import { Lineup } from '../../Atoms'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const PositionMap = ({ id }) => {
    const [map, setMap] = useState(null)

    useEffect(() => {
        axios.get(`${ENDPOINT}client/positionmap/${id}`)
            .then(resp => {
                if(resp.data && resp.data.length) {
                    setMap(resp.data)
                }
            })
    }, [id])

    return  <div className='position-map'>
                <div className='title alterfont'>Карта позиций</div>

                <Lineup data={map} width={280} heatmap={true} />
            </div>
}

export default PositionMap
