import React, { useState, useEffect, useContext } from 'react'

import { Skeleton } from 'primereact/skeleton'
import { Dropdown } from 'primereact/dropdown'
import { Emblem, AvatarShield } from '../../Atoms'

import { Tag } from 'primereact/tag'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

import moment from 'moment'

import DesktopContext from '../../../Layouts/Desktop/ctx'

const checkFavorites = (id, arr) => {
    return arr ? arr.find(el => el._id === id) : false
}

const renderAge = str => {
    const years = moment().diff(moment(str, 'DD.MM.YYYY'), 'years').toString()
    const digit = years.slice(-1)
    const form = ['2','3','4'].includes(digit) ? 'года' : digit === '1' ? 'год' : 'лет'

    return `${years} ${form} (${str})`
}

const Profile = ({ _id, name, surname, birthday, avatarUrl, favorites, favoritedBy, appUserId, showAccount, career, selectedTeam, setSelectedTeam }) => {
    const [inFavorites, setInfavorites] = useState(false)
    const [favoritedCount, setFavoritedCount] = useState(favoritedBy || 0)

    const ctx = useContext(DesktopContext)

    const _appUserId = appUserId || (ctx && ctx.appUser ? ctx.appUser.firebaseTkn : null)
    const _showAccount = showAccount || (ctx ? ctx.setAccountOpen : null)
    const _favorites = favorites || (ctx && ctx.appUser ? ctx.appUser.favorites : [])

    const toggleSub = () => {
        if(!_appUserId) {
            _showAccount(true)
        } else {
            axios.put(`${ENDPOINT}user/${_appUserId}`, {
                favorites: inFavorites ? [..._favorites].filter(f => f._id !== _id) : [..._favorites].concat([{
                    img: avatarUrl || null,
                    type: 'player',
                    info: name+' '+surname,
                    _id: _id,
                    url: `/player/${_id}`
                }])
            }).then(resp => {
                setInfavorites(!inFavorites)
            })

            setFavoritedCount(inFavorites ? (favoritedCount - 1) : (favoritedCount + 1))
        }
    }

    useEffect(() => {
        setFavoritedCount(favoritedBy || 0)
    }, [favoritedBy])

    useEffect(() => {
        if(_favorites && _favorites.length) {
            setInfavorites(checkFavorites(_id, _favorites))
        }
    }, [favorites, ctx, favoritedBy])

    const splittedSquads = career && career.teams ? career.teams.reduce((acc, tobj) => {
        const { team, tournaments, ...stats } = tobj
        const target = tobj.unlinked || !tournaments || !tournaments.find(t => !t.tournament.finished) ? 'finished' : 'active'
        if(!acc[target]) { acc[target] = [] }
        acc[target].push({...team, ...stats})
        return acc
    }, {}) : {}

    return  <div className='profile'>
                {!name ? (
                    <div className='avatar-skeleton'>
                        <Skeleton width={160} height={160} />
                    </div>
                ) : <AvatarShield
                    width={260}
                    height={300}
                    source={avatarUrl || ''}
                />}

                <div className='info'>
                    <div className='name alterfont'>{name || (<Skeleton height={24} width={120} />)}</div>
                    <div className='surname alterfont'>{surname || (<Skeleton height={19} width={140} />)}</div>
                    <div className='age'>{name ? !birthday ? 'возраст не указан' : renderAge(birthday) : (<Skeleton height={14} width={80} />)}</div>

                    {_id ? (
                        <div className='player-subs'>
                            <div className='player-subs_title'>подписчиков</div>
                            <div className='player-subs_value alterfont'>
                                <div>{favoritedCount}</div>
                            </div>
                            <div className='player-subs_icon'>
                                <span className='pi pi-star-fill'></span>
                            </div>
                        </div>
                    ) : null}

                    {name ? inFavorites ? (
                        <Tag className='sub negative' icon='pi pi-times' value='из избранного' onClick={() => toggleSub()} />
                    ) : (
                        <Tag className='sub positive' icon='pi pi-bell' value='в избранное' onClick={() => toggleSub()} />
                    ) : null}
                </div>

                <div className='squads'>
                    <div className='section'>
                        {splittedSquads.active && splittedSquads.active.length ? [
                            <div className='label'>Активные заявки</div>,
                            <div className='items'>
                            {splittedSquads.active.map((s, i) => (
                                <div className={`item${selectedTeam && selectedTeam._id === s._id ? ' selected' : ''}`} key={i} onClick={() => setSelectedTeam(s)}>
                                    <Emblem source={s.club.emblem} size='xs' backdropped={true} />
                                    <span>{s.name}</span>
                                </div>
                            ))}
                            </div>
                        ] : null}
                    </div>

                    <div className='section'>
                        {splittedSquads.finished && splittedSquads.finished.length ? (
                            <Dropdown
                                placeholder='Завершенные заявки'
                                options={splittedSquads.finished}
                                optionLabel='name'
                                value={selectedTeam}
                                onChange={s => setSelectedTeam(s.value)}
                                showClear={true}
                            />
                        ) : null}
                        {/*splittedSquads.finished && splittedSquads.finished.length ? [
                            <div className='label'>Завершенные заявки</div>,
                            <div className='items'>
                                {splittedSquads.finished.map((s, i) => (
                                    <div className={`item${selectedTeam && selectedTeam._id === s._id ? ' selected' : ''}`} key={i} onClick={() => setSelectedTeam(s)}>
                                        <Emblem source={s.club.emblem} size='xs' backdropped={true} />
                                        <span>{s.name}</span>
                                    </div>
                                ))}
                            </div>
                        ] : null*/}
                    </div>
                </div>
            </div>
}

export default Profile
