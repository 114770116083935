import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Tag } from 'primereact/tag'
import { Chip } from 'primereact/chip'

import { Loader } from '../Atoms'

import VideoItem from './VideoItem'
import AlbumItem from './AlbumItem'
import MixedItem from './MixedItem'

import { ENDPOINT } from '../../env'
import axios from 'axios'

import moment from 'moment'

import './style.scss'

const itemTypes = {
    video: VideoItem,
    album: AlbumItem,
    mixed: MixedItem,
    text: MixedItem
}

const Publication = ({ id }) => {
    const [data, setData] = useState(null)

    const history = useHistory()

    useEffect(() => {
        if(id) {
            axios.get(`${ENDPOINT}client/publication/${id}`)
                .then(resp => {
                    setData(resp.data)
                })
        }
    }, [id])

    const Specified = data ? itemTypes[data.type] || 'No specified layout' : null
    const titleRe = data ? new RegExp(data.title, 'g') : ''

    return  !data ? (
                <Loader width={200} />
            ) : (
                <div className='publication'>
                    <div className='title alterfont'>{data.title}</div>
                    <div className='meta'>
                        <div>{moment(data.date, 'YY-MM-DD').format('D MMMM YYYY')}</div>
                        {data.categoryTag ? <Tag>#{data.categoryTag.toLowerCase()}</Tag> : null}
                    </div>

                    {data.tags && data.tags.length ? (
                        <div className='tags'>
                            {data.tags.map((t, i) => (
                                <Chip onClick={() => history.push(`/feed/${t._id}`)} key={i} label={t.text} icon='pi pi-hashtag' />
                            ))}
                        </div>
                    ) : null}

                    <div>
                        {Specified ? (
                            <Specified
                                gallery={data.type === 'album' ? [...data.attachments] : null}
                                content={data.content ? data.content.replace(titleRe, '') : ''}
                                slides={data.type === 'mixed' ? [...data.attachments] : null}
                                imported={data.type === 'text' && data.socialId}
                                pubId={data._id}
                                idx={0}
                            />
                        ) : null}
                    </div>
                </div>
            )
}

export default Publication
