import React, {useState, useEffect, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import moment from 'moment'
import { BtnPull } from '../Atoms'

import axios from 'axios'
import { ENDPOINT } from '../../env'

import DesktopContext from '../../Layouts/Desktop/ctx'

import './style.scss'

const getPhotoSrc = obj => {
    if(obj?.sizes && obj?.sizes?.length) {
        return obj?.sizes?.sort((a, b) => a.height - b.height)[0].url
    }
    return ''
}

const getVideoSrc = obj => {
    if(obj.image && obj.image.length) {
        return obj.image.sort((a, b) => b.height - a.height)[0].url
    } else {
        return ''
    }
}

const Feed = ({ layout, theme, width, subjectId }) => {
    const [data, setData] = useState(null)
    const [categories, setCategories] = useState([])
    const [filterCategory, setFilterCategory] = useState(null)
    const [page, setPage] = useState(0)

    useEffect(() => {
        axios.get(`${ENDPOINT}client/feed/${subjectId}`)
            .then(resp => {
                if(resp.data) {
                    setData(resp.data)
                }
            })
    }, [])

    useEffect(() => {
        if(data) {
            const set = new Set(data.map(i => JSON.stringify(i.category)))
            setCategories(Array.from(set).map(i => JSON.parse(i)))
        }
    }, [data])

    const output = data ? data.filter(i => filterCategory ? i.category._id === filterCategory : i) : []

    const template1 = {
        layout: 'PageLinks'
    };

    const templateDefault = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
    };

    return  <div
                className={`feed ${layout}`}
                style={{
                    borderColor: `var(--${theme.border})`
                }}
            >
                {categories.length && categories.length > 1 ? (
                    <BtnPull
                        pull={[{_id: null, name: 'Все материалы'}].concat(categories.filter(c => c.name && c.name.length))}
                        active={filterCategory}
                        theme={theme.buttons}
                        onChange={id => setFilterCategory(id)}
                        layout={layout}
                    />
                ) : null}

                <div className='feed_list'>
                    {output.slice(page, page+6).map((i, idx) => (
                        <Item
                            key={idx}
                            theme={theme}
                            data={i}
                            layout={layout}
                        />
                    ))}
                </div>

                {layout === 'mobile' ? <div className='feed-btn'>
                    <Button label="Загрузить ещё" className="p-button-outlined p-button-secondary p-button-sm" />
                </div> : (
                    <Paginator
                        first={page}
                        rows={6}
                        totalRecords={output.length}
                        onPageChange={(e) => setPage(e.first)}
                        template={output.length/6 > 5 ? templateDefault : template1}
                    />
                )}
            </div>
}

const Item = ({ data, theme, layout }) => {
    const dctx = useContext(DesktopContext)
    const history = useHistory()

    const attachment = data && data.attachments ? data.attachments[0] || null : null

    return  <div
                className='feed_list_item'
                onClick={() => layout === 'mobile' ? history.push(window.location.pathname+'?publication='+data._id) : dctx.setPublicationId(data._id)}
            >
                <div className='feed_list_item-prepend' style={{backgroundColor: `var(--${theme.buttons.highlight})`}}></div>
                <div
                    className='feed_list_item_media'
                >
                    <div
                        className='media-thumb'
                        style={{background: attachment ? `url(${attachment.type === 'video' ? getVideoSrc(attachment.video) : getPhotoSrc(attachment.photo)})` : '#f3f8ff'}}
                    >
                    </div>
                </div>
                <div className='feed_list_item_content'>
                    <div
                        className='feed_list_item_content_title alterfont'
                    >{data.title}</div>
                    <div
                        className='feed_list_item_content_date alterfont'
                        style={{
                            color: `var(--${theme.text}-500)`
                        }}
                    >{moment(data.date, 'YY-MM-DD').format('D MMMM')}</div>
                </div>
            </div>
}

export default Feed
