import React from 'react'

import { Skeleton } from 'primereact/skeleton'
import { Tooltip } from 'primereact/tooltip'

import './style.scss'

const Summary = ({ data, team }) => {
    const source = team || data

    const formatted = data ? [
        {label: 'Матчи', value: source.squads},
        {label: 'MVP', value: source.mvps || ''},
        {label: 'Points Rate', value: source.pointsRate || 49, tooltip: 'Points Rate - отношение турнирных очков, заработанных командой в матчах с игроком в заявке к очкам в матчах, когда игрок отсутствовал + базовый коэффициент по модели FIFA', tt_id: 'pr_tt'},
        {label: 'ЧРД', value: source.frequency ? Math.floor(source.frequency) : null, tooltip: 'Частота результативных действий - среднее количество игровых минут, которые игрок тратит на забитый мяч или голевую передачу', tt_id: 'fr_tt'},
        {label: 'Голы', value: source.goals},
        {label: 'Ассисты', value: source.assists},
        {label: 'ЖК + КК', value: !isNaN(source.ycs + source.rcs) ? source.ycs + source.rcs : ''}
    ] : null

    return  <div className='player-summary'>
                <div className='title alterfont'>Cтатистика игрока</div>

                {formatted ? (
                    <div className={`grid ${formatted.filter(r => r.value).length > 6 ? ' compact' : ''}`}>
                        {formatted.filter(r => r.value).map((r, i) => (
                            <div className='grid-item' key={i}>
                                <div className='label'>
                                    {r.label}
                                    {r.tooltip ? [
                                        <Tooltip
                                            content={r.tooltip}
                                            target={`#${r.tt_id}`}
                                            position='top'
                                            className='blue-tooltip'
                                            style={{
                                                maxWidth: 200,
                                                fontSize: '.85rem',
                                                lineHeight: 1.5
                                            }}
                                        />,
                                        <span className='tooltip-trigger' id={r.tt_id}>
                                            <i className='pi pi-question'></i>
                                        </span>
                                    ] : null}
                                </div>
                                <div className='value alterfont'>
                                    <span>{r.value}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Skeleton height={150} />
                )}
            </div>
}

export default Summary
