import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'

import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { Emblem } from '../../Atoms'

import initMessaging from './initMessaging'

import moment from 'moment'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

import ym from 'react-yandex-metrika'

const fallbackAvatar = e => {
    e.target.src = require('./player.png')
}

const renderAge = (form, profile) => {
    if(form && profile) {
        const formatYears = years => {
            const lastChar = years.toString().charAt(1)
            const form = lastChar === '1' ? 'год' : ['2', '3', '4'].includes(lastChar) ? 'года' : 'лет'
            return years+' '+form
        }

        const origin = form.birthday ? moment(form.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD') : null
        let years
        if(origin) {
            years = moment().diff(origin, 'years')
        } else {
            years = profile.age || null
        }

        return years ? formatYears(years) : 'возраст не указан'
    } else {
        return 'возраст не указан'
    }
}

const UserWidget = ({ data, hideAccount, nativeToken, setNativeToken, isIos }) => {
    const [notifications, setNotifications] = useState(data.fcmToken)
    const [form, setForm] = useState(data && data.profile ? {name: data.profile.name, birthday: data.bindedPlayer ? data.bindedPlayer.birthday || data.profile.birthday : data.profile.birthday || null} : {name: 'Безымянный Профиль'})
    const [updating, setUpdating] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [bindSuggestions, setBindSuggestions] = useState(false)
    const [bindedPlayer, setBindedPlayer] = useState(data.bindedPlayer && data.bindedPlayer._id ? data.bindedPlayer : null)
    const [binding, setBinding] = useState(false)
    const history = useHistory()

    useEffect(() => {
        console.log('Native token received', nativeToken)
        if(nativeToken && updating && !notifications) {
            console.log('Applying native token state')
            setUpdating(false)
        }
    }, [nativeToken])

    const patchProfile = () => {
        if(data.firebaseTkn) {
            axios.put(`${ENDPOINT}user/${data.firebaseTkn}`, {clientId: data.clientId, profile: {...data.profile, ...form}})
                .then(resp => {
                    console.log('Patched')
                    fetchSuggestions()
                })
        }
    }

    const bindSuggestion = bs => {
        setBinding(true)
        axios.put(`${ENDPOINT}user/${data.firebaseTkn}`, {clientId: data.clientId, bindedPlayerId: bs._id})
            .then(resp => {
                setBinding(false)
                setBindSuggestions(null)
                setBindedPlayer({...bs})
                setForm({...form, birthday: bs.birthday})
            })
    }

    const fetchSuggestions = () => {
        setBindSuggestions(null)
        if(form.name && form.name.length && form.name !== 'Безымянный Профиль' && !bindedPlayer) {
            axios.get(`${ENDPOINT}client/bindsuggestions/${data.firebaseTkn}`)
                .then(resp => {
                    if(resp.data && !resp.data.error) {
                        setBindSuggestions(resp.data)
                    }
                })
        }
    }

    useEffect(() => {
        if(!editMode) {
            fetchSuggestions()
        }
    }, [form.name])

    const switchNotifications = async () => {
        if(notifications || nativeToken) {
            axios.put(`${ENDPOINT}user/${data.firebaseTkn}`, {clientId: data.clientId, fcmToken: null})
                .then(resp => {
                    setNotifications(false)
                    setNativeToken(null)
                })
        } else {
            const { messaging, VAPID, getMessagingToken } = await initMessaging()
            if(messaging) {
                setUpdating(true)
                getMessagingToken(messaging, {vapidKey: VAPID})
                    .then(currentToken => {
                        if(currentToken) {
                            console.log('Got token', currentToken)
                            ym('reachGoal', 'PUSH_SUBSCRIBED')
                            axios.put(`${ENDPOINT}user/${data.firebaseTkn}`, {clientId: data.clientId, fcmToken: currentToken})
                                .then(resp => {
                                    setNotifications(currentToken)
                                    setUpdating(false)
                                })
                        } else {
                            console.log('No token, show UI')
                        }
                    })
                    .catch(err => {
                        setUpdating(false)
                        console.log(err)
                    })
            } else {
                console.log('Fallback to native FCM flow')
                if(window && window.top && data.creds) {
                    setUpdating(true)
                    window.top.postMessage({
                        type: 'wrapCredentials',
                        credentials: data.creds,
                        firebaseId: data.firebaseId
                    }, '*')
                }
            }
        }
    }

    return  <div className='user-widget'>
                <div className='card'>
                    <span className='edit-trigger' onClick={() => {
                        setEditMode(!editMode)
                        if(editMode) {
                            patchProfile()
                        }
                    }}>
                        <i className={`pi pi-${editMode ? 'save' : 'pencil'}`}></i>
                    </span>
                    <div className='profile'>
                        <div className='avatar'>
                            <img src={bindedPlayer ? (bindedPlayer.avatarUrl || '') : (data && data.profile ? data.profile.avatarUrl || '' : '')} onError={e => fallbackAvatar(e)} />
                        </div>

                        <div className='profile-info'>
                            {editMode ? (
                                <InputText value={form.name} onChange={e => setForm({...form, name: e.target.value})} />
                            ) : (
                                <div className='name alterfont'>{form.name}</div>
                            )}
                            {editMode ? (
                                <InputMask
                                    mask={'99.99.9999'}
                                    value={form.birthday}
                                    placeholder='ДД.ММ.ГГГГ'
                                    onChange={e => setForm({...form, birthday: e.target.value})}
                                />
                            ) : (
                                <div className='age'>{renderAge(form, data.profile)}</div>
                            )}
                        </div>
                    </div>

                    {!editMode && bindedPlayer ? (
                        <div className='binded-stats-link' onClick={() => {
                            history.push(`/player/${bindedPlayer._id}`)
                            hideAccount()
                        }}>
                            <span className='pi pi-star'></span>
                            <span>Моя статистика</span>
                        </div>
                    ) : null}
                </div>

                {bindSuggestions && bindSuggestions.length ? (
                    <div className='suggestions'>
                        <div className='notice'>Мы нашли профили игроков, очень похожие на Вас. Хотите привязать?</div>
                        {bindSuggestions.map((bs, i) => (
                            <div className='card' key={i}>
                                <div className='profile'>
                                    <div className='avatar'>
                                        <img src={bs.avatarUrl || ''} onError={e => fallbackAvatar(e)} />
                                    </div>

                                    <div className='profile-info'>
                                        <div className='name alterfont'>{bs.surname} {bs.name} {bs.middlename}</div>
                                        <div className='age'>{bs.birthday || 'дата рождения не указана'}</div>
                                    </div>
                                </div>

                                <Button className='bind-action p-button-sm' icon='pi pi-check' label='Это я!' loading={binding} onClick={() => bindSuggestion(bs)} />
                            </div>
                        ))}
                    </div>
                ) : null}

                <div
                    className={'switch-control'+((notifications || nativeToken) ? ' active' : '')}
                    onClick={() => !updating ? switchNotifications() : null}
                >
                    {updating ? <span style={{width: 38.5, height: 24.75, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><span style={{color: '#fff'}} className='pi pi-spin pi-spinner'></span></span> : <InputSwitch checked={notifications || nativeToken} />}
                    <label>Уведомления {!notifications && !nativeToken ? 'не ' : ''} подключены</label>
                </div>

                {data.favorites && data.favorites.length ? (
                    <div className='favorites'>
                        <div className='top'>
                            <Tag icon='pi pi-star-fill' value='Избранное' />
                        </div>

                        <div className='items'>
                            {data.favorites.map((f, i) => (
                                <div className='item' onClick={() => {
                                    history.push(f.url)
                                    hideAccount()
                                }}>
                                    <Emblem size='sm' source={f.img} backdroped={true} player={f.type === 'player'}/>
                                    <div className='name alterfont'>{f.info}</div>

                                    <Tag icon='pi pi-times' value='удалить' />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
}

export default UserWidget
