import React, { useState, useEffect } from 'react'

import { Dialog } from 'primereact/dialog'
import ReactPlayer from 'react-player/lazy'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const VideoBox = ({ data, onClose }) => {
    const [source, setSource] = useState(null)
    const [playing, setPlaying] = useState(true)

    useEffect(() => {
        if(data) {
            axios.get(`${ENDPOINT}client/extract_video/${data._id}_0`)
                .then(resp => {
                    setSource(resp.data)
                })
        }
    }, [data])

    const yid = source && source.player && source.player.split('/embed/')[1] ? source.player.split('/embed/')[1].split('?')[0] : ''

    return  <Dialog
                visible={(data !== null)}
                onHide={() => {
                    setPlaying(false)
                    onClose()
                }}
                style={{width: '75vw'}}
                blockScroll={true}
                className='video-box'
                dismissableMask
            >
                {source ? (
                    <div className={'video-wrap'}>
                        <div className={'video'}>
                            {yid && yid.length ? (
                                <ReactPlayer
                                    url={`https://youtube.com/watch?v=${yid}`}
                                    width='100%'
                                    height='100%'
                                    playing={playing}
                                    loop={true}
                                    controls={true}
                                    onPause={() => setPlaying(false)}
                                    options={{
                                        youtube: {
                                            palyerVars: {
                                                modestbranding: 1,
                                                fs: 1,
                                                rel: 0,
                                                showinfo: 0
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                <iframe
                                    src={source.player}
                                    width="100% "
                                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                    frameborder="0"
                                    allowfullscreen
                                    style={{aspectRatio: '16/9'}}
                                ></iframe>
                            )}
                        </div>
                    </div>
                ) : null}
            </Dialog>
}

export default VideoBox
