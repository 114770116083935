import React, { useState, useEffect } from 'react'

import ReactPlayer from 'react-player/lazy'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const Video = ({ pubId, idx, isDesktop }) => {
    const [source, setSource] = useState(null)
    const [isLandscape, setIsLandscape] = useState(Math.abs(window.orientation) === 90)
    const [muted, setMuted] = useState(isDesktop ? false : true)
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        const onReoriented = () => {
            setIsLandscape(Math.abs(window.orientation) === 90)
        }

        window.addEventListener("orientationchange", onReoriented)
        return () => {
            window.removeEventListener("orientationchange", onReoriented)
        }
    }, [isLandscape])

    useEffect(() => {
        if(pubId) {
            axios.get(`${ENDPOINT}client/extract_video/${pubId}_${idx}`)
                .then(resp => {
                    setSource(resp.data)
                })
        }
    }, [pubId, idx])

    const yid = source && source.player && source.player.split('/embed/')[1] ? source.player.split('/embed/')[1].split('?')[0] : ''

    return  source ? [
                <div className={'video-wrap'+(isLandscape ? ' fs' : '')}>
                    <div className={'video'}>
                        {yid && yid.length ? (
                            <ReactPlayer
                                url={`https://youtube.com/watch?v=${yid}`}
                                width='100%'
                                height='100%'
                                muted={muted}
                                playing={isDesktop ? playing : true}
                                loop={true}
                                controls={isDesktop}
                                options={{
                                    youtube: {
                                        palyerVars: {
                                            modestbranding: 1,
                                            fs: isDesktop ? 1 : 0,
                                            rel: 0,
                                            showinfo: 0
                                        }
                                    }
                                }}
                            />
                        ) : (
                            <iframe
                                src={source.player}
                                width="100% "
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                                frameborder="0"
                                allowfullscreen
                                style={{aspectRatio: '16/9'}}
                            ></iframe>
                        )}
                    </div>

                    {!isDesktop ? <div className={'mute_btn'+(muted ? ' off' : ' on')}>
                        <Button
                            icon={`pi pi-volume-${muted ? 'down' : 'up'}`}
                            outlined
                            className='p-button-rounded p-button-sm'
                            onClick={() => setMuted(!muted)}
                        />
                    </div> : null}
                </div>,
                !isDesktop ? <div className='notice'>
                    <Tag>поверните устройсто для полноэкранного просмотра</Tag>
                </div> : null
            ] : null
}

export default Video
