import React, { useState, useEffect } from 'react'

import { motion, AnimatePresence } from 'framer-motion'
import { Loader } from '../../Atoms'

import Card from './Card'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

import './style.scss'

const Feed = ({ team }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        if(team) {
            setData(null)
            axios.get(`${ENDPOINT}client/teamfeed/${team}`)
                .then(resp => {
                    setTimeout(() => {
                        setData(resp.data)
                    }, 1500)
                })
        }
    }, [team])

    return  <div className='club-feed'>
                <AnimatePresence
                    mode='popLayout'
                >
                    {!data ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{delay: 0.3}}
                        >
                            <Loader width={240} />
                        </motion.div>
                    ) : null}
                </AnimatePresence>

                <AnimatePresence
                    mode='popLayout'
                >
                    {data ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{delay: 0.3}}
                        >{data.map((card, i) => (
                            <Card key={i} data={card} />
                        ))}
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
}

export default Feed
