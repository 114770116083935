import React from 'react'

import './style.scss'

const BannerNode = ({ media, action }) => {
    return  <div
                className='banner-node'
                onClick={() => action()}
            >
                <div className='media'>
                    <img src={media} />
                </div>
            </div>
}

export default BannerNode
