import React from 'react'

import './style.scss'

const Icon = ({ size, icon, color }) => {
    return  <div
                className='am-icon'
                style={{
                    width: size,
                    height: size,
                    WebkitMaskImage: `url(${require('./lineal/'+icon+'.png')})`,
                    backgroundColor: `var(--${color})`
                }}
            ></div>
}

export default Icon
