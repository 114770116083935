import React, { useState, useEffect, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../env'

import { Skeleton } from 'primereact/skeleton'
import { VideoBox } from '../Atoms'

import Gallery from './Gallery'

const extractActiveMedia = (arr, type) => {
    switch (type) {
        case 'album':
            const photo = arr.find(att => att.type === 'photo')
            const url = photo.photo.sizes.sort((a, b) => b.width - a.width)[0].url
            return url
            break
        case 'video':
            const video = arr.find(att => att.type === 'video')
            const preview = video ? video.video.image.sort((a, b) => b.width - a.width)[0].url : null
            return preview
            break
        default:
            return null
    }
}

const PreviewItem = ({ data, theme, contentType, setBoxItem }) => {
    const itemRef = useRef(null)

    return  <div className='preview-item'>
                <div
                    className='preview-item_media'
                    ref={itemRef}
                    style={{
                        height: itemRef.current ? itemRef.current.outerWidth/1.78 : 240,
                        backgroundImage: `url(${extractActiveMedia(data.attachments, contentType)})`,
                        boxShadow: `2px 2px 25px ${theme.previewShadowColor}`
                    }}
                    onClick={() => contentType === 'video' ? setBoxItem(data) : null}
                >
                    {contentType === 'video' ? <div className='play_btn'></div> : null}
                </div>
                <div
                    className='preview-item_title'
                    style={{
                        color: `var(--highlights-item-color)`
                    }}
                >{data.title}</div>
            </div>
}

const Highlights = ({ layout, width, theme, scope, subjectId, contentType }) => {
    const [active, setActive] = useState(null)
    const [items, setItems] = useState(null)
    const [boxIndex, setBoxIndex] = useState(-1)
    const [boxItem, setBoxItem] = useState(null)

    const splideRef = useRef(null)

    useEffect(() => {
        axios.get(`${ENDPOINT}client/highlights/${subjectId}?scope=${scope}&contentType=${contentType}`)
            .then(resp => {
                if(resp.data) {
                    const items = contentType === 'video' ? resp.data.filter(i => i.category === 'highlights').slice(0, 6) : resp.data
                    setItems(items)
                    if(items && items.length) {
                        setActive(items[0])
                    }
                }
            })
    }, [])

    return  <div
                className={`highlights ${layout}`}
                style={{
                    width: width,
                    backgroundColor: theme.surface.includes('#') ? theme.surface : `var(--${theme.surface})`,
                    color: `var(--highlights-item-color)`
                }}
            >
                <div
                    className='highlights_title'
                    style={{
                        color: `var(--highlights-title-color)`
                    }}
                >{contentType === 'album' ? 'Фотоальбомы' : 'Хайлайты'}</div>

                {(active !== null) && items ? <div
                    className='highlights_container'
                >
                    <div className='highlights_container_media' onClick={() => setBoxIndex(0)}>
                        <img src={extractActiveMedia(active.attachments, contentType)} />
                        <div
                            className='media-linear'
                            style={{
                                background: `linear-gradient(180deg, transparent 31.6%, ${theme.card.includes('#') ? theme.card : 'var(--'+theme.card+')'} 100%)`
                            }}
                        ></div>
                        {contentType !== 'album' ? (
                            <div className='media-play'>
                                <div className='play_btn'></div>
                            </div>
                        ) : null}
                    </div>
                    <div
                        className='highlights_container_bottom'
                        style={{
                            backgroundColor: theme.card.includes('#') ? theme.card : `var(--${theme.card})`
                        }}
                    >
                        <div className='highlights_container_title'>{active.title}</div>
                    </div>
                </div> : layout === 'mobile' ? items && !items.length ? (
                    <div className='empty-highlights'>
                        <span className='pi pi-images'></span>
                        <div>не загружены</div>
                    </div>
                ) : (
                    <div className='mobile_skeleton'>
                        <Skeleton height='240px' />
                    </div>
                ) : (
                    <div className='container'>
                        <Skeleton width='30%' height='240px' />
                        <Skeleton width='30%' height='240px' />
                        <Skeleton width='30%' height='240px' />
                    </div>
                )}

                <Gallery boxIndex={boxIndex} setBoxIndex={setBoxIndex} attachments={active ? active.attachments : []} />

                {layout === 'mobile' ? <div
                    className='highlights_carousel'
                    style={{
                        backgroundColor: theme.card.includes('#') ? theme.card : `var(--${theme.card})`
                    }}
                >
                    <Splide
                        ref={splideRef}
                        options={{
                            fixedWidth: 90,
                            type: items && items.length > 5 ? 'loop' : null,
                            arrows: false,
                            gap: 8,
                            interval: 4500,
                            perMove: 1,
                            updateOnMove: true,
                            pagination: true,
                            clones: 1
                        }}
                    >
                        {items ? items.slice(0,20).map((item, i) => (
                            <SplideSlide key={i}>
                                <div
                                    className={`highlights_carousel_item${active && active._id === item._id ? ' active' : ''}`}
                                    style={{
                                        borderColor: active && active._id === item._id ? `var(--mobile-highlights-preview-border)` : 'none'
                                    }}
                                    onClick={() => setActive({...item})}
                                >
                                    <img src={extractActiveMedia(item.attachments, contentType)} />
                                </div>
                            </SplideSlide>
                        )) : null}
                    </Splide>
                </div> : [
                    <div className='previews'>
                        {items ? items.map((item, i) => (
                            <PreviewItem
                                key={i}
                                theme={theme}
                                data={item}
                                contentType={contentType}
                                setBoxItem={setBoxItem}
                            />
                        )) : null}
                    </div>,
                    <VideoBox
                        data={boxItem}
                        key={boxItem ? boxItem._id : null}
                        onClose={() => setBoxItem(null)}
                    />
                ]}
            </div>

}

export default Highlights
