import React from 'react'
import Lottie from 'react-lottie'

import * as animationData from './soccer-empty-state.json'

import './style.scss'

const Loader = ({ width }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    }

    return  <div className='loader'>
                <Lottie
                    options={defaultOptions}
                    width={width}
                />
            </div>
}

export default Loader
