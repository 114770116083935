export default {
    appTitle: 'ДФЛ | KIDS.LFL39.RU',
    federation: {
        _id: '63283eba7d3ec0dbcb77bd13'
    },
    bannerNode: {
        media: `${process.env.FILES_BUCKET}banners/dfl_pulse.jpg`,
        publicationId: null,
        externalLink: 'https://pulse.insure/sport/?campaign=&media_source=0043&utm_source=web&utm_medium=adbanner&utm_campaign=kroo_plt_1_sport'
    },
    brandEmblem: `${process.env.FILES_BUCKET}leagues/kids_260.png`,
    topBanners: [
        `${process.env.FILES_BUCKET}apps_promo/champoin_store.png`,
        `${process.env.FILES_BUCKET}apps_promo/fc_baltika.png`,
    ],
    footerCreds: {
        copyright: '© КРОО Детская Футбольная Лига , 2024',
        phone: '+7 (921) 263-56-77',
        address: '236000, г. Калининград, ул. Дм.Донского, д. 5а'
    },
    footerStores: null,
    footerBanners: [
        `${process.env.FILES_BUCKET}apps_promo/dfl39_promo_3.png`,
        `${process.env.FILES_BUCKET}apps_promo/dfl39_promo_4.png`,
        `${process.env.FILES_BUCKET}apps_promo/dfl39_promo_5.png`
    ],
    footerSocials: {
        vk: 'https://vk.com/kdfl39',
        youtube: 'https://www.youtube.com/@user-zp9sw3fp9i'
    },
    fantasyBrand: null,
    metrikaCounter: 90856968,
    firebase: {
        config: {
            apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
            authDomain: "amateum-bb5a1.firebaseapp.com",
            projectId: "amateum-bb5a1",
            storageBucket: "amateum-bb5a1.appspot.com",
            messagingSenderId: "456338694560",
            appId: "1:456338694560:web:c777bcd130c236984784c5",
            measurementId: "G-01NNRBZMP7"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
