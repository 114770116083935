import React from 'react'
import moment from 'moment'
import { Tag } from 'primereact/tag'

import Emblem from '../Emblem'

import './style.scss'
import './v2.scss'

const renderCardHighlightClass = (match, subject) => {
    if(!match.score || !match.score.full) {
        return 'upcoming'
    } else {
        const isHome = subject === match.home._id
        const hs = parseInt(match.score.full.home)
        const as = parseInt(match.score.full.away)

        return hs === as ? 'draw' : isHome ? hs > as ? 'won' : 'lost' : hs > as ? 'lost' : 'won'
    }
}

const MatchCard = ({ layout, theme, data, extendedMode, v2, scope, subject }) => {
    return  <div className={`match-card ${layout}${v2 ? ' v2' : ''}`}>
                <div
                    className={'match-card_body'+(scope === 'team' ? ' '+renderCardHighlightClass(data, subject) : '')}
                    style={{
                        background: `var(--${theme.surface})`
                    }}
                >
                    <div className={'match-card_body_emblem __home'+(!data.score ? ' noscore' : '')}>
                        <Tag>{data.home.name}</Tag>
                        <Emblem size='md' source={data.home.emblem} backdroped={true} />
                    </div>

                    <div
                        className='match-card_body_score alterfont'
                        style={{color: `var(--${theme.contentColor}-700)`}}
                    >
                        <span>{data.score ? data.score.full.home : ''}</span>
                        <i className='pi pi-times'></i>
                        <span>{data.score ? data.score.full.away : ''}</span>
                    </div>

                    <div
                        className={'match-card_body_datetime'+(!data.score ? ' noscore' : '')}
                        style={{color: `var(--${theme.contentColor}-600)`}}
                    >
                        <div>{data.matchday}</div>
                        {!extendedMode ? <div>{data.time}, {moment(data.date, 'YY-MM-DD').format('D MMMM')}</div> : null}
                    </div>

                    <div className={'match-card_body_emblem __away'+(!data.score ? ' noscore' : '')}>
                        <Emblem size='md' source={data.away.emblem} backdroped={true} />
                        <Tag>{data.away.name}</Tag>
                    </div>

                    {data.live ? <Tag className='match-card_body_tag' severity='danger' value='LIVE'></Tag> : null}
                </div>
            </div>
}

export default MatchCard
