export default {
    appTitle: 'OLE | PARI',
    federation: {
        _id: '624c17e25887f52dbfc6819c'
    },
    bannerNode: {
        media: `${process.env.FILES_BUCKET}storage/become_partner.jpg`,
        publicationId: null,
        externalLink: 'http://olesportspartnership.tilda.ws/'
    },
    brandEmblem: `${process.env.FILES_BUCKET}leagues/ole_288.png`,
    topBanners: [
        `${process.env.FILES_BUCKET}apps_promo/dodo.png`
    ],
    footerCreds: {
        copyright: '© ОЛЕ Промо Груп, 2008 - 2024',
        phone: '+7 (921) 409-04-25',
        address: '194100, г. Санкт-Петербург, ул. Новолитовская, д. 15, корп. Б, офис 408'
    },
    footerStores_: {
        releaseDesc: 'c 20 июля в:',
        googleplay: 'https://olesports.ru/android/app-release.apk',
        appstore: ''
    },
    footerBanners: [],
    footerSocials: {
        vk: 'https://vk.com/piterfootball',
        telegram: 'https://t.me/OLEPARI',
        youtube: 'https://www.youtube.com/@user-tq7ip4nn1e'
    },
    fantasyActive: true,
    fantasyBrand: {
        type: 'image',
        source: `${process.env.FILES_BUCKET}assets/brand.png`
    },
    metrikaCounter: 91751862,
    firebase: {
        config: {
            apiKey: 'AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0',
            authDomain: 'amateum-bb5a1.firebaseapp.com',
            projectId: 'amateum-bb5a1',
            storageBucket: 'amateum-bb5a1.appspot.com',
            messagingSenderId: '456338694560',
            appId: '1:456338694560:web:f0c6aa80f4440eaf4784c5',
            measurementId: 'G-MPYG5TJ9ET'
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
