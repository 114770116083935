export default {
    appTitle: 'FFGT | Федерация Футбола города Твери',
    federation: {
        _id: '6282545d647054666647c126'
    },
    bannerNode: null,
    brandEmblem: `${process.env.FILES_BUCKET}apps/ffgt_site_emb.png`,
    topBanners: [
        `${process.env.FILES_BUCKET}apps/ffgt_colored_1.png`
    ],
    topLinks: [
        'https://www.vtb.ru'
    ],
    footerCreds: {
        copyright: '© ОО ФФ Города Твери, 2024',
        phone: '+7 (991) 113-15-85',
        address: ''
    },
    footerStores: null,
    footerBanners: [
        `${process.env.FILES_BUCKET}apps_promo/ffgt_3.png`,
        `${process.env.FILES_BUCKET}apps_promo/ffgt_1.png`,
        `${process.env.FILES_BUCKET}apps_promo/ffgt_2.png`
    ],
    footerLinks: [
        'https://www.vtb.ru',
        'https://vk.com/rezultattver',
        'https://mastersmiletver.ru'
    ],
    footerSocials: {
        vk: 'https://vk.com/footbol69',
        telegram: 'https://t.me/lfsarena',
        //youtube: 'https://www.youtube.com/@user-zp9sw3fp9i'
    },
    fantasyBrand: {
        type: 'label',
        text: 'Amateum',
        link: null
    },
    metrikaCounter: 89473122,
    firebase: {
        config: {
          apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
          authDomain: "amateum-bb5a1.firebaseapp.com",
          projectId: "amateum-bb5a1",
          storageBucket: "amateum-bb5a1.appspot.com",
          messagingSenderId: "456338694560",
          appId: "1:456338694560:web:cf9ca1f72089d2c34784c5",
          measurementId: "G-RVD8PJ4SMH"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
