import React, { useRef } from  'react'

import { Splide, SplideSlide } from '@splidejs/react-splide'

const Item = ({ category, isActive, setActive, theme }) => {
    return  <div
                className={'stories-nav-item'+(isActive ? ' active' : '')}
                onClick={() => setActive(category.id)}
            >
                <div style={{background: `var(--${isActive ? theme.storiesAccent : theme.storiesBackground})`}}>
                    <div style={{backgroundImage: `url(${category.data.length ? category.data[0].landscapeCover || null : null})`}}></div>
                </div>
                <span style={{background: `var(--${isActive ? theme.storiesAccent : theme.storiesBackground})`, color: `var(--${!isActive ? theme.storiesAccent : theme.storiesBackground})`}}>{category.label}</span>
            </div>
}

const StoriesNav = ({ data, active, setActive, theme }) => {
    const splideStoriesNavRef = useRef(null)

    return  <Splide
                ref={splideStoriesNavRef}
                options={{
                    pagination: false,
                    autoWidth: true,
                    arrows: false,
                    gap: 0,
                    interval: 4500,
                    perMove: 1,
                    updateOnMove: false
                }}
                className='stories-nav'
            >
                {data.map(cat => (
                    <SplideSlide key={cat.id}>
                        <Item
                            isActive={cat.id === active}
                            category={cat}
                            setActive={setActive}
                            theme={theme}
                        />
                    </SplideSlide>
                ))}
            </Splide>
}

export default StoriesNav
