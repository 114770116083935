import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Timeline } from 'primereact/timeline'

import { EvtIcon, NonIdealState } from '../Atoms'

const aliases = {
    yellowcard: 'yc',
    redcard: 'rc',
    secondyellow: 'rc'
}

const formatProxyTimeline = obj => {
    return Object.keys(obj).reduce((acc, side) => {
        acc = acc.concat(obj[side].map(e => ({...e, side: side, type: aliases[e.type] || e.type})))
        return acc
    }, [])
}

const formatTimeline = obj => {
    return obj.data ? Object.entries(obj.data).reduce((acc, e) => {
        for(let key in e[1]) {
            for(let evt of e[1][key]) {
                acc.push({
                    ...evt,
                    type: key,
                    side: e[0]
                })
            }
        }

        return acc
    }, []).sort((a, b) => parseInt(a.minute) - parseInt(b.minute)) : []
}

const Evt = ({ data }) => {
    const history = useHistory()

    const linkHandle = (event) => {
        event.preventDefault();
        history.push(event.currentTarget.getAttribute('href'))
    }

    return  <div className='events-item_body'>
                <a href={data.headquarter || !data.player?._id ? null : `/player/${data.player._id}`} className='events-item_body_primary' onClick={linkHandle}><span>{data.headquarter ? data.headquarter.name+' '+data.headquarter.surname : (data.player?.name || (data.player?.surname ? '' : 'игрок не указан'))+' '+(data.player?.surname || '')}</span></a>
                {data.assistant ? <a href={`/player/${data.assistant._id}`} className='events-item_body_secondary' onClick={linkHandle}>{data.assistant.name+' '+data.assistant.surname}</a> : data.owngoal || data.penalty || data.missedPenalty ? <span className='events-item_body_secondary'>{data.owngoal ? 'автогол' : data.penalty ? 'с пенальти' : data.missedPenalty ? 'незабитый пенальти' : null}</span> : null}
            </div>
}

const Events = ({ data, proxyData }) => {
    const source = data.events.find(e => e.type === 'events')
    const proxyEvents = proxyData && proxyData.time && !proxyData.finished ? proxyData.events : null
    const events = proxyEvents ? formatProxyTimeline(proxyEvents) : source ? formatTimeline(source) : []

    return  <div className='match-events_grid'>
                {events && events.length ? (
                    <Timeline
                        value={events}
                        content={item => item.side === 'home' ? <div className='event-minute alterfont'><span><b>{item.minute}'</b></span></div> : <Evt data={item} />}
                        opposite={item => item.side === 'home' ? <Evt data={item} /> : <div className='event-minute alterfont'><span><b>{item.minute}'</b></span></div>}
                        marker={item => (
                            <EvtIcon
                                size={18}
                                theme={{color: 'blue', depth: 500}}
                                type={item.missedPenalty ? 'missedPenalty' : item.owngoal ? 'owngoal' : item.penalty || item.subtype === 'penalty' ? 'penalty' : item.type}
                            />
                        )}
                    />
                ) : (
                    <div className='empty' style={{height: 300, display: 'flex', alignItems: 'center'}}>
                        <NonIdealState
                            icon='time'
                            text='нет информации'
                        />
                    </div>
                )}
            </div>
}

export default Events
