import React from 'react'
import { useHistory } from 'react-router-dom'

import { Emblem } from '../../Atoms'
import moment from 'moment'

const Card = ({ data }) => {
    const history = useHistory()

    return  <div className='mobile-match_card' onClick={() => history.push(`/match/${data._id}`)}>
                <div className='teams'>
                    <div className='emblems'>
                        <Emblem source={data.home.emblem || ''} size='xxs' />
                        <span className='pi pi-times'></span>
                        <Emblem source={data.away.emblem || ''} size='xxs' />
                    </div>
                    <div className='names'>
                        <div className='alterfont'>{data.home.name} <i>vs</i></div>
                        <div className='alterfont'>{data.away.name}</div>
                    </div>
                </div>

                {data.score ? (
                    <div className='score alterfont'>{data.score.full.home}<i>:</i>{data.score.full.away}</div>
                ) : null}

                <div className='meta'>
                    <div className='tournament-name'>{data.location ? data.location.name : ''}</div>
                    <div className='time'>{data.time}</div>
                    <div className='date'>{moment(data.date, 'YY-MM-DD').format('DD MMMM')}</div>
                    <div className='tournament-name'>{data.tournament.name}</div>
                </div>
            </div>
}

export default Card
