import React from 'react'

import { Skeleton } from 'primereact/skeleton'

import './style.scss'

const AvatarShield = ({ height, width, source, action }) => {
    return  <div
                className={'avatar-shield'+(action ? ' clickable' : '')}
                style={{
                    width: width,
                    height: height
                }}
                onClick={() => action ? action() : null}
            >
                <div className='avatar'>
                    <img src={source || ''} onError={e => {
                        e.target.src = require('./holder.png')
                    }} />
                </div>
            </div>
}

export default AvatarShield
