export default {
    appTitle: 'Самарская Футбольная Лига | FOOTBALL63.COM',
    federation: {
        _id: '623b14343aac1773a551604e'
    },
    bannerNode: null,
    brandEmblem: `${process.env.FILES_BUCKET}apps/football63_brand.png`,
    topBanners: [],
    footerCreds: {
        copyright: '© ООО «Самарская Футбольная Лига», 2024',
        phone: '+7 (927) 740 80 91',
        address: ''
    },
    footerStores: null,
    footerBanners: [
    ],
    footerSocials: {
        vk: 'https://vk.com/samliga',
        telegram: 'https://t.me/samliga',
    },
    fantasyBrand: {
        type: 'label',
        text: 'Amateum',
        link: null
    },
    metrikaCounter: 89111529,
    firebase: {
        config: {
          apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
          authDomain: "amateum-bb5a1.firebaseapp.com",
          projectId: "amateum-bb5a1",
          storageBucket: "amateum-bb5a1.appspot.com",
          messagingSenderId: "456338694560",
          appId: "1:456338694560:web:7e5150108ed2d70d4784c5",
          measurementId: "G-XNHBYX1CTE"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
