export default {
    appTitle: 'Футбол в школе',
    federation: {
        _id: '6502c29819668251ba10e4e1'
    },
    bannerNode: null,
    brandEmblem: `${process.env.FILES_BUCKET}apps/sfl_top.png`,
    topBanners: [],
    footerCreds: {
        copyright: '© Российский Футбольный Союз, 2024',
        // phone: '+7 (921) 839 97 34',
        // address: '162602, Вологодская обл., г. Череповец, ул. Максима Горького, д. 34, кв. 316'
    },
    footerStores: null,
    footerBanners: [],
    footerSocials: {
        vk: 'https://vk.com/footballatschools',
        //telegram: 'https://t.me/samliga',
        //youtube: 'https://www.youtube.com/@user-zp9sw3fp9i'
    },
    fantasyBrand: null,
    metrikaCounter: 94944688,
    firebase: {
        config: {
          apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
          authDomain: "amateum-bb5a1.firebaseapp.com",
          projectId: "amateum-bb5a1",
          storageBucket: "amateum-bb5a1.appspot.com",
          messagingSenderId: "456338694560",
          appId: "1:456338694560:web:a5fe2d1fd635960e4784c5",
          measurementId: "G-EFST7EZ4BM"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
