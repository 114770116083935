import React, { useState, useRef, useEffect } from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Loader } from '../../Atoms'

import './style.scss'

import VideoItem from '../VideoItem'
import { injectLinks } from '../utils'

const getPhotoSrc = arr => {
    return arr.sort((a, b) => b.width - a.width)[0].url
}

const SlideContent = ({ data, pubId, idx, active }) => {
    let Specified, append
    let obj = {}

    if(active) {
        switch (true) {
            case (typeof(data.video) !== 'undefined'):
                Specified = VideoItem
                obj = {pubId: pubId, idx: idx}
                append = data.splitted ? <div className='splitted-append'>{data.splitted.title}</div> : null
                break
            case (typeof(data.photo) !== 'undefined'):
                append = <img className='image-append' src={getPhotoSrc(data.photo.sizes)} />
                break
        }

        return Specified ? [<Specified {...obj} />, append] : append || null
    } else {
        return <div style={{height: 260}}></div>
    }
}

const MixedItem = ({ slides, content, pubId, imported }) => {
    const [slideIdx, setSlideIdx] = useState(0)

    const splideRef = useRef(null)

    return  <div className='mixed-item'>
                {slides && slides.length ? (
                    <Splide
                        ref={splideRef}
                        onMove={(spl, newIndex) => setSlideIdx(newIndex)}
                        options={{
                            pagination: true,
                            focus: 'center',
                            //type: 'loop',
                            arrows: false,
                            interval: 4500,
                            perMove: 1,
                            updateOnMove: true,
                            waitForTransition: true,
                            clones: 1
                        }}
                    >
                        {slides.map((slide, i) => (
                            <SplideSlide key={i}>
                                <SlideContent
                                    active={i === slideIdx}
                                    idx={i}
                                    pubId={pubId}
                                    data={slide}
                                />
                            </SplideSlide>
                        ))}
                    </Splide>
                ) : null}

                <div className={`mixed-item_content alterfont${imported ? ' imported' : ''}`} dangerouslySetInnerHTML={{__html: injectLinks(content)}}></div>
            </div>
}

export default MixedItem
