import React, { useState, useRef, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { BtnSet, MatchCard, BtnPull } from '../Atoms'
import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../env'

import { Skeleton } from 'primereact/skeleton'

import moment from 'moment'

const renderDateTime = ({ date, time }) => {
    let ds
    let ts = ''

    switch (true) {
        case date === moment().format('YY-MM-DD'):
            ds = 'сегодня'
            ts = ', '+time
            break
        case date === moment().add(-1, 'days').format('YY-MM-DD'):
            ds = 'вчера'
            break
        case date === moment().add(1, 'days').format('YY-MM-DD'):
            ds = 'завтра'
            ts = ', '+time
            break
        default:
            ds = moment(date, 'YY-MM-DD').format('D MMMM')
            break
    }

    return ds+(time ? ts : '')
}

const MatchesCarousel = ({ layout, width, theme, extendedMode, scope, subject, v2 }) => {
    const [data, setData] = useState(null)
    const [tab, setTab] = useState('recent')
    const [tours, setTours] = useState([])
    const [filterTour, setFilterTour] = useState(null)

    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        loadCarousel()
    }, [id])

    useEffect(() => {
        if(subject) {
            loadCarousel()
        }
    }, [subject])

    const linkHandle = (event) => {
        event.preventDefault();
        history.push(event.currentTarget.getAttribute('href'))
    }

    const loadCarousel = () => {
        axios.get(`${ENDPOINT}client/matchescarousel/${subject || id || ''}${scope ? '?scope='+scope : ''}`)
            .then(resp => {
                if (resp.data) {
                    setData({
                        ...resp.data,
                        upcoming: resp.data.upcoming.slice(0, 10),
                        recent: !extendedMode ? resp.data.recent.reverse() : resp.data.recent.slice(0, 10)
                    })

                    const set = new Set(extendedMode ? [] : resp.data[tab].map(m => JSON.stringify(m.tournament)))
                    setTours(Array.from(set).map(t => JSON.parse(t)))
                }
            })
    }

    const splideRef = useRef(null)

    const output = !data ? [] : scope ? subject === '624c17e25887f52dbfc6819c' ? data.live : data.recent.concat(data.upcoming) : extendedMode ? data.live : data[tab].filter(m => filterTour ? m.tournament._id === filterTour : m)

    return  <div
                className={`matches-carousel ${layout} ${extendedMode ? 'extended' : ''}`}
                style={{width: width, background: `var(--${theme.surface})`}}
            >
                <div className='container' style={(data && !output.length) ? {display: 'none'} : {}}>
                    <div>
                        {!extendedMode && !scope ? <BtnSet
                            size={'sm'}
                            btns={[
                                {id: 'recent', label: 'Прошедшие', icon: 'check'},
                                {id: 'upcoming', label: 'Предстоящие', icon: 'calendar'}
                            ]}
                            active={tab}
                            theme='blue-500'
                            onChange={id => setTab(id)}
                        /> : null}
                    </div>
                    <div className='matches-carousel_cards'>
                        {output && output.length && v2 ? <Splide
                            ref={splideRef}
                            options={{
                                pagination: false,
                                fixedWidth: v2 ? 220 : 268,
                                focus: 'center',
                                type: 'loop',
                                arrows: false,
                                gap: v2 ? '40px' : '20px',
                                interval: 4500,
                                perMove: 1,
                                updateOnMove: false,
                                waitForTransition: true,
                                start: scope ? data.recent.length - 1 : 1,
                                clones: 1
                            }}
                        >
                            {output.map((m, i) => (
                                <SplideSlide
                                    key={i}
                                    style={{paddingTop: 5}}
                                >
                                    <MatchCard
                                        layout={layout}
                                        theme={theme.card}
                                        data={m}
                                        extendedMode={extendedMode}
                                        v2={v2 || false}
                                        scope={scope}
                                        subject={subject}
                                    />
                                </SplideSlide>
                            ))}
                        </Splide> : (
                            !extendedMode ? <div className='skeleton'>
                                <Skeleton width={220} height={120} />
                                <Skeleton width={220} height={120} />
                                <Skeleton width={220} height={120} />
                                <Skeleton width={220} height={120} />
                                <Skeleton width={220} height={120} />
                                <Skeleton width={220} height={120} />
                            </div> : null
                        )}
                    </div>
                    {!extendedMode && !scope && tours.length && tours.length > 1 ? (
                        <BtnPull
                            pull={[{_id: null, name: 'Все турниры'}].concat(tours)}
                            active={filterTour}
                            theme={theme.buttons}
                            onChange={id => setFilterTour(id)}
                            layout={layout}
                        />
                    ) : null}
                    {extendedMode && data && data.upcoming ? (
                        <div className='matches-carousel_list upcoming'>
                            {data.upcoming.filter(r => r.home && r.home.name).map((r, i) => (
                                <a href={`/match/${r._id}`} className='matches-carousel_list_item' key={i} onClick={linkHandle}>
                                    <div
                                        className='matches-carousel_list_item_datetime'
                                        style={{
                                            color: `var(--${theme.text})`
                                        }}
                                    >{renderDateTime(r)}</div>
                                    <div
                                        className='matches-carousel_list_item_info'
                                        style={{
                                            color: `var(--${theme.text})`
                                        }}
                                    >
                                        <div className='matches-carousel_list_item_teams'>{r.home.name}<span>VS</span>{r.away.name}
                                        {r.live ? <span className='smooth-tag'><i className='alterfont'>LIVE</i></span> : null}
                                        </div>
                                        <div
                                            className='matches-carousel_list_item_score'
                                            style={{
                                                color: `var(--${theme.text})`,
                                                opacity: '0.8'
                                            }}
                                        >{r.location ? r.location.name : '- : -'}</div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    ) : null}
                    {extendedMode && data && data.recent ? (
                        <div className='matches-carousel_list'>
                            {data.recent.reverse().map((r, i) => (
                                <a href={`/match/${r._id}`} className='matches-carousel_list_item' key={i} onClick={linkHandle}>
                                    <div
                                        className='matches-carousel_list_item_datetime'
                                        style={{
                                            color: `var(--${theme.text})`
                                        }}
                                    >{renderDateTime(r)}</div>
                                    <div
                                        className='matches-carousel_list_item_info'
                                        style={{
                                            color: `var(--${theme.text})`
                                        }}
                                    >
                                        <div className='matches-carousel_list_item_teams'>{r.home.name}<span>VS</span>{r.away.name}</div>
                                        <div
                                            className='matches-carousel_list_item_score __played'
                                            style={{
                                                color: `var(--${theme.text})`
                                            }}
                                        >{r.score.full.home}:{r.score.full.away}</div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
}

export default MatchesCarousel
