import React, { useState, useEffect, useRef } from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Skeleton } from 'primereact/skeleton'
import { motion, AnimatePresence } from 'framer-motion'

import Item from './Item'
import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const order = ['highlights', 'broadcast', 'interview', 'photos', 'news', 'previews']

const MediaBlockV2 = ({ scope, subjectId, stashed }) => {
    const [data, setData] = useState(null)
    const [catIdx, setCatIdx] = useState(0)

    useEffect(() => {
        if(!stashed) {
            axios.get(`${ENDPOINT}client/mediablock/${subjectId}?scope=${scope}`)
                .then(resp => {
                    setData(resp.data.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id)))
                })
        }
    }, [subjectId])

    const splideRef = useRef(null)

    const carouselSource = data && data[catIdx] ? data[catIdx].data : []

    return  <div className='mb2'>
                <div
                    className='nav-circle'
                    style={{left: (70*catIdx)+8}}
                ></div>

                <div
                    className='nav-spot'
                    style={{left: (70*catIdx)+25}}
                ></div>

                <div className='mb-body'>
                    <AnimatePresence mode='popLayout'>
                        {data && carouselSource ? (
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                            >
                                <Splide
                                    ref={splideRef}
                                    options={{
                                        pagination: true,
                                        focus: 'center',
                                        type: 'loop',
                                        arrows: false,
                                        interval: 4500,
                                        perMove: 1,
                                        updateOnMove: false,
                                        waitForTransition: true,
                                        clones: 1
                                    }}
                                >
                                    {carouselSource.map((item, i) => (
                                        <SplideSlide key={i}>
                                            <Item data={{...item, attachments: item.attachments.filter(i => i.type !== 'album')}} />
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </motion.div>
                        ) : null}
                    </AnimatePresence>

                    <AnimatePresence mode='popLayout'>
                        {!data || !carouselSource ? (
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                            >
                                <Skeleton className='slide-skeleton' />
                                <Skeleton className='date-skeleton' height={13} width={100} />
                                <Skeleton className='title-skeleton' height={18} />
                                <Skeleton className='title-skeleton-bottom' height={18} width={180} />
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                </div>

                <div className='nav'>
                    {data ? data.map((cat, i) => (
                        <div
                            className={'nav-cat'+(i === catIdx ? ' active' : '')}
                            onClick={() => setCatIdx(i)}
                        >
                            <div className='nav-cat_icon'>
                                <img src={cat.data[0].landscapeCover} />
                            </div>
                            <span className='alterfont'>{cat.label}</span>
                        </div>
                    )) : [
                        <Skeleton shape='circle' size={46} />,
                        <Skeleton shape='circle' size={46} />,
                        <Skeleton shape='circle' size={46} />,
                        <Skeleton shape='circle' size={46} />,
                        <Skeleton shape='circle' size={46} />
                    ]}
                </div>
            </div>
}

export default React.memo(MediaBlockV2)
