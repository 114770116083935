import React from 'react'
import { Tag } from 'primereact/tag'

import './style.scss'

const BtnPull = ({ pull, theme, layout, active, onChange, icon }) => {
    return  <div className={`btn-pull ${layout}`}>
                {pull.map((b, i) => (
                    <Tag
                        key={i}
                        value={b.name}
                        icon={icon || (b._id === active ? 'pi pi-check' : null)}
                        style={{
                            background: theme.outlined ? active === b._id ? `var(--global-highlight-color)` : 'transparent' : `var(--global-surface)`,
                            color: theme.outlined ? active === b._id ? `var(--global-surface)` : `var(--global-highlight-color)` : active === b._id ? `var(--global-highlight-color)` : `var(--${theme.text})`,
                            border: theme.outlined ? `1px solid var(--global-highlight-color)` : 'none'
                        }}
                        onClick={() => onChange(b._id)}
                    ></Tag>
                ))}
            </div>
}

export default BtnPull
