import qs from 'qs'

const randomizeList = async (arr) => {
    let len = arr.length
    let currentIndex
    let usedIndexes = []
    let returnArray = []
    while (len > 0) {
        currentIndex = someIndex(arr.length)
        if (!usedIndexes.includes(currentIndex)) {
            usedIndexes.push(currentIndex)
            returnArray.push(arr[currentIndex])
            len --
        }
    }

    return returnArray
}

const someIndex = (len) => {
    return Math.floor(Math.random() * len);
}

const injectQueryPath = (k, v) => {
    const current = qs.parse(window.location.search.replace('?', ''))
    if(v) {
        current[k] = v
    } else {
        delete current[k]
    }

    return Object.entries(current).length ? `?${qs.stringify(current)}` : ''
}

export { randomizeList, injectQueryPath }
