import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UAParser from 'ua-parser-js'

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import axios from 'axios'
import { ENDPOINT, SENTRY_TRACE_RATE } from './env'

const root = ReactDOM.createRoot(document.getElementById('root'));
const ua = UAParser()

Sentry.init({
  dsn: "https://a0a1dbcbcb394ba38832e625e3276881@o1021987.ingest.sentry.io/4505323494113280",
  integrations: [new BrowserTracing()],
  tracesSampleRate:  SENTRY_TRACE_RATE
})

const loadConfig = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${ENDPOINT}client/appconfig`)
            .then(resp => {
                resolve(resp.data)
            })
    })
}

const mount = (config, fed) => {
    console.log('MOUNTING')
    root.render(
        <App
            device={ua}
            config={config}
            federationObj={fed}
        />
    )
}

const applyClasses = () => {
    setTimeout(() => {
        document.querySelector('#app').setAttribute('class', 'loaded')
    }, 2500)

    setTimeout(() => {
        //document.querySelector('#splash').remove()
    }, 4000)
}

const config = JSON.parse(localStorage.getItem('_am_appconfig'))
if(config && Object.keys(config).length) {
    console.log('Got initial config', config)
    mount(config)
    applyClasses()
    loadConfig()
        .then(({ federation, ...conf }) => {
            if(federation) {
                localStorage.setItem('_am_appconfig', JSON.stringify(conf))
                localStorage.setItem('_am_federation', JSON.stringify(federation))
            }
        })
} else {
    console.log('Loading initial config')
    loadConfig()
        .then(({ federation, ...conf }) => {
            console.log('Federation', federation)
            if(federation) {
                localStorage.setItem('_am_appconfig', JSON.stringify(conf))
                localStorage.setItem('_am_federation', JSON.stringify(federation))
                setTimeout(() => {
                    mount(conf, federation)
                    applyClasses()
                }, 1500)
            }
        })
}

serviceWorkerRegistration.register().then(reg => {
    console.log('Resolved register')
    console.log('Adding listener')
    window.addEventListener('beforeinstallprompt', evt => {
        window.deferredPrompt = evt
        console.log('BEFORE IP Fired. Need to show dialog...')
    })
})
