import React from 'react'

import './stats.scss'

const labels = {
    possession: 'Владение мячом',
    passesCompleted: 'Точные передачи',
    shotsTotal: 'Удары',
    shotsOnTarget: 'Удары в створ',
    frameShots: 'Штанги / Перекладины',
    corners: 'Угловые',
    fouls: 'Нарушения правил',
    yellowCards: 'Предупреждения',
    redCards: 'Удаления'
}

const calcValue = row => {
    if(row.key === 'possession') {
        return row.home
    } else {
        const total = parseInt(row.home) + parseInt(row.away)
        if(!total) {
            return '50%'
        } else {
            return `${Math.ceil((parseInt(row.home)/total)*100)}%`
        }
    }
}

const Stats = ({ statsData, layout }) => {
    return  <div className='stats-content' style={layout === 'mobile' ? {marginTop: 16, border: 'none'} : {}}>
                {statsData.map((row, i) => labels[row.key] ? (
                    <div className='stats-row' key={i}>
                        <div className='alterfont stats-label'>
                            <div className='qty'>{row.home}</div>
                            <div className='str'>{labels[row.key]}</div>
                            <div className='qty'>{row.away}</div>
                        </div>
                        <div className='stats-bar'>
                            <div className='stats-value' style={{width: calcValue(row)}}></div>
                        </div>
                    </div>
                ) : null)}
            </div>
}

export default Stats
