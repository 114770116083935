import React from 'react'

import './style.scss'

const EvtIcon = ({ size, type, theme }) => {
    return  <div
                className={`event-icon ${type}`}
                style={{
                    width: ['yc', 'syc', 'rc'].includes(type) ? size/1.3 : size,
                    height: size,
                    background: `var(--match-events-icon)`
                }}
            ></div>
}

export default EvtIcon
