const appends = {
    career: 'в карьере',
    tournament: 'в турнире',
    team: 'в составе команды'
}

const renderAchievementText = (ach, sample, subjectName) => {
    let output

    switch (ach.scope) {
        case 'squads':
            output = `принял участие в ${ach.value}-м матче ${appends[sample || ach.sample]}${subjectName || ''}`
            break
        case 'lineups':
            output = `вышел в основе в ${ach.value}-й раз ${appends[sample || ach.sample]}${subjectName || ''}`
            break
        case 'goals':
            output = `забил ${ach.value}-й мяч ${appends[sample || ach.sample]}${subjectName || ''}`
            break
        case 'assists':
            output = `отдал ${ach.value}-й голевой пас ${appends[sample || ach.sample]}${subjectName || ''}`
            break
        case 'mvps':
            output = `${ach.value}-й раз ${appends[sample || ach.sample]}${subjectName || ''} признан MVP матча`
            break
    }

    if(!output) {
        console.log('NO OUTPUT', ach, sample, subjectName)
    }

    return output
}

export default renderAchievementText
