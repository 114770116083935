import React from 'react'
import { useHistory } from 'react-router-dom'

import { AvatarShield, NonIdealState } from '../'

import './style.scss'

const StatsCard = ({ data, layout, theme, width, type, setExtraView }) => {
    const history = useHistory()

    const isGoalsRate = (data.title === 'Goals Rate')

    return  <div
                className={`stats-card ${layout}${isGoalsRate ? ' goals-rate' : ''}`}
                style={{
                    width: width
                }}
            >
                {data.list && data.list.length ? (
                    <div
                        className='stats-card_inner'
                        style={{
                            backgroundColor: `var(--statsblock-card)`,
                            color: `var(--statsblock-text)`
                        }}
                    >
                        <div className='stats-card_inner_top'>
                            <div
                                className='stats-card_inner_top__title'
                                style={{
                                    backgroundColor: `var(--statsblock-bgd)`
                                }}
                            >{data.title}</div>
                            <div className='stats-card_inner_top__emblem'>
                                <div
                                    style={{
                                        backgroundImage: `url(${type === 'player' ? data.list[0].team.emblem : data.list[0].emblem})`
                                    }}
                                ></div>
                            </div>
                            <div className='stats-card_inner_top__value alterfont'>{isGoalsRate ? data.list[0].value : Math.round(data.list[0].value)}</div>
                            <div className='stats-card_inner_top__player' onClick={() => history.push(`${type === 'player' ? '/player/'+data.list[0]._id : '/club/'+data.list[0].clubId+'?team='+data.list[0]._id}`)}>
                                <div className='alterfont'>{data.list[0].name}</div>
                                {type === 'player' ? <div>{data.list[0].team.name}</div> : null}
                            </div>
                            <div className={'stats-card_inner_top__avatar'+(type === 'team' ? ' _team' : '')}>
                                {type === 'player' && data.list[0].avatar && data.list[0].avatar.includes('.jpg') ? (
                                    <AvatarShield
                                        width={260}
                                        height={300}
                                        source={data.list[0].avatar}
                                    />
                                ) : (
                                    <div
                                        className={type === 'player' && !data.list[0].avatar ? '_empty' : ''}
                                        style={{
                                            backgroundImage: `url(${type === 'player' ? data.list[0].avatar || require('./holder.png') : data.list[0].emblem})`
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                        <div className='stats-card_inner_list'>
                            {data.list.slice(1).map((row, i) => (
                                <div
                                    className='stats-card_inner_list_row'
                                    key={i}
                                    onClick={() => history.push(`${type === 'player' ? '/player/'+row._id : '/club/'+row.clubId+'?team='+row._id}`)}
                                >
                                    <div className='stats-card_inner_list_player'>
                                        <div>{row.name}</div>
                                        {type === 'player' ? <div>{row.team.name}</div> : null}
                                    </div>
                                    <div className='stats-card_inner_list_value alterfont'>{isGoalsRate ? row.value : Math.round(row.value)}</div>
                                </div>
                            ))}
                        </div>
                        {type === 'player' ? (
                            <div
                                className='stats-card_inner_footer'
                                onClick={() => setExtraView(data.id)}
                            >
                                <span>Открыть TOP-50</span>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div
                        className='stats-card_inner'
                        style={{
                            backgroundColor: `var(--statsblock-card)`,
                            color: `var(--statsblock-text)`
                        }}
                    >
                        <div className='stats-card_inner_top _holder'>
                            <div
                                className='stats-card_inner_top__title'
                                style={{
                                    backgroundColor: `var(--statsblock-bgd)`
                                }}
                            >{data.title}</div>
                            <NonIdealState
                                text='нет данных для этого турнира'
                                height='100%'
                                icon='shirt'
                                color='var(--blue-200)'
                                iconColor='blue-50'
                            />
                        </div>
                    </div>
                )}
            </div>
}

export default StatsCard
