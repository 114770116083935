import React from 'react'

import Card from '../Club/Feed/Card'
import { NonIdealState } from '../Atoms'

const MatchPubs = ({ data }) => {
    return  data ? <div className='match-pubs'>
                {data.length ? data.map((card, i) => (
                    <Card data={card} key={i} />
                )) : (
                    <NonIdealState icon='time' text='нет материалов' />
                )}
            </div> : null
}

export default MatchPubs
