import React, { useRef, useEffect, useState } from 'react'

import CustomScrollbars from 'react-custom-scrollbars-2'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'

import 'yet-another-react-lightbox/styles.css'
import './style.scss'

import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

const getGalleryPreview = arr => {
    const srtd = arr.sort((a, b) => b.height - a.height)
    return {...srtd[0], src: srtd[0].url}
}

const AlbumItem = ({ gallery, pubId, isDesktop }) => {
    const [wrapper, setWrapper] = useState(null)
    const [boxIndex, setBoxIndex] = useState(-1)
    const wrapperRef = useRef(null)

    useEffect(() => {
        if(wrapperRef && wrapperRef.current) {
            setWrapper(wrapperRef.current.getBoundingClientRect())
        }
    }, [wrapperRef])

    const content = gallery && gallery.length ? gallery.filter(f => f.type === 'photo').map((f, idx) => getGalleryPreview(f.photo.sizes)) : []

    const wrapperHeight = wrapper ? window.outerHeight - wrapper.top - 25 : 0

    return  <div id='galleryWrapper' ref={wrapperRef} style={wrapper && !isDesktop ? {height: wrapperHeight} : isDesktop ? {aspectRatio: '16/9', position: 'relative'} : {}}>
                {wrapper ? (
                    <CustomScrollbars>

                        {isDesktop ? (
                            <div className='preview' onClick={() => setBoxIndex(0)}>
                                <div className='photos-qty alterfont'>{content.length} фото</div>
                            </div>
                        ) : (
                            <PhotoAlbum
                                layout='masonry'
                                photos={content}
                                spacing={5}
                                onClick={({ index }) => setBoxIndex(index)}
                            />
                        )}

                        <Lightbox
                            slides={content.map(({ src, width }) => ({src: src, width: width}))}
                            open={boxIndex >= 0}
                            index={boxIndex}
                            close={() => setBoxIndex(-1)}
                            plugins={[Thumbnails, Zoom]}
                        />

                    </CustomScrollbars>
                ) : null}
            </div>
}

export default AlbumItem
