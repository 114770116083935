import React from 'react'

import './style.scss'

const ContentCard = ({ children, theme, title }) => {
    return  <div
                className='content-card'
                style={{
                    background: theme.surface,
                    boxShadow: `2px 2px 25px ${theme.shadowColor}`
                }}
            >
                <div
                    className='title alterfont'
                    style={{
                        background: theme.titleSurface,
                        color: theme.titleColor
                    }}
                >{title}</div>
                {children}
            </div>
}

export default ContentCard
