import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader } from '../Atoms'

import { Sidebar } from 'primereact/sidebar'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import CustomScrollbars from 'react-custom-scrollbars-2'

import './desktop.scss'

import { ENDPOINT } from '../../env'
import axios from 'axios'

import qs from 'qs'

import { injectLinks } from './utils'

const getPhotoSrc = obj => {
    if(obj?.sizes && obj?.sizes?.length) {
        return obj?.sizes?.sort((a, b) => b.height - a.height)[0].url
    } else {
        return ''
    }
}

const getVideoSrc = obj => {
    if(obj.image && obj.image.length) {
        return obj.image.sort((a, b) => b.height - a.height)[0].url
    } else {
        return ''
    }
}

const cleanQueryString = obj => {
    const cleaned = Object.keys(obj).reduce((acc, k) => {
        if(k !== 'publication') {
            acc[k] = obj[k]
        }
        return acc
    }, {})

    if(Object.entries(cleaned).length) {
        return `?${qs.stringify(cleaned)}`
    } else {
        return ''
    }
}

const DesktopPub = ({ publication, query, resetPublication }) => {
    const [data, setData] = useState(null)

    const history = useHistory()

    useEffect(() => {
        if(publication) {
            axios.get(`${ENDPOINT}client/publication/${publication}`)
                .then(resp => {
                    if(resp.data && resp.data._id) {
                        setData(resp.data)
                    }
                })
        }
    }, [publication])

    const attachment = data && data.attachments ? data.attachments[0] || null : null
    const splidedMedia = data && data.attachments && data.attachments.length > 1

    const titleRe = data ? new RegExp(data.title, 'g') : ''

    return  <Sidebar
                visible={publication}
                position='bottom'
                maskClassName='publication-desktop'
                dismissable
                //blockScroll
                onHide={() => {
                    history.push(`${window.location.pathname}${cleanQueryString(query)}`)
                    resetPublication()
                    setData(null)
                }}
            >
                {!data ? (
                    <div style={{marginTop: 200}}>
                        <Loader width={200} />
                    </div>
                ) : [
                        <h3 className='alterfont'>{data.title}</h3>,
                        <div className='publication-body'>
                        <div className='publication-meta'>
                        </div>

                        {attachment ? splidedMedia ? (
                            <Splide>
                                {data.attachments.filter(att => att.type === 'photo').map((att, i) => (
                                    <SplideSlide key={i}>
                                        <img src={getPhotoSrc(att.photo)} />
                                    </SplideSlide>
                                ))}
                            </Splide>
                        ) : (
                            <div
                                className='publication-media'
                                style={{backgroundImage: `url(${attachment.type === 'video' ? getVideoSrc(attachment.video) : getPhotoSrc(attachment.photo)})`}}
                            >
                            </div>
                        ) : null}

                        <div className={`publication-content alterfont${data.type === 'text' && data.social ? ' imported' : ''}`} dangerouslySetInnerHTML={{__html: injectLinks(data.content ? data.content.replace(titleRe, '') : '')}} />
                        </div>
                ]}
            </Sidebar>
}

export default DesktopPub
