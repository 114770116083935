const areasModel = {
    root: [
        {label: 'Лента', id: 'stories'},
        {label: 'Матчи', id: 'matches'},
        {label: 'Статистика', id: 'stats'},
        {label: 'Лучшие', id: 'top'}
    ],
    tournament: [
        {label: 'Лента', id: 'stories'},
        {label: 'Матчи', id: 'matches'},
        {label: 'Статистика', id: 'stats'},
        {label: 'Лучшие', id: 'top'}
    ],
    player: [
        {label: 'Профиль', id: 'info'},
        {label: 'Матчи', id: 'matches'},
        {label: 'Карьера', id: 'stats'},
        // {label: 'События', id: 'timeline'}
    ],
    match: [
        {label: 'Инфо', id: 'info'},
        {label: 'События', id: 'events'},
        {label: 'Составы', id: 'rosters'},
        {label: 'Медиа', id: 'media'}
    ],
    club: [
        {label: 'Лента', id: 'stories'},
        {label: 'Матчи', id: 'matches'},
        {label: 'Статистика', id: 'stats'},
        {label: 'Игроки', id: 'players'}
    ],
    feed: [
        {label: 'Материалы по тегу', id: 'feed'},
    ]
}

export default areasModel
