import React from 'react'
import qs from 'qs'

import { MatchesCarousel } from '../../'
import MobileMatches from '../../MatchesCarousel/Mobile'

import './style.scss'

const { matchesCarousel } = JSON.parse(localStorage.getItem('_am_appconfig'))

const Matches = ({ data, theme, team }) => {
    return  <div className='club-matches'>
                {/*<div className='title alterfont'>Матчи</div>*/}

                {/*<MatchesCarousel
                    width='100%'
                    extended={false}
                    scope='team'
                    subject={team || qs.parse(window.location.search.slice(1)).team}
                    layout='desktop'
                    theme={theme}
                    v2={true}
                />*/}

                <MobileMatches
                    deftheme={theme}
                    extendedMode={true}
                    scope='team'
                    subject={team || qs.parse(window.location.search.slice(1)).team}
                    layout='desktop'
                />
            </div>
}

export default Matches
