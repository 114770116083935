import React from 'react'

import { Sidebar } from 'primereact/sidebar'

import './style.scss'

import { BETS_BASE } from '../../../env'

const Fantasy = ({ state, user, onClose }) => {
    const fromMatch = window.location.pathname.includes('/match/')
    const presetMatch = fromMatch ? window.location.pathname.split('/')[2] : null

    return  <div>
                <Sidebar
                    position='top'
                    visible={state && user && user._id}
                    maskClassName='fantasy-panel'
                    onHide={onClose}
                >
                    {user ? <iframe src={`${BETS_BASE}${user._id}${presetMatch && presetMatch.length === 24 ? '?presetMatch='+presetMatch : ''}`} frameborder="0" /> : null}
                </Sidebar>
            </div>
}

export default Fantasy
