import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory } from "react-router-dom"
import { Top, MediaBlock, MediaBlockV2, MatchesCarousel, Tournament, Feed, Standings, StatsBlock, Highlights, BottomStories, Footer, Match, Club, Player } from '../../Components'
import { TopLineup, AchievementsCarousel, BannerNode, ScrollToTop } from '../../Components/Atoms'
import MultiWrap from '../../Components/Standings/MultiWrap'
import DesktopPub from '../../Components/Publication/Desktop'

import withStorage from '../Mobile/Storage'

import { injectQueryPath } from '../../utils'

import './style.scss'
import './pixel-ratios.scss'
import './medias.scss'

import LayoutContext from './ctx'
import qs from 'qs'

import { CONF } from '../../configs'

const _federation = JSON.parse(localStorage.getItem('_am_federation'))

const Desktop = ({ width, config, federationObj }) => {
    const query = qs.parse(window.location.search.slice(1))

    const [toursArr, setToursArr] = useState(null)
    const [user, setUser] = useState(null)
    const [accountOpen, setAccountOpen] = useState(true)
    const [publicationId, setPublicationId] = useState(query.publication || null)

    const [bannerNode, setBannerNode] = useState(CONF.bannerNode ? (
        <BannerNode
            media={CONF.bannerNode.media}
            action={() => {
                if(CONF.bannerNode.publicationId) {
                    setPublicationId('6412c6f4f71656203756a69d');
                    history.push(`${window.location.pathname}${injectQueryPath('publication', CONF.bannerNode.publicationId)}`);
                } else {
                    window.open(CONF.bannerNode.externalLink)
                }
            }}
        />) : null
    )

    const federation = CONF.federation || _federation || federationObj

    const history = useHistory()

    return  <LayoutContext.Provider
                value={{appUser: user, setUser, accountOpen, setAccountOpen, federation, setPublicationId, bannerNode}}
            >
            <div className='layout-desktop'>
                <Top {...config.top} width={width} layout={'desktop'} />
                <ScrollToTop />
                <Switch>
                    <Route path='/match/:id'>
                        <Match {...config.match} width={width} layout='desktop' />
                    </Route>
                    <Route path='/tournament/:id'>
                        <MatchesCarousel {...config.matchesCarousel} width={width} scope='tournament' layout='desktop' extendedMode={true} />
                        <MediaBlock {...config.mediablock} width={width} layout='desktop' subjectId={null} scope='tournament' />
                        <Tournament {...config.tournament} width={width} layout='desktop' />
                        <StatsBlock {...config.stats} blured={false} width={width} layout='desktop' />
                    </Route>

                    <Route path='/club/:id'>
                        <Club {...config} />
                    </Route>

                    <Route path='/player/:id'>
                        <Player />
                    </Route>

                    <Route exact path='/'>
                        <MediaBlock {...config.mediablock} width={width} layout='desktop' subjectId={federation._id} scope='federation' />
                        <MatchesCarousel {...config.matchesCarousel} subject={federation._id} scope='federation' width={width} layout='desktop' extendedMode={true} />
                        <div
                            className='desktop-section'
                            style={{backgroundColor: config.feed.theme.surface.includes('#') ? config.feed.theme.surface : `var(--${config.feed.theme.surface})`}}
                        >
                            <div className='section-stripe' style={{background: config.feed.theme.stripe.includes('#') ? config.feed.theme.stripe : `var(--${config.feed.theme.stripe})`}}></div>
                            <div className='container'>
                                <div className='primary'>
                                    <div className='sub-cols'>
                                        {bannerNode}

                                        <div className='main'>
                                            <Feed subjectId={federation._id} {...config.feed} width={width} layout='desktop' />
                                        </div>

                                        <div className='secondary'>
                                            <TopLineup scope='federation' subjectId={federation._id} setToursArr={setToursArr} />
                                            <AchievementsCarousel toursArr={toursArr} scope='federation' subjectId={federation._id} />
                                        </div>
                                    </div>
                                </div>
                                <div className='secondary'>
                                    <MultiWrap {...config.standings} width={width} layout='desktop' />
                                </div>
                            </div>
                        </div>
                        <StatsBlock {...config.stats} blured={true} width={width} layout='desktop' />
                        <Highlights {...config.highlights} width={width} scope='federation' subjectId={federation._id} layout='desktop' contentType='video' />
                        {/*<BottomStories {...config.stories} width={width} layout='desktop' />*/}
                    </Route>
                </Switch>

                <Footer {...config.footer} width={width} layout='desktop' />

                <DesktopPub
                    publication={publicationId}
                    resetPublication={() => setPublicationId(null)}
                    query={query}
                />
            </div>
        </LayoutContext.Provider>
}

export default withStorage(Desktop)
