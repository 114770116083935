import React, { useState, useEffect } from 'react'

import './style.scss'
import qs from 'qs'

import axios from 'axios'
import { ENDPOINT } from '../../../env'
import { NonIdealState, StandingsSkeleton } from '../../Atoms'
import Standings from '../../Standings'
import { Skeleton } from 'primereact/skeleton'
import { Dropdown } from 'primereact/dropdown'
import {SelectButton} from "primereact/selectbutton";

const TeamStandings = ({ tours, teamId, theme, scopedTheme }) => {
    const [tournament, setTournament] = useState(null)
    const [empty, setEmpty] = useState(false)
    const [data, setData] = useState(null)
    const [loadedTour, setLoadedTour] = useState(null)
    const [stage, setStage] = useState(0)
    const [fullData, setFullData] = useState([])

    useEffect(() => {
        if(tours && !loadedTour) {
            if(tours[0].items[0] || tours[1].items[0]) {
                setTournament(tours[0].items[0] || tours[1].items[0])
            } else {
                setEmpty(true)
            }
        }
    }, [tours])

    useEffect(() => {
        setLoadedTour(null)
    }, [teamId])

    useEffect(() => {
        if(tournament && tournament._id && loadedTour !== tournament._id) {
            setData(null)
            axios.get(`${ENDPOINT}client/standings/${tournament._id}?full=true&teamId=${teamId}`)
                .then(resp => {
                    const widgetData = resp.data && resp.data[0] && resp.data[resp.data.length - 1].groups ? resp.data[resp.data.length - 1].groups.find(g => g.teams.find(gt => gt._id === teamId)) : resp.data[resp.data.length - 1]
                    if(resp.data && resp.data[0]){
                        setFullData(resp.data)
                        setStage(resp.data[resp.data.length - 1]._id)
                    } else {
                        setFullData([])
                        setStage(0)
                    }
                    setData(widgetData)
                    setLoadedTour(tournament._id)
                })
        }
    }, [tournament])

    return  empty ? (
                <NonIdealState
                    height={300}
                    icon='time'
                    text='нет информации о турнирах'
                />
            ) : (
                <div className='team-standings'>
                    {!tournament ? (
                        <Skeleton width='100%' height={32} />
                    ) : [
                            <Dropdown
                                className='compact'
                                value={tournament}
                                optionLabel="label"
                                optionGroupLabel="label"
                                optionGroupChildren="items"
                                options={tours.filter(g => g.items && g.items.length)}
                                onChange={e => setTournament(e.value)}
                            />,
                            data ? (
                                <Standings
                                    width='100%'
                                    config={{compact: true}}
                                    theme={scopedTheme}
                                    chess={false}
                                    layout='desktop'
                                    data={data.standings}
                                    highlight={teamId}
                                />
                            ) : (
                                <StandingsSkeleton rows={9} compact={true} />
                            ),
                            fullData.length > 1 ?
                                <SelectButton
                                    value={stage}
                                    options={fullData}
                                    onChange={(e) => {
                                        setStage(e.value)
                                        const newData = fullData.find(fd => fd._id === e.value)
                                        setData(newData.groups ? newData.groups.find(g => g.teams.find(gt => gt._id === teamId)) : newData)
                                    }}
                                    optionLabel="title"
                                    optionValue='_id'
                                    unselectable={false}
                                /> : null

                    ]}
                </div>
            )
}

export default TeamStandings
