import React, { useState, useEffect } from 'react'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const getGalleryPreview = arr => {
    const srtd = arr.sort((a, b) => b.height - a.height)
    return {...srtd[0], src: srtd[0].url}
}

const PublicationPreview = ({ data, onOpen }) => {
    const [album, setAlbum] = useState(null)
    const [albumIndex, setAlbumIndex] = useState(-1)

    useEffect(() => {
        if(data && data.mediaType === 'album') {
            axios.get(`${ENDPOINT}client/publication/${data._id}`)
                .then(resp => {
                    setAlbum(resp.data.attachments
                        .filter(a => a.type !== 'album')
                        .map((f, idx) => getGalleryPreview(f.photo.sizes))
                        .map(({ src, width }) => ({src: src, width: width}))
                    )
                })
        }
    }, [data])

    return  <div className='publication-preview'>
                <div
                    className='publication-preview_media'
                    style={{
                        backgroundImage: `url(${data.preview})`
                    }}
                    onClick={() => onOpen(album || null)}
                >
                    {data.mediaType === 'video' ? <div className='play_btn'></div> : null}
                    {album ? <div className='photos-qty'>{album.length} фото</div> : null}
                </div>
                <div className='publication-preview_title'>{data.title}</div>
            </div>
}

export default PublicationPreview
