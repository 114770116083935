import React, { useState, useEffect } from 'react'

import { Tag } from 'primereact/tag'

import { Emblem } from '../../Atoms'
import { Skeleton } from 'primereact/skeleton'

import './style.scss'

import { injectQueryPath } from '../../../utils'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

const formatTeamName = str => {
    if(/\(*\)/.test(str)) {
        return str.split('(')[1].split(')')[0]
    } else {
        return str
    }
}

const checkFavorites = (clubId, arr) => {
    return arr ? arr.find(el => el._id === clubId || el.url.includes(clubId)) : false
}

const Top = ({ data, team, setTeam, favorites, appUserId, showAccount }) => {
    const [favoritedBy, setFavoritedBy] = useState(data ? data.favoritedBy || 0 : 0)
    const [inFavorites, setInfavorites] = useState(false)

    useEffect(() => {
        if(favorites && favorites.length) {
            setInfavorites(checkFavorites(data ? data._id : null, favorites))
        }
    }, [favorites])

    useEffect(() => {
        if(data && (data.favoritedBy !== favoritedBy)) {
            setFavoritedBy(data.favoritedBy)
        }
    }, [data])

    const toggleSub = () => {
        if(!appUserId) {
            showAccount()
        } else {
            axios.put(`${ENDPOINT}user/${appUserId}`, {
                favorites: inFavorites ? [...favorites].filter(f => !f.url.includes(data._id)) : [...favorites].concat([{
                    img: data.emblem || null,
                    type: 'team',
                    info: data.name,
                    _id: team || data._id,
                    url: `/club/${data._id}${team ? '?team='+team : ''}`
                }])
            })
            .then(resp => {
                setFavoritedBy(inFavorites ? (favoritedBy - 1) : (favoritedBy + 1))
                setInfavorites(!inFavorites)
            })
        }
    }

    return  <div className='club-top'>
                <div className='club-top_intro'>
                    <div className='club-top_emblem'>
                        {data ? (
                            <Emblem size='lg' source={data.emblem || ''} backdroped={true} />
                        ) : <Skeleton width={128} height={128} />}
                    </div>

                    <div className='club-top_primary'>
                        <div className='club-top_name'>
                            {data ? (
                                <span className='alterfont'>{data.name}</span>
                            ) : <Skeleton width={400} height={36} />}
                        </div>
                        <div className='club-top_location'>
                            {data ? data.city ? (
                                <span>{data.city.name}</span>
                            ) : null : <Skeleton width={140} height={17} />}
                        </div>
                        <div className='club-top_location'>
                            {data ? data.region ? (
                                <span>{data.region.name}</span>
                            ) : null : <Skeleton width={190} height={17} />}
                        </div>

                        {inFavorites ? (
                            <Tag className='sub negative' icon='pi pi-times' value='Отписаться' onClick={() => toggleSub()} />
                        ) : (
                            <Tag className='sub positive' icon='pi pi-bell' value='Подписаться' onClick={() => toggleSub()} />
                        )}
                    </div>
                </div>

                <div className='club-top_meta'>
                    <div className='club-top_subscriptions'>
                        <div className='club-top_subscriptions_title'>подписчиков</div>
                        <div className='club-top_subscriptions_value alterfont'>
                            <div>{favoritedBy}</div>
                        </div>
                        <div className='club-top_subscriptions_icon'>
                            {data ? (
                                <span className='pi pi-star-fill'></span>
                            ) : <Skeleton width={36} height={36} />}
                        </div>
                    </div>
                </div>

                {data && data.teams && data.teams.length > 1 ? (
                    <div className='club-top_teams'>
                        <div className='title'>Выберите команду:</div>
                        <div className='list'>
                            {data.teams.sort((a, b) => b.canonical - a.canonical).map((t, i) => (
                                <Tag
                                    className={team === t._id ? 'selected' : ''}
                                    key={i}
                                    onClick={() => {
                                        setTeam(t._id)
                                        window.history.pushState({}, null, `${window.location.pathname}${injectQueryPath('team', t._id)}`)
                                    }}
                                >{formatTeamName(t.name)}</Tag>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
}

export default Top
