import React from 'react'

import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

const getGalleryPreview = arr => {
    const srtd = arr.sort((a, b) => b.height - a.height)
    return {...srtd[0], src: srtd[0].url}
}

const Gallery = ({ attachments, boxIndex, setBoxIndex }) => {
    const content = attachments && attachments.length ? attachments.filter(a => a.photo).map((f, idx) => getGalleryPreview(f.photo.sizes)) : []

    return  <Lightbox
                slides={content.map(({ src, width }) => ({src: src, width: width}))}
                open={boxIndex >= 0}
                index={boxIndex}
                close={() => setBoxIndex(-1)}
                plugins={[Thumbnails, Zoom]}
            />
}

export default Gallery
