import React, { useState, useEffect } from 'react'

import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../env'
import { Skeleton } from 'primereact/skeleton'
import { Tag } from 'primereact/tag'

import moment from 'moment'

const icons = {
    won: require('./assets/ball.png'),
    non_lost: require('./assets/banner.png'),
    scored: require('./assets/boots.png'),
    unconceded: require('./assets/goalie.png')
}

const SeriesStack = ({ scope, title, subjectId }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        if(subjectId) {
            axios.get(`${ENDPOINT}client/series/${subjectId}?scope=${scope}`)
                .then(resp => {
                    if (!resp.data.error){
                        setData(resp.data)
                    }

                })
        }
    }, [subjectId])

    return  <div className='series-stack'>
                <div className='title alterfont'>{title}</div>
                {data ? data.map((card, i) => (
                    <div className={`series-item ${card.type}`} key={i}>
                        <div className='icon'>
                            <img src={icons[card.type]} />
                        </div>
                        <div className='title'>
                            <span>{card.title}</span>
                        </div>
                        <div className='status'>
                            <div className='established'>зафиксирована:</div>
                            <div className='date'>{moment(card.established, 'YY-MM-DD').format('D MMMM YYYY')}</div>
                            {card.current ? (
                                <div className='current'>
                                    <div>текущая серия: <span className='alterfont'>{card.current.value}</span></div>
                                </div>
                            ) : null}
                        </div>
                        {card.isCurrent ? (
                            <Tag
                                icon='pi pi-bolt'
                                className='flash'
                            >продолжается</Tag>
                        ) : null}
                        <div className='value'>
                            <div className='alterfont'>{card.value}</div>
                            <span className='alterfont'>матчей</span>
                        </div>
                    </div>
                )) : [
                    <Skeleton height={90} key={1} />,
                    <Skeleton height={90} key={2} />,
                    <Skeleton height={90} key={3} />
                ]}
            </div>
}

export default SeriesStack
