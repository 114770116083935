import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { motion, AnimatePresence } from 'framer-motion'
import { Dropdown } from 'primereact/dropdown'

import './style.scss'

import { ENDPOINT } from '../../../env'
import axios from 'axios'

import { Loader, Emblem } from '../../Atoms'

import moment from 'moment'

const formatRange = range => {
    if(range) {
        let str = `с ${moment(range.start, 'YY-MM-DD').format('MM.YYYY')}`
        if(range.end) {
            str += ` по ${moment(range.end, 'YY-MM-DD').format('MM.YYYY')}`
        }

        return <div className={`range ${range.end ? ' ended' : ''}`}>{str}</div>
    } else {
        return null
    }
}

const labels = {
    squads: 'матчи',
    lineups: 'в основе',
    mvps: 'mvp',
    goals: 'голы',
    assists: 'ассисты',
    ycs: 'жк',
    rcs: 'кк'
}

const mapper = teams => {
    return teams.filter(row => row.tournaments).map(row => {
        const { squads, lineups, goals, assists, ycs, rcs, mvps, tournaments, ...raw } = row

        return {
            ...raw,
            overall: {squads, lineups, goals, assists, ycs, rcs, mvps},
            splitted: [...tournaments]
        }
    })
}

const Body = ({ data }) => {
    const [tour, setTour] = useState(data.splitted.length === 1 ? data.splitted[0].tournament._id : 'overall')

    const sample = tour === 'overall' ? data.overall : data.splitted.find(t => t.tournament._id === tour)

    return  <div className='career-body'>
                <Dropdown
                    value={tour}
                    options={[{value: 'overall', label: 'Все турниры'}].concat(data.splitted.map((t, ix) => ({value: t.tournament._id, label: t.tournament.name})))}
                    onChange={e => setTour(e.target.value)}
                    disabled={data.splitted.length === 1}
                />

                <div className='values'>
                    {Object.entries(labels).map((e, i) => (
                        <div className='values-item' key={i}>
                            <span>{labels[e[0]]}</span>
                            <div className='alterfont'>{sample[e[0]]}</div>
                        </div>
                    ))}
                </div>
            </div>
}

const Career = ({ teams }) => {
    const [data, setData] = useState(null)

    const history = useHistory()

    useEffect(() => {
        if(teams) {
            setData(mapper(teams))
        }
    }, [teams])

    return  <div className='career'>
                <AnimatePresence
                    mode='popLayout'
                >
                    {!data ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: .8}}
                        >
                            <Loader width={240} />
                        </motion.div>
                    ) : null}
                </AnimatePresence>

                <AnimatePresence
                    mode='popLayout'
                >
                    {data ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: .8}}
                        >
                            {data.map((card, i) => (
                                <div className='career-card' key={i}>
                                    <div
                                        className='title'
                                        onClick={() => history.push(`/club/${card.team.club._id}`)}
                                    >
                                        <Emblem source={card.team.club.emblem || ''} size='xs' backdroped={true} />
                                        <span>{card.team.name}</span>
                                        {formatRange(card.range)}
                                    </div>

                                    <Body data={card} />
                                </div>
                            ))}
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
}

export default Career
