import React, { useRef } from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Tag } from 'primereact/tag'
import './style.scss'

import moment from 'moment'

const ItemsNav = ({ category, activeIdx, setActiveIdx }) => {
    const slides = category ? [...category.data] : []

    const slidesRef = useRef(null)

    return  <div className="items-nav">
                <Splide
                    ref={slidesRef}
                    options={{
                        pagination: false,
                        fixedWidth: 180,
                        focus: 0,
                        type: null,
                        arrows: (slides.length > 2),
                        gap: '10px',
                        interval: 4500,
                        perMove: 1,
                        updateOnMove: false,
                        waitForTransition: true,
                        clones: 1
                    }}
                    onMoved={(e, idx) => setActiveIdx(idx)}
                >
                    {slides.map((sl, i) => (
                        <SplideSlide key={i} onClick={() => setActiveIdx(i)}>
                            <div className='items-nav_item'>
                                <div><Tag>{moment(sl.date, 'YY-MM-DD').format('DD.MM.YY')}</Tag></div>
                                <div className='title'>{sl.title}</div>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
}

export default ItemsNav
