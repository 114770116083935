export default {
    appTitle: 'КЛФЛ | LFL39.RU',
    federation: {
        _id: '63720deb3d69d811c73373e5'
    },
    bannerNode: null,
    brandEmblem: `${process.env.FILES_BUCKET}leagues/klfl_288.png`,
    topBanners: [
        `${process.env.FILES_BUCKET}apps_promo/stadium_kgd.png`,
        `${process.env.FILES_BUCKET}apps_promo/champoin_store.png`,
        `${process.env.FILES_BUCKET}apps_promo/fc_baltika.png`,
        `${process.env.FILES_BUCKET}apps_promo/pivnoy_dvor.png`
    ],
    footerCreds: {
        copyright: '© КРОО Любительская Футбольная Лига, 2024',
        phone: '+7 (921) 610-79-42',
        address: '236000, г. Калининград, ул. Дм.Донского, д. 5а'
    },
    footerStores: null,
    footerBanners: [
        `${process.env.FILES_BUCKET}apps_promo/dfl39_promo_3.png`,
        `${process.env.FILES_BUCKET}apps_promo/dfl39_promo_4.png`,
        `${process.env.FILES_BUCKET}apps_promo/dfl39_promo_5.png`
    ],
    footerSocials: {
        vk: 'https://vk.com/lfl39',
        youtube: 'https://www.youtube.com/@user-zp9sw3fp9i'
    },
    fantasyBrand: {
        type: 'label',
        text: 'Amateum',
        link: null
    },
    metrikaCounter: 93552623,
    firebase: {
        config: {
          apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
          authDomain: "amateum-bb5a1.firebaseapp.com",
          projectId: "amateum-bb5a1",
          storageBucket: "amateum-bb5a1.appspot.com",
          messagingSenderId: "456338694560",
          appId: "1:456338694560:web:7b9723017c89aa864784c5",
          measurementId: "G-81PYKSXGL6"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
