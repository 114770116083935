import React from 'react'

import { Skeleton } from 'primereact/skeleton'

import './style.scss'

const rndAppend = (min, max) => {
    return Math.random() * (max - min) + min
}

const renderArr = rows => {
    const arr = []
    for(let i=0; i<rows; i++) {
        arr.push(i)
    }

    return arr
}

const StandingsSkeleton = ({ rows, compact }) => {
    const arr = renderArr(rows)

    return  <div className='standings-skeleton'>
                {arr.map((r, i) => (
                    <div key={i} className='standings-skeleton_row'>
                        <Skeleton width={`${compact ? rndAppend(45, 75) : rndAppend(30, 55)}%`} height={22} />
                        {compact ? null : <Skeleton width={120} height={22} /> }
                        <Skeleton width={22} height={22} />
                    </div>
                ))}
            </div>
}

export default StandingsSkeleton
