import React from 'react'

import { Button } from 'primereact/button'

import './style.scss'

const BtnLink = ({ icon, label, handler, iconPos, className }) => {
    return  <Button
                className={'btn-link'+(className ? ' '+className : '')}
                label={label}
                icon={`pi pi-${icon}`}
                onClick={() => handler()}
                iconPos={iconPos || 'left'}
            />
}

export default BtnLink
