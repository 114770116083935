const injectLinks = html => {
    if(html && html.length) {
        let output = html
        const links = html.match(/\svk.com\S{0,}\s/gi)

        if(links) {
            for(let link of links) {
                output = output.replace(link, ` <a href="https://${link.trim()}" target="_blank">по ссылке</a> `)
            }
        }

        const mentions = output.match(/\[id\d{0,}\|.{0,}\]/gi)

        if(mentions) {
            for(let mention of mentions) {
                const men = mention.replace(/[\[\]]/g, '').split('|')
                output = output.replace(mention, `<a href="https://vk.com/${men[0].trim()}" target="_blank">@${men[1]}</a>`)
            }
        }

        return output.replace(/<p><br><\/p>/g, '')
    } else {
        return ''
    }
}

export { injectLinks }
