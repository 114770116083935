import React from 'react'
import StorageContext from './ctx'

import { entries, get, set } from 'idb-keyval'

import moment from 'moment'

const getStorage = async () => {
    const data = await entries()
    return data
}

const fillEmpty = async (k, v=null, type=null) => {
    const entry = await get(k)
    if(!entry) {
        set(k, moment().toString())
    }
}

const putContentMarkers = async arr => {
    for(let data of arr) {
        get(data.join('_')).then(val => {
            set(data.join('_'), (val || 0) + 1)
        })
    }
}

const withFlows = (Wrapped) => {
    return props => {
        return  <StorageContext.Provider value={{ getStorage, fillEmpty, putContentMarkers }}>
                    <Wrapped {...props} />
                </StorageContext.Provider>
    }
}

export default withFlows
