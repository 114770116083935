import React, { useState, useEffect, useContext } from 'react'

import StorageContext from 'storage'

import { InputMask } from 'primereact/inputmask'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

import { app, auth } from './firebase'

import { RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword } from 'firebase/auth'

import { ENDPOINT } from '../../../env'
import { CONF } from '../../../configs'
import axios from 'axios'

import ym from 'react-yandex-metrika'

const Form = ({ setUser, setInitedFlag }) => {
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [sending, setSending] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [code, setCode] = useState('')
    const [confirming, setConfirming] = useState(false)
    const [codeErr, setCodeErr] = useState(false)
    const [mailMode, setMailMode] = useState(false)
    const [mailCodeMode, setMailCodeMode] = useState(false)

    const sctx = useContext(StorageContext)

    useEffect(() => {
        if(code && code.replace(/\D/g,'').length === 6) {
            setConfirming(true)
            if(mailCodeMode) {
                signInWithEmailAndPassword(auth, email, code)
                    .then(result => {
                        setUser({
                            token: result.user.accessToken,
                            uid: result.user.uid,
                            phone: result.user.phoneNumber,
                            email: result.user.email
                        })
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } else {
                let confirmationResult = window.confirmationResult
                confirmationResult.confirm(code.replace(/\D/g,''))
                    .then(result => {
                        setUser({
                            token: result.user.accessToken,
                            uid: result.user.uid,
                            phone: result.user.phoneNumber
                        })

                        ym('reachGoal', 'SUCCESS_AUTH')
                        sctx.fillEmpty('first_authorized')
                    })
                    .catch(err => {
                        setConfirming(false)
                        setCodeErr(true)
                    })
            }
        } else {
            setCodeErr(false)
        }
    }, [code])

    const storeGlobalVerifier = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, auth)
    }

    const cleanedPhone = phone.replace(/\D/g, '')

    const signIn = () => {
        setSending(true)
        const { federation } = CONF
        axios.get(`${ENDPOINT}client/binduser/check/?init=true&${mailMode ? 'email' : 'phone'}=${mailMode ? email : cleanedPhone}&scope=${federation._id}`)
            .then(resp => {
                if(resp.data) {
                    console.log(resp.data)
                    if(!resp.data.error) {
                        setInitedFlag(true)
                    }

                    if(!mailMode) {
                        storeGlobalVerifier()
                        let appVerifier = window.recaptchaVerifier
                        signInWithPhoneNumber(auth, phone, appVerifier)
                            .then(confirmationResult => {
                                window.confirmationResult = confirmationResult
                                setConfirmation(true)
                                setSending(false)
                            })
                            .catch(err => {
                                setSending(false)
                            })
                    } else {
                        setMailCodeMode(true)
                    }
                } else {
                    alert('Что-то пошло не так. Повторите попытку')
                }
            })
    }

    const resetForm = () => {
        window.recaptchaVerifier = null
        window.confirmationResult = null
        setSending(false)
        setConfirmation(false)
        setCode('')
        setConfirming(false)
        setMailCodeMode(false)
    }

    return  <div className='form'>
                <img className='img' src={require('./ticket.png')} />
                <div className='title alterfont'>АВТОРИЗАЦИЯ</div>

                {!confirmation && !mailCodeMode ? [
                    <span className='switch-label' onClick={() => setMailMode(!mailMode)}>
                        <Button icon='pi pi-mobile' className={!mailMode ? 'active' : 'none'} />
                        <Button icon='pi pi-envelope' className={mailMode ? 'active' : 'none'} />
                    </span>,
                    <span className='p-float-label'>
                        {mailMode ? (
                            <InputText
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                disabled={sending}
                                autoClear={false}
                                autocomplete='off'
                                id='email'
                            />
                        ) : (<InputMask
                            id='phone'
                            mask='+7 (999) 999-99-99'
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={sending}
                            autoClear={false}
                            autocomplete='off'
                            inputmode='numeric'
                        />)},
                        {!sending ? <label htmlFor='phone'>{mailMode ? 'Ваш email' : 'Ваш номер телефона'}</label> : null}
                    </span>
                ] : (
                    <span className={!confirming ? 'p-float-label' : 'p-input-icon-right'}>
                        {confirming ? <i className='pi pi-spin pi-spinner'></i> : null}
                        <InputMask
                            id='code'
                            mask='999999'
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            autoClear={false}
                            autocomplete='off'
                        />
                        {!confirming ? <label htmlFor='code'>Проверочный код</label> : null}
                    </span>
                )}

                {confirmation || mailCodeMode ? codeErr ? <small className='p-error block'>Введён неверный код</small> : <span className='notice'>Мы отправили 6-значный проверочный код на {mailCodeMode ? 'почту' : 'номер'} {mailCodeMode ? email : phone}, введите его в поле выше. {!mailCodeMode ? <i className='reset' onClick={() => resetForm()}>Изменить номер</i> : null}</span> : null}

                {!confirmation && !mailCodeMode ? (
                    <Button
                        disabled={(!email.length && cleanedPhone.length < 11) || sending}
                        onClick={() => signIn()}
                        loading={sending}
                        label='Продолжить'
                    />
                ) : null}
                <div id='recaptcha-container'></div>
            </div>
}

export default Form
