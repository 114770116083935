import React, { useRef, useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { TopLineup, AchievementsCarousel, StatsCard, BtnPull } from '../Atoms'
import { Skeleton } from 'primereact/skeleton'

import axios from 'axios'
import { ENDPOINT } from '../../env'

import ExtraView from './ExtraView'

import DesktopContext from '../../Layouts/Desktop/ctx'
import MobileContext from '../../Layouts/Mobile'

import './style.scss'

const StatsBlock = ({ layout, width, theme, blured, scope, stashed }) => {
    const [cards, setCards] = useState(null)
    const [tours, setTours] = useState(null)
    const [extraView, setExtraView] = useState(null)
    const splideRef = useRef(null)

    const history = useHistory()
    const { id } = useParams()

    const dctx = useContext(DesktopContext)
    const mctx = useContext(MobileContext)

    useEffect(() => {
        if(!stashed) {
            const federation = dctx && dctx.federation ? {...dctx.federation} : mctx && mctx.federation ? {...mctx.federation} : {}

            loadCards()

            axios.get(`${ENDPOINT}client/tournaments_navi/${federation._id}`)
                .then(resp => {
                    setTours(resp.data)
                })
        }
    }, [])

    useEffect(() => {
        setCards(null)
        loadCards()
    }, [id])

    const loadCards = () => {
        axios.get(`${ENDPOINT}client/statsblock/${id || ''}`)
            .then(resp => {
                setCards(resp.data)
            })
    }

    return  <div
                className={`stats-block ${layout}${blured ? ' blured' : ''}`}
                style={{
                    width: width,
                    backgroundColor: `var(--statsblock-bgd)`
                }}
            >

                {layout !== 'mobile' && id ? (
                    <div className='stats-side'>
                        <TopLineup
                            scope='tournament'
                            width={320}
                            theme={{
                                surface: '#fff',
                                nameColor: 'var(--global-titles-color)'
                            }}
                        />
                        {dctx && dctx.bannerNode ? dctx.bannerNode : null}
                        <AchievementsCarousel scope='tournament' subjectId={id} />
                    </div>
                ) : null}

                {layout === 'mobile' ? <Splide
                    ref={splideRef}
                    options={{
                        pagination: true,
                        fixedWidth: width-40,
                        focus: 'center',
                        type: cards && cards.length > 1 ? 'loop' : null,
                        arrows: false,
                        gap: 0,
                        interval: 4500,
                        perMove: 1,
                        updateOnMove: true,
                        clones: 1
                    }}
                    className='stats-block_cards'
                >
                    {cards ? cards.map((card, i) => (
                        <SplideSlide key={i}>
                            <StatsCard
                                type={card.type}
                                data={card.data}
                                theme={theme}
                                width={width - 60}
                                setExtraView={setExtraView}
                            />
                        </SplideSlide>
                    )) : null}
                </Splide> : cards ? (
                    <div className='stats-block_cards'>
                        {cards.map((card, i) => (
                            <StatsCard
                                key={i}
                                type={card.type}
                                data={card.data}
                                theme={theme}
                                width='30%'
                                setExtraView={setExtraView}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='stats-block_cards'>
                        <Skeleton height='280px' />
                        <Skeleton height='280px' />
                        <Skeleton height='280px' />
                    </div>
                )}

                {blured ? (
                    <div className='overlay'>
                        <div className='overlay-text alterfont'>Выберите турнир для просмотра статистики лучших команд и игроков:</div>
                        <BtnPull
                            pull={tours || []}
                            active={null}
                            theme={theme.buttons}
                            onChange={id => history.push(`/tournament/${id}?section=stats`)}
                            layout={layout}
                            icon='pi pi-star-fill'
                        />
                    </div>
                ) : (
                    <ExtraView
                        type={extraView}
                        setExtraView={setExtraView}
                        subjectId={id}
                        layout={layout}
                    />
                )}
            </div>
}

export default StatsBlock
