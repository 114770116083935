import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import { CONF } from '../../../configs'

const app = initializeApp(CONF.firebase.config)
const auth = getAuth(app)
const authObserver = onAuthStateChanged
auth.languageCode = 'ru'

export { app, auth, authObserver }
