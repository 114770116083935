import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Loader } from '../../Atoms'
import { motion, AnimatePresence } from 'framer-motion'

import Section from './Section'

import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../env'

import LayoutContext from '../../../Layouts/Mobile/ctx'

const sections = [
    //{id: 'live', title: 'Идут сейчас'},
    {id: 'recent', title: 'Прошедшие'},
    {id: 'upcoming', title: 'Предстоящие'}
]

const MobileMatches = ({ extendedMode, scope, subject, deftheme, layout }) => {
    const ctx = useContext(LayoutContext)
    const { width } = ctx
    const theme = deftheme || ctx.config.matchesCarousel.theme

    const [data, setData] = useState(null)
    const [fetching, setFetching] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        console.log('SUBJECT', subject)

        if(!fetching) {
            if(scope === 'team') {
                if(subject) {
                    setFetching(true)
                    axios.get(`${ENDPOINT}client/matchescarousel/${subject}?scope=team`)
                        .then(resp => {
                            setData(resp.data)
                            setFetching(false)
                        })
                }
            } else {
                setFetching(true)
                axios.get(`${ENDPOINT}client/matchescarousel/${subject || id || ''}${scope ? '?scope='+scope : ''}`)
                    .then(resp => {
                        setData(resp.data)
                        setFetching(false)
                    })
            }
        }
    }, [subject])

    return  <div className='mobile-matches'>
                <AnimatePresence
                    mode="popLayout"
                >
                    {!data ? (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                        >
                            <Loader width={200} />
                        </motion.div>
                    ) : sections.map((s, i) => (
                        <Section key={i} layout={layout} data={data[s.id]} type={s.id} title={s.title} />
                    ))}
                </AnimatePresence>
            </div>
}

export default React.memo(MobileMatches)
