import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { fetch } from './fetch'

import { motion, AnimatePresence } from 'framer-motion'
import { Tag } from 'primereact/tag'

import Standings from '../Standings'
import Rankings from '../Rankings'
import MatchesList from '../MatchesList'
import { Loader, NonIdealState } from '../Atoms'

const _bodies = {
    stats: {
        standings: {
            component: Standings,
            props: isMobile => ({
                chess: false,
                wrapped: false,
                theme: {
                    surface: 'surface-card',
                    divider: 'surface-hover',
                    even: '#FBFDFF',
                    evenBorder: '#f1f6fb',
                    text: 'bluegray'
                },
                config: {
                    type: 'regular',
                    showTrends: true,
                    showForms: !isMobile,
                    compact: isMobile
                }
            })
        },
        chess: {
            component: Standings,
            props: isMobile => ({
                chess: true,
                wrapped: false,
                theme: {
                    surface: 'surface-card',
                    divider: 'surface-hover',
                    even: '#FBFDFF',
                    evenBorder: '#f1f6fb',
                    text: 'bluegray'
                },
                config: {
                    type: 'regular',
                    showTrends: true,
                    showForms: false,
                    compact: isMobile
                }
            })
        },
        scorers: {
            component: Rankings,
            props: isMobile => ({
                wrapped: false,
                theme: {
                    text: 'bluegray',
                    even: '#FBFDFF',
                    evenBorder: '#f1f6fb'
                },
                head: ['И', 'Г'],
                valuesModel: ['pld', 'gs']
            })
        },
        assists: {
            component: Rankings,
            props: isMobile => ({
                wrapped: false,
                theme: {
                    text: 'bluegray',
                    even: '#FBFDFF',
                    evenBorder: '#f1f6fb'
                },
                head: ['И', 'А'],
                valuesModel: ['pld', 'a']
            })
        },
        mvp: {
            component: Rankings,
            props: isMobile => ({
                wrapped: false,
                theme: {
                    text: 'bluegray',
                    even: '#FBFDFF',
                    evenBorder: '#f1f6fb'
                },
                head: ['И', 'MVP'],
                valuesModel: ['pld', 'mvp']
            })
        },
        cards: {
            component: Rankings,
            props: isMobile => ({
                wrapped: false,
                theme: {
                    text: 'bluegray',
                    even: '#FBFDFF',
                    evenBorder: '#f1f6fb'
                },
                head: ['ЖК', 'КК', 'Все'],
                valuesModel: ['yc', 'rc', 'cards']
            })
        }
    },
    results: {
        bydate: {
            component: MatchesList,
            props: isMobile => ({
                type: 'results',
                group: 'bydate'
            })
        },
        bytour: {
            component: MatchesList,
            props: isMobile => ({
                type: 'results',
                group: 'bytour'
            })
        }
    },
    schedule: {
        bydate: {
            component: MatchesList,
            props: isMobile => ({
                type: 'schedule',
                group: 'bydate'
            })
        },
        bytour: {
            component: MatchesList,
            props: isMobile => ({
                type: 'schedule',
                group: 'bytour'
            })
        }
    }
}

const playoffBodies = {
    ..._bodies,
    stats: Object.keys(_bodies.stats).reduce((acc, key) => {
        if(key !== 'chess') {
            acc[key] = _bodies.stats[key]
        }
        return acc
    }, {})
}

const initSlots = (tabs, navi) => {
    return tabs.reduce((acc, tab, idx) => {
        if(!acc[tab.path]) {
            acc[tab.path] = {}
        }

        if(navi[idx]) {
            for(let obj of navi[idx]) {
                acc[tab.path][obj.path] = null
            }
        }

        return acc
    }, {})
}

const Content = ({ tabs, _navi, activeTab, activeNav, width, layout, setPlayoff, isPlayoff, setOverrideNavi }) => {
    const [slots, setSlots] = useState(initSlots(tabs, _navi))
    const [data, setData] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [stageIdx, setStageIdx] = useState(0)
    const [firstLoadFlag, setFirstLoadFlag] = useState(false)
    const [navi, setNavi] = useState([..._navi])
    const [playoffFlag, setPlayoffFlag] = useState(null)
    const [stagesStash, setStagesStash] = useState(null)
    const [preventStash, setPreventStash] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)
    const [currentNav, setCurrentNav] = useState(0)

    const [showErr, setShowErr] = useState(false)

    const { id } = useParams()

    const bodies = isPlayoff ? playoffBodies : _bodies

    useEffect(() => {
        setPlayoff(false)
        setSlots(initSlots(tabs, _navi))
        setData(null)
        setStageIdx(0)
        setFirstLoadFlag(false)
        setShowErr(false)
        setPlayoffFlag(null)
        setPreventStash(false)
        setStagesStash(null)
        if(!fetching) {
            loadData()
        }
        //loadData()
    }, [id])

    useEffect(() => {
        if(stagesStash) {
            if(!preventStash) {
                const poItem = stagesStash.find(s => s.type === 'playoff')
                if(poItem) {
                    setStagesStash(stagesStash.map(s => ({
                        ...s,
                        attachStats: s.name && s.name.includes('Высший') ? poItem._id : null
                    })))
                    setPreventStash(true)
                }
            } else {
                setPreventStash(false)
            }
        }
    }, [stagesStash])

    useEffect(() => {
        const isPlayoff = (data && data[stageIdx] && data[stageIdx].standings && data[stageIdx].standings.nodes && data[stageIdx].standings.nodes.length)
        setPlayoff(isPlayoff)
    }, [stageIdx])

    useEffect(() => {
        if((playoffFlag !== null) && (isPlayoff !== playoffFlag)) {
            setSlots({...slots, stats: {...slots.stats, standings: null}})
            setPlayoffFlag(isPlayoff)
        }
        setNavi(isPlayoff ? _navi.map((n, i) => i === 0 ? n.filter(k => k.path !== 'chess') : n) : [..._navi])
        if(layout === 'mobile') {
            setOverrideNavi(isPlayoff ? _navi.map((n, i) => i === 0 ? n.filter(k => k.path !== 'chess').map(k => k.path === 'standings' ? ({...k, label: 'Сетка'}) : k) : n) : null)
        }
    }, [isPlayoff])

    useEffect(() => {
        if (currentTab !== activeTab) {
            setCurrentTab(activeTab)
            setCurrentNav(0)
        } else if (currentNav !== activeNav) {
            setCurrentNav(activeNav)
        }
    }, [activeTab, activeNav])

    useEffect(() => {
        setShowErr(false)
        setData(null)
        const path = tabs[currentTab] ? [tabs[currentTab].path, navi[currentTab] && navi[currentTab][currentNav] ? navi[currentTab][currentNav].path : null] : null

        if(slots[path[0]] && slots[path[0]][path[1]]) {
            const byStageLayout = ['standings', 'chess'].includes(path[1])
            const data = [...slots[path[0]][path[1]]]
            setData(data)
            // if(byStageLayout) {
            //     setStageIdx(data.length - 1)
            // }
            if(!data.length) { setShowErr(true) }
        } else {
            if(!fetching) {
                loadData()
            }
        }
    }, [currentTab, currentNav, firstLoadFlag, slots])

    const path = [tabs[currentTab].path, (navi[currentTab] && navi[currentTab][currentNav]) ? navi[currentTab][currentNav].path : null]
    const node = bodies[path[0]][path[1]] && slots[path[0]][path[1]] ? bodies[path[0]][path[1]] : null
    const byStageLayout = ['standings', 'chess'].includes(path[1])

    const loadData = () => {
        const path = tabs[currentTab] ? [tabs[currentTab].path, navi[currentTab] && navi[currentTab][currentNav] ? navi[currentTab][currentNav].path : null] : null

        if(!slots[path[0]] || !slots[path[0]][path[1]]) {
            setFetching(true)
            fetch(path, id)
                .then(data => {
                    setSlots({...slots, [path[0]]: {...slots[path[0]], [path[1]]: data}})
                    if (data && path.includes('cards')) {
                        data.sort((a, b) => {
                            if (a.data.yc + a.data.rc === b.data.yc + b.data.rc) {
                                return a.rc > b.rc ? 1 : -1
                            } else return 0
                        })
                    }
                    setData(data)
                    if(!data || !data.length) { setShowErr(true) }
                    setFetching(false)

                    //id турниров для lfl36, в которых нужно переопределить id дефолтной стадии
                    const lfl36stages = ['646f724184a0570ab7d325b1', '646b9b0bc73ee067ccc1af8c']

                    const rfuStages = ['64a80247324c5a79b747afa7']

                    if(data && data.length > 1 && !firstLoadFlag) {
                        if(byStageLayout) {
                            if(lfl36stages.includes(id)) {
                                setStageIdx(1)
                            } if (rfuStages.includes(id)){
                                setStageIdx(0)
                            } else {
                                if (data[data.length - 1]?.type === 'playoff' && data[data.length - 1]?.splitted?.length) {
                                    setStageIdx(data.length - 1)
                                } else setStageIdx(0)
                            }
                            setStagesStash(data.map(({standings, ...stage}) => ({
                                ...stage,
                                teams: stage.teams ? stage.teams.map(t => t._id) : []
                            })))
                        }
                        setFirstLoadFlag(true)
                    } else if(data && data[0] && data[0].type === 'playoff' && !firstLoadFlag) {
                        if(lfl36stages.includes(id)) {
                            setStageIdx(1)
                        } else {
                            if (data[data.length - 1]?.type === 'playoff' && data[data.length - 1]?.splitted?.length) {
                                setStageIdx(data.length - 1)
                            } else setStageIdx(0)
                        }
                        setStagesStash(data.map(({standings, ...stage}) => ({
                            ...stage,
                            teams: stage.teams ? stage.teams.map(t => t._id) : []
                        })))
                        setFirstLoadFlag(true)
                        setPlayoff(true)
                    }
                })
        }
    }

    const Specified = node ? node.component : null

    return  <div className='content'>
                <AnimatePresence mode='popLayout'>
                    {node && data ? (
                        <motion.div
                            className={`node-wrap${node ? ' loaded' : ''}`}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                        >
                            {
                                showErr ? (
                                    <NonIdealState
                                        icon='boots'
                                        text='нет данных для этого турнира'
                                        color='var(--blue-900)'
                                        iconColor='blue-300'
                                        height={340}
                                    />
                                ) : [
                                    byStageLayout && stagesStash ? (
                                        <div className='stages-nav'>
                                            {stagesStash.map((stage, i) => (
                                                <Tag
                                                    key={i}
                                                    className={stageIdx === i ? 'selected' : ''}
                                                    onClick={() => setStageIdx(i)}
                                                >{stage.title || stage.name}</Tag>
                                            ))}
                                        </div>
                                    ) : stagesStash && activeTab === 0 ? (
                                        <div className='stages-nav'>
                                            {stagesStash.filter(stage => stage.type !== 'playoff').length > 1 ? stagesStash.filter(stage => stage.type !== 'playoff').map((stage, i) => (
                                                <Tag
                                                    key={i}
                                                    className={stageIdx === i ? 'selected' : ''}
                                                    onClick={() => setStageIdx(i)}
                                                >{stage.title || stage.name}</Tag>
                                            )) : null}
                                        </div>
                                    ) : null,
                                    byStageLayout && data[stageIdx] && data[stageIdx].groups && data[stageIdx].groups.length > 0 ? data[stageIdx].groups.map((g, _i) => g.groups ? g.groups.map((_g, __i) => (
                                        <Specified
                                            {...node.props(layout === 'mobile')}
                                            key={__i}
                                            title={_g.title || _g.name}
                                            data={_g.standings}
                                            width={layout === 'mobile' ? width-40 : '100%'}
                                            layout={layout}
                                        />
                                    )) : (
                                        <Specified
                                            {...node.props(layout === 'mobile')}
                                            key={_i}
                                            title={g.title || g.name}
                                            data={g.standings}
                                            width={layout === 'mobile' ? width-40 : '100%'}
                                            layout={layout}
                                        />
                                    )) : (
                                        <Specified
                                            {...node.props(layout === 'mobile')}
                                            data={byStageLayout ? data[stageIdx] && (data[stageIdx].standings || data[stageIdx]) : data}
                                            width={layout === 'mobile' ? width-40 : '100%'}
                                            layout={layout}
                                            stagesStash={stagesStash}
                                            stageIdx={stageIdx}
                                        />
                                    )
                                ]
                            }
                        </motion.div>
                    ) : null}
                </AnimatePresence>

                <AnimatePresence mode='popLayout'>
                    {!node && !data ? (
                        <motion.div
                            className={`content-loader${node ? ' loaded' : ''}`}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: .8}}
                        >
                            <div
                                className={`content-loader${node ? ' loaded' : ''}`}
                                style={{minHeight: layout === 'mobile' ? 'calc(100vh - 20px)' : 'unset'}}
                            >
                                <Loader width={240} />
                            </div>
                        </motion.div>
                    ) : null}
                </AnimatePresence>
            </div>
}

export default Content
