import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import { Tooltip } from 'primereact/tooltip'
import './style.scss'

const Link = ({ color, path, text, nested, idx, action, active, setActiveTab }) => {
    const history = useHistory()
    const [activeStage, setActiveStage] = useState(-1)

    const location = useLocation()

    const linkClick = () => {
        history.push(path)
        setActiveTab(idx)
    }

    useEffect(() => {
        setActiveStage(!active ? -1 : nested ? nested.findIndex(n => n._id === location.pathname.replace('/tournament/', '')) : -1)
    }, [active])

    return  <span
                style={{
                    color: `var(--${color})`,
                    borderColor: `var(--${color})`
                }}
                className={`app-link${active ? ' active' : ''}`}
                id={`top_link_${idx}`}
                onClick={() => action ? action() : path ? linkClick() : null}
            >{text}
                {nested ? (
                    <Tooltip
                        target={`#top_link_${idx}`}
                        position='bottom'
                        className={`blue-tooltip${idx === 0 ? ' first' : ''}`}
                        autoHide={false}
                    >
                        <div className='_nested'>
                            {nested.map((node, i) => (
                                <div
                                    key={i}
                                    className={`_nested-node${activeStage === i ? ' active' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveTab(idx)
                                        setActiveStage(i)
                                        history.push(`/tournament/${node._id}`);
                                    }}
                                >{node.name}</div>
                            ))}
                        </div>
                    </Tooltip>
                ) : null}
            </span>
}

export default Link
